// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mc-link-disclaimer-text{
    color: #7E7E7E;
    line-height: 22px;
}
.mc-link-article-control-wrapper{
    border-top:  1px solid rgba(0, 113, 193, 0.2);
    border-bottom:  1px solid rgba(0, 113, 193, 0.2);
}
.mc-link-disclaimer-wrapper{
    border-top:  1px solid rgba(0, 113, 193, 0.2);
    border-bottom:  1px solid rgba(0, 113, 193, 0.2);
    padding: 15px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/MarketCompass/marketlink.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,6CAA6C;IAC7C,gDAAgD;AACpD;AACA;IACI,6CAA6C;IAC7C,gDAAgD;IAChD,iBAAiB;AACrB","sourcesContent":[".mc-link-disclaimer-text{\n    color: #7E7E7E;\n    line-height: 22px;\n}\n.mc-link-article-control-wrapper{\n    border-top:  1px solid rgba(0, 113, 193, 0.2);\n    border-bottom:  1px solid rgba(0, 113, 193, 0.2);\n}\n.mc-link-disclaimer-wrapper{\n    border-top:  1px solid rgba(0, 113, 193, 0.2);\n    border-bottom:  1px solid rgba(0, 113, 193, 0.2);\n    padding: 15px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
