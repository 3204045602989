import React from "react";
const MarkerHtmL = ({ item }) => {
  return (
    <>
      <div
        style={{
          height: "55px",
          width: "100%",
          backgroundColor: "#0071C1",
          color: "#ffff",
          padding: "15px 0px 0px 10px",
          zIndex: "2",
        }}
      >
        <h2 className="font-semibold text-sm capitalize">{item?.company}</h2>
      </div>
      <div style={{ padding: "10px" }} className="popup-content">
        <div className="main-wrapper">
          {item?.country !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Country:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.country}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.group_capacity_mt !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Group Capacity (tons):
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">
                    {item?.group_capacity_mt}
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}

          {item?.port_location !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Port:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.port_location}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.avg_bulk_size !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Avg Bulk Size:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.avg_bulk_size}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.business_type !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Cargo Type:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.business_type}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.activities !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Activities:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.activities}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.commodities !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Materials:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.commodities}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.equipment !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Equipment:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.equipment}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.general_email !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    General Email:
                  </p>
                </div>
                <div className="col-span-1">
                  <p
                    className="font-medium text-xs"
                    style={{ color: "#0071C1" }}
                  >
                    {item?.general_email}
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.website !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Website:
                  </p>
                </div>
                <div className="col-span-1">
                  <p
                    className="font-medium text-xs"
                    style={{ color: "#0071C1" }}
                  >
                    {item?.website}
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.phone !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Phone:
                  </p>
                </div>
                <div className="col-span-1">
                  <p
                    className="font-medium text-xs"
                    style={{ color: "#0071C1" }}
                  >
                    {item?.phone}
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.key_personnels !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Key Personnel:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.key_personnels}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.job_title !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Job title:
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="font-medium text-xs">{item?.job_title}</p>
                </div>
              </div>
              <hr />
            </>
          )}
          {item?.contact_email !== null && (
            <>
              <div className="grid grid-cols-2 pt-3 pb-3 ">
                <div className="col-span-1">
                  <p
                    className="text-xs font-medium"
                    style={{ color: "#818181" }}
                  >
                    Contact Email:
                  </p>
                </div>
                <div className="col-span-1">
                  <p
                    className="font-medium text-xs"
                    style={{ color: "#0071C1" }}
                  >
                    {item?.contact_email}
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MarkerHtmL;
