import React,{useEffect, useState,useRef} from 'react'
import { useLocation } from 'react-router-dom';

import { Rate ,Select,Progress,Spin,Tooltip } from 'antd';

import axios from '../../axiosconfiguration/axios'
import RatingStars from '../../Components/rate/RatingStars'
import { CircleFlag } from 'react-circle-flags'
import countryCodes from '../../Components/CountryCodes.json'
import leftArrow from '../../Assets/images/left-arrow.svg'
import rightArrow from '../../Assets/images/right-arrow.svg'
import './Fifa.css'
import { CaretDownOutlined } from '@ant-design/icons'


const Fifa = () => {

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const location = useLocation();
const [countryData, setCountryData] = useState([]);
const [arenas,setArenas]=useState([])
const [selectedArena,setSelectedArena]=useState(null)
const [selectedCountry1,setSelectedCountry1]=useState([]) 
const [selectedCountry2,setSelectedCountry2]=useState([])
const [selectedCompany1,setSelectedCompany1]=useState({})
const [selectedCompany2,setSelectedCompany2]=useState({})

const [loading,setLoading]=useState(false)
const [country1Loading,setCountry1Loading]=useState(false)
const [country2Loading,setCountry2Loading]=useState(false)
const [company1Loading,setCompany1Loading]=useState(false)
const [company2Loading,setCompany2Loading]=useState(false)
const [arenaLoading,setArenaLoading]=useState(false)
const [dropDown1Open,setDropDown1Open]=useState(false)
const [dropDown2Open,setDropDown2Open]=useState(false)
const [showTooltip1,setShowTooltip1]=useState(false)
const [showTooltip2,setShowTooltip2]=useState(false)


const arenaImageRef = useRef(null);

useEffect(() => {
  if (selectedArena !== null) {
    setArenaLoading(true);

    arenaImageRef.current = new Image();
    arenaImageRef.current.onload = handleArenaImageLoad;
    arenaImageRef.current.src = selectedArena.image;
  }
}, [selectedArena]);

const handleArenaImageLoad = () => {
  setArenaLoading(false);
};


const mapOptions=[
  {label:'Estimated Volumes',value: 'estimated_volumes'},
  {label:'Cargo Sizes',value:'cargo_sizes'},
  {label:'Last year Capacity',value:'current_vs_last_YTD_volumes'},
  {label:'Deepsea Concentration',value:'deepsea_concentration'},
  {label:'Loading Rates',value: 'loading_rates'},
  {label:'Mill Concentration',value:'mill_concentration'},
  {label:'Port Operations',value:'port_operations'},
  {label:'Shortsea Concentration',value:'shortsea_concentration'},
  {label:'Total Score',value:'total_score'},
]

useEffect(() => {
  setTimeout(() => {
    let synth=window.speechSynthesis;
    synth.cancel();
  }, 300);
   
}, [location]);

useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);


useEffect(()=>{
    const getData=async ()=>{
      setCountry1Loading(true)
      setCountry2Loading(true)
      setCompany1Loading(true)
      setCompany2Loading(true)
        try{
            let url='/fifa/countries'
              const response = await axios.post(url,{}, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              })
            if (response.data){

                const countryDataArray = Object.values(response.data.data);

                setCountryData(countryDataArray)
                setSelectedCountry1(countryDataArray[0])
                setSelectedCountry2(countryDataArray[1])
            }
        }
        catch(e){
            console.log(e)
        }
        finally{
          setCountry1Loading(false)
          setCountry2Loading(false)
        }
    }
        getData()
},[])

useEffect(()=>{
  const getData=async ()=>{
    setArenaLoading(true)
      try{
          let url='/fifa/arenas'
            const response = await axios.post(url,{}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            })
          if (response.data){
            setArenas(response.data.data)
            setSelectedArena(response.data.data[0])             
          }
          return
      }
      catch(e){
          console.log(e)
      }
  }
      getData()
},[])



useEffect(()=>{
    
    const changeSelectedCompany1=async()=>{
        setCompany1Loading(true)
        if(selectedCountry1[0].id===selectedCompany2.id && selectedCountry1.length>1){
            setSelectedCompany1(selectedCountry1[1])
        }
        else
            setSelectedCompany1(selectedCountry1[0])
    }

    if(selectedCountry1.length>0)
      changeSelectedCompany1()
},[selectedCountry1])

useEffect(()=>{
    
    const changeSelectedCompany2=async()=>{
        setCompany2Loading(true)
        if(selectedCountry2[0].id===selectedCompany1.id && selectedCountry2.length>1){
            setSelectedCompany2(selectedCountry2[1])
        }
        else
            setSelectedCompany2(selectedCountry2[0])
    }

    if(selectedCountry2.length>0)
      changeSelectedCompany2()

},[selectedCountry2])

const previousCountry1 = async () => {
    setCountry1Loading(true)
    const bIndex = countryData.findIndex(country => country[0].country === selectedCountry1[0].country);
    if (bIndex !== -1) {
      let previousIndex = bIndex === 0 ? countryData.length - 1 : bIndex - 1;
      if(countryData[previousIndex].length===1 && countryData[previousIndex][0].id===selectedCompany2.id){
        previousIndex = previousIndex === 0 ? countryData.length - 1 : previousIndex - 1;
      }
      setSelectedCountry1(countryData[previousIndex]);
    }
    setCountry1Loading(false)
  };

  const nextCountry1 = () => {
    setCountry1Loading(true)
    const bIndex = countryData.findIndex(country => country[0].country === selectedCountry1[0].country);
    if (bIndex !== -1) {
      let nextIndex = bIndex === countryData.length - 1 ? 0 : bIndex + 1;
      if(countryData[nextIndex].length===1 && countryData[nextIndex][0].id===selectedCompany2.id){
        nextIndex = nextIndex === countryData.length - 1 ? 0 : nextIndex + 1;
      }
      setSelectedCountry1(countryData[nextIndex]);
    }
    setCountry1Loading(false)
  };

  const previousCountry2 = () => {
    setCountry2Loading(true)
    const bIndex = countryData.findIndex(country => country[0].country === selectedCountry2[0].country);
    if (bIndex !== -1) {
        let previousIndex = bIndex === 0 ? countryData.length - 1 : bIndex - 1;
        if(countryData[previousIndex].length===1 && countryData[previousIndex][0].id===selectedCompany1.id){
          previousIndex = previousIndex === 0 ? countryData.length - 1 : previousIndex - 1;
        }
      setSelectedCountry2(countryData[previousIndex]);
      setCountry2Loading(false)
    }
  };

  // Function to store the next value of 'b'
  const nextCountry2 = () => {
    setCountry2Loading(true)
    const bIndex = countryData.findIndex(country => country[0].country === selectedCountry2[0].country);
    if (bIndex !== -1) {
        let nextIndex = bIndex === countryData.length - 1 ? 0 : bIndex + 1;
        if(countryData[nextIndex].length===1 && countryData[nextIndex][0].id===selectedCompany1.id){
          nextIndex = nextIndex === countryData.length - 1 ? 0 : nextIndex + 1;
        }
      setSelectedCountry2(countryData[nextIndex]);
      setCountry2Loading(false)
    }
  };

  const previousCompany1 = async () => {
    setCompany1Loading(true)
    const bIndex = selectedCountry1.findIndex(company => company.id === selectedCompany1.id);
    if (bIndex !== -1) {
      let previousIndex = bIndex === 0 ? selectedCountry1.length - 1 : bIndex - 1;
      if(selectedCountry1[previousIndex].id===selectedCompany2.id)
      {
        previousIndex = previousIndex === 0 ? selectedCountry1.length - 1 : previousIndex - 1;
        if(selectedCountry1.length===2){
          setCompany1Loading(false)
        }
      }
      setSelectedCompany1(selectedCountry1[previousIndex]);
    if(selectedCountry1.length===1)
      setCompany1Loading(false)
    }
  };

  const nextCompany1 = () => {
    setCompany1Loading(true)
    const bIndex = selectedCountry1.findIndex(company => company.id === selectedCompany1.id);
    if (bIndex !== -1) {
        let nextIndex = bIndex === selectedCountry1.length - 1 ? 0 : bIndex + 1;
        if(selectedCountry1[nextIndex].id===selectedCompany2.id)
        {
            nextIndex = nextIndex === selectedCountry1.length - 1 ? 0 : nextIndex + 1;
            if(selectedCountry1.length===2){
              setCompany1Loading(false)
            }
        }
        setSelectedCompany1(selectedCountry1[nextIndex]);
      if(selectedCountry1.length===1)
        setCompany1Loading(false)
    }
  };

  const previousCompany2 = () => {
    setCompany2Loading(true)
    const bIndex = selectedCountry2.findIndex(company => company.id === selectedCompany2.id);
    if (bIndex !== -1) {
      let previousIndex = bIndex === 0 ? selectedCountry2.length - 1 : bIndex - 1;
      if(selectedCountry2[previousIndex].id===selectedCompany1.id)
        {
          previousIndex = previousIndex === 0 ? selectedCountry2.length - 1 : previousIndex - 1;
          if(selectedCountry2.length===2){
            setCompany2Loading(false)
          }
        }
      setSelectedCompany2(selectedCountry2[previousIndex]);
      if(selectedCountry2.length===1)
        setCompany2Loading(false)
    }
  };

  const nextCompany2 = () => {
    setCompany2Loading(true)
    const bIndex = selectedCountry2.findIndex(company => company.id === selectedCompany2.id);
    if (bIndex !== -1) {
        let nextIndex = bIndex === selectedCountry2.length - 1 ? 0 : bIndex + 1;
        if(selectedCountry2[nextIndex].id===selectedCompany1.id)
        {
            nextIndex = nextIndex === selectedCountry2.length - 1 ? 0 : nextIndex + 1;
            if(selectedCountry2.length===2){
              setCompany2Loading(false)
            }
        }
        setSelectedCompany2(selectedCountry2[nextIndex]);
        if(selectedCountry2.length===1)
          setCompany2Loading(false)
    }
  };

  const nextArena = async () => {
    setArenaLoading(true);
  
    if (arenas.length === 1) {
      setArenaLoading(false);
      return;
    }
  
    const currentIndex = arenas.findIndex((arena) => arena === selectedArena);
  
    const nextIndex = (currentIndex + 1) % arenas.length;
  
    setSelectedArena(arenas[nextIndex]);
  };

  const prevArena = async () => {
    setArenaLoading(true);
  
    if (arenas.length === 1) {
      setArenaLoading(false);
      return;
    }
  
    const currentIndex = arenas.findIndex((arena) => arena === selectedArena);
  
    const prevIndex =
      currentIndex === 0 ? arenas.length - 1 : currentIndex - 1;
  
    setSelectedArena(arenas[prevIndex]);
  };


  const company1loaded=async ()=>{
    setCompany1Loading(false)
  }

  const company2loaded=async ()=>{
    setCompany2Loading(false)
  }


  useEffect(()=>{
    if(!company1Loading && !company2Loading && !country1Loading && !country2Loading){
      setLoading(false)
    }
    else{
      setLoading(true)
    }
  },[company1Loading,company2Loading,country1Loading,country2Loading])

  const handleCountryChange1 = (value) => {
    const selectedCountryData = countryData.find(
      (country) => country[0].country === value
    );
    console.log(selectedCountryData)
    setSelectedCountry1(selectedCountryData)
  };

  const handleCountryChange2 = (value) => {
    const selectedCountryData = countryData.find(
      (country) => country[0].country === value
    );
    console.log(selectedCountryData)
    setSelectedCountry2(selectedCountryData)
  };

  // const setDropdownStyling = () => {
  //   const dropdownElements = document.getElementsByClassName('ant-select-dropdown');
  //   if (dropdownElements.length > 0) {
  //     const firstDropdownElement = dropdownElements[0];
  //     firstDropdownElement.style.backgroundColor = '#252525';
  //   }
  //   if(dropdownElements.length > 1){
  //     const firstDropdownElement = dropdownElements[1];
  //     firstDropdownElement.style.backgroundColor = '#252525';
  //   }
  // };
  
  const dropdown1changer=(props)=>{
    if(props)
      setDropDown1Open(true)
    else
      setDropDown1Open(false)
  }

  const dropdown2changer=(props)=>{
    if(props)
      setDropDown2Open(true)
    else
      setDropDown2Open(false)
  }

  const dropDown2Opener=()=>{
    setDropDown2Open(prev=>!prev)
  }
  const dropDown1Opener=()=>{
    setDropDown1Open(prev=>!prev)
  }

  console.log("safari",isSafari)
  return (
    <>
    {(loading) && <div className="overlay"></div>}
    {(loading) && <Spin size='large' style={{position:'absolute',left:'49%',top:'49%',zIndex:'99999'}}/>}
    <div className='fifa-page-wrapper' style={{backgroundImage:`url(${selectedArena===null? 'none': selectedArena.image})`,backgroundColor: selectedArena === null ? 'black' : 'transparent',display:'flex',justifyContent:'center'}}>
        
    <div className='mt-3 z-0 h-full main-part'>
        {selectedCompany1 &&<img key={selectedCompany1.id} src={'https://'+selectedCompany1.left_worker} onLoad={company1loaded} alt="Contestantleft" className='left-worker'  />}
        {selectedCompany2 &&<img key={selectedCompany2.id} src={'https://'+selectedCompany2.right_worker} onLoad={company2loaded} alt="ContestantRight" className='right-worker' />}

        <div className='mx-auto  fifa-comparison-wrapper relative ' >
            <div className='fifa-comparison-header flex items-center text-white font-semibold text-sm' style={{justifyContent:'center'}}>
                <p className='text-white fifa-header-text'>Exhibition Match</p>
            </div>
           
            <div className="fifa-comparison-body mt-4 pb-4 h-full">  
                <div className='w-full grid grid-cols-2 upper-main'>
                    <p className='absolute text-white font-medium vs-text'>VS</p>

                    <div className='flex flex-col ' style={{width:'86%'}}> {/* bg-here */}
                       
                    <div className='country-select'>
                        <div className='arrow-button left' onClick={previousCountry1}>
                        {/* <i className="bi bi-chevron-left"></i> */}
                        <img src={leftArrow} width='26%'/>
                        </div>
                        <div className='center-text' onMouseEnter={()=>{setShowTooltip1(true)}} onMouseLeave={()=>{setShowTooltip1(false)}}>
                                <div className='flag-container country1flag'>
                                  {selectedCountry1.length>0 && <CircleFlag countryCode={countryCodes[selectedCountry1[0].country]}/>}
                                </div>
                                {selectedCountry1.length>0 && (
                                  <Tooltip color='#252525' title={selectedCountry1[0].country} open={!dropDown1Open && showTooltip1}>
                                <Select
                                className='country-select-header'
                                suffixIcon={<CaretDownOutlined onClick={dropDown1Opener}/>}
                                value={selectedCountry1[0].country.length > 15 ? `${selectedCountry1[0].country.substring(0, 12)}...` : selectedCountry1[0].country}
                                onChange={handleCountryChange1}
                                popupClassName={'custom-dropdown'}
                                onDropdownVisibleChange={dropdown1changer}
                                open={dropDown1Open}
                                  style={{ width: '100%' }}
                                >
                                  {countryData.map((country, index) => (
                                    <Option key={index} className='selected-options' title={!isSafari ? ' ' : ''} value={country[0].country}>
                                      {country[0].country}
                                    
                                    </Option>
                                  ))}
                                </Select>
                                </Tooltip>
                                )}
                                {/* {selectedCountry1.length>0 && (selectedCountry1[0].country.length>15? selectedCountry1[0].country.substring(0, 12) + "..." :selectedCountry1[0].country)} */}
                          </div>
                        <div className='arrow-button right' onClick={nextCountry1}>
                            {/* <i className="bi bi-chevron-right"></i> */}
                            <img src={rightArrow} width='26%'/>
                        </div>
                    </div>
                        <div className='company-square mt-3'>
                            <div className='company-fifa mb-2 mx-auto' style={{borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center' }}> 
                                {selectedCompany1 && <img src={'https://'+selectedCompany1.logo} alt='' className='company-logo'/>}
                            </div>
                            {/* <i className="bi bi-chevron-left left-comp" style={{position:'relative',top:'-40%',color:'white',fontSize:'30px',cursor:'pointer'}} onClick={previousCompany1}></i> */}
                            {/* <i className="bi bi-chevron-right right-comp" onClick={nextCompany1}></i> */}
                            <div className='left-comp' onClick={previousCompany1}>
                              <img src={leftArrow} />
                            </div>
                            <div className='right-comp' onClick={nextCompany1}>
                              <img src={rightArrow} />
                            </div>
                            <div className='left-comp-gap' style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'-70px'}} ></div>
                            <div className='name-rating-div card1-div' style={{display:'flex',flexDirection:'column',alignItems:'center',paddingBottom:'10%'}} >
                            <p className='text-white fifa-header-text' style={{fontSize:'16px',marginBottom:'10px'}}>{selectedCompany1 && selectedCompany1.company}</p>
                              <RatingStars value={Math.round((selectedCompany1.total_score / 100) * 10) / 2}/>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className='flex flex-col ' style={{width:'86%',marginLeft:'20px'}}> {/* bg-here */}
                       
                        <div className='country-select'>
                            <div className='arrow-button left' onClick={previousCountry2}>
                            {/* <i className="bi bi-chevron-left"></i> */}
                            <img src={leftArrow} width='26%'/>
                            </div>
                            <div className='center-text'  onMouseEnter={()=>{setShowTooltip2(true)}} onMouseLeave={()=>{setShowTooltip2(false)}}>
                                <div className='flag-container country2flag'>
                                  {selectedCountry2.length>0 && <CircleFlag countryCode={countryCodes[selectedCountry2[0].country]}/>}
                                </div>
                                {selectedCountry2.length>0 && (
                                <Tooltip color='#252525' title={selectedCountry2[0].country} open={!dropDown2Open && showTooltip2}>
                                <Select
                                className='country-select-header'
                                suffixIcon={<CaretDownOutlined onClick={dropDown2Opener}/>}
                                  value={selectedCountry2[0].country.length > 15 ? `${selectedCountry2[0].country.substring(0, 12)}...` : selectedCountry2[0].country}
                                  onChange={handleCountryChange2}
                                  style={{ width: '100%' }}
                                  open={dropDown2Open}
                                  onDropdownVisibleChange={dropdown2changer}
                                  popupClassName={'custom-dropdown'}
                                >
                                  {countryData.map((country, index) => (
                                    <Option key={index} className='selected-options' title={!isSafari ? ' ' : ''} value={country[0].country}>
                                      {country[0].country}
                                    </Option>
                                  ))}
                                </Select>
                                </Tooltip>)}
                                {/* {selectedCountry1.length>0 && (selectedCountry1[0].country.length>15? selectedCountry1[0].country.substring(0, 12) + "..." :selectedCountry1[0].country)} */}
                            </div>
                            <div className='arrow-button right' onClick={nextCountry2}>
                                {/* <i className="bi bi-chevron-right"></i> */}
                                <img src={rightArrow} width='26%'/>
                            </div>
                        </div>
                        <div className='company-square mt-3'>
                            <div className='company-fifa mb-2 mx-auto' style={{borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center' }}> 
                                { selectedCompany2 && <img src={'https://'+selectedCompany2.logo} alt='' className='company-logo'/>}
                            </div>
                            {/* <i className="bi bi-chevron-left left-comp" style={{position:'relative',top:'-40%',color:'white',fontSize:'30px',cursor:'pointer'}} onClick={previousCompany2}></i> */}
                            {/* <i className="bi bi-chevron-right right-comp" onClick={nextCompany2}></i> */}
                            <div className='left-comp' onClick={previousCompany2}>
                              <img src={leftArrow} />
                            </div>
                            <div className='right-comp' onClick={nextCompany2}>
                              <img src={rightArrow} />
                            </div>
                            <div className='name-rating-div card2-div' style={{display:'flex',flexDirection:'column',alignItems:'center',paddingBottom:'10%'}} >
                            <p className='text-white fifa-header-text' style={{fontSize:'16px',marginBottom:'10px'}}>{selectedCompany2 && selectedCompany2.company}</p>
                              <RatingStars value={Math.round((selectedCompany2.total_score / 100) * 10) / 2}/>
                            </div>
                        </div>
                        
                        
                    </div>

                </div>
                <div className='arena-select'>
                    <div className='arrow-button left' onClick={prevArena}>
                        {/* <i className="bi bi-chevron-left"></i> */}
                        <img src={leftArrow} width='25%'/>
                    </div>
                    <div className='center-text arena-center-text'>
                        {selectedArena && selectedArena.title}
                    </div>
                    <div className='arrow-button right' onClick={nextArena}>
                        {/* <i className="bi bi-chevron-right"></i> */}
                        <img src={rightArrow} width='25%'/>
                    </div>
                </div>

                <div className='company-properties'>
  {mapOptions.map((option, index) => (
    <div
      key={index}
      className={`${
        index % 2 === 0 ? 'odd-property' : ''
      } property`}
      style={{
        borderTopLeftRadius: index === 0 ? '5px' : '0',
        borderTopRightRadius: index === 0 ? '5px' : '0',
        borderBottomLeftRadius:
          index === mapOptions.length - 1 ? '5px' : '0',
        borderBottomRightRadius:
          index === mapOptions.length - 1 ? '5px' : '0',
      }}
    >
      <div className='left-property'>
        {selectedCompany1[option.value] && (
          <div className='left-property-info'>
            <Progress
              percent={selectedCompany1[option.value]}
              format={(percent) => ''}
              strokeColor={
                selectedCompany1[option.value] > 66
                  ? '#30CE30'
                  : selectedCompany1[option.value] > 33
                  ? '#F5B24E'
                  : '#FF6767'
              }
              showInfo={false}
              className='left-progressbar progressbarr'
            />
            <span>{selectedCompany1[option.value]>9?selectedCompany1[option.value]:`0${selectedCompany1[option.value]}`}</span>
          </div>
        )}
      </div>
      <div style={{ minWidth: '40%', textAlign: 'center' }}>
        {option.label}
      </div>
      <div className='right-property'>
        {selectedCompany2[option.value] && (
          <div className='right-property-info'>
            <span style={{ marginRight: '10px' }}>
              {selectedCompany2[option.value]>9?selectedCompany2[option.value]:`0${selectedCompany2[option.value]}`}
            </span>
            <Progress
              percent={selectedCompany2[option.value]}
              format={(percent) => ''}
              strokeColor={
                selectedCompany2[option.value] > 66
                  ? '#30CE30'
                  : selectedCompany2[option.value] > 33
                  ? '#F5B24E'
                  : '#FF6767'
              }
              showInfo={false}
              className='right-progressbar progressbarr'
            />
          </div>
        )}
      </div>
    </div>
  ))}
</div>
            </div>
            
        </div>
    </div>
    
    </div>
    
    </>
  )
}

export default Fifa