import { useNavigate } from "react-router-dom"
import { WarningModal } from "./Misc/WarningModal"

export const SubMenu = ({ submenuAccessLevel, accessLevels, setSeletectedSubMenu, selectedSubMenu }) => {
    const navigate = useNavigate()
    let ListIdstring = localStorage.getItem('ListIds')
    let ListId = JSON.parse(ListIdstring)
    return (
        <nav className="header-sub-menu">
            <ul
                className='sub-menu-item'
            >
                {submenuAccessLevel?.map(item => {
                    return (
                        <li
                            key={item.id}
                            className={`${item?.path == selectedSubMenu
                                ? 'active-submenu ant-btn css-dev-only-do-not-override-pr0fja border-blue-200'
                                : 'submenu ant-btn css-dev-only-do-not-override-pr0fja border-blue-200'
                                }`}
                            onClick={e => {
                                console.log(accessLevels)
                                setSeletectedSubMenu(item?.path)
                                // const currentItem = accessLevels?.find(
                                //     i => item?.id == i?.id
                                // )
                                // console.log(item)
                                // console.log(currentItem); 
                                // (accessLevels &&
                                //         // List Array
                                //         ListId?.some(ai =>
                                //             currentItem?.lists
                                //                 ?.map(it => JSON.parse(it))
                                //                 ?.includes(ai)
                                //         )) ||
                                //         (accessLevels && currentItem?.lists?.lists?.length < 1)
                                //         ? setSeletectedSubMenu(item?.path)
                                //         : WarningModal(item?.text)
                            }}
                        >
                            <p style={{ textAlign: "center" }} > {item?.text} </p>

                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}