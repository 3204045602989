import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BiSolidStarHalf, BiSolidStar, BiStar } from 'react-icons/bi';
import './RatingStars.css';

const RatingStars = ({ value = 0 }) => {
  const [size, setSize] = useState(28); // Default size for normal screens

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSize(16); // Set size to 25 for mobile screens
      } else {
        setSize(20); // Set size to 30 for normal screens
      }
    };

    // Add an event listener to listen for window resize
    window.addEventListener('resize', handleResize);

    // Initial size setup
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const stars = [];
  const maxStars = 5.5;
  value = value + 0.5;

  const clampedValue = Math.min(
    Math.max(Math.round(value * 2) / 2, 0.5),
    maxStars
  );

  for (let i = 1; i <= maxStars; i++) {
    if (i <= clampedValue - 0.5) {
      stars.push(
        <BiSolidStar
          key={i}
          className="star-icon yellow-fill background-yellow"
          size={size}
        />
      );
    } else if (i <= clampedValue) {
      stars.push(
        <BiSolidStarHalf
          key={i}
          className="star-icon yellow-fill"
          size={size}
        />
      );
    } else {
      stars.push(
        <BiStar
          key={i}
          className="star-icon yellow-fill"
          size={size}
        />
      );
    }
  }

  return <div className="rating-stars">{stars}</div>;
};

RatingStars.propTypes = {
  value: PropTypes.number,
};

export default RatingStars;
