import { Link,useNavigate } from 'react-router-dom'
import { Input,Spin,Button } from 'antd'
import { useState } from 'react'


import { useParams } from 'react-router-dom'
import FieldValidation from '../fieldsvalidation'
import './styles.css'
import UseFetchAxios from '../../Hooks/UseFetchAxios'
import { ReactComponent as Check } from '../../Assets/images/Check.svg'



const ForgetPassword = () => {
  const [ loading,setLoading] = useState(false)
  const useFetch = UseFetchAxios()
  let parameters = useParams();
  const [password, setPasswords] = useState({
    old_password: '',
    password: '',
  })
  const [error, setError] = useState('')
  const [done,setDone]=useState(false)
  const navigate=useNavigate()


  const updatePassword = (key, value) => {
    setPasswords({
      ...password,
      [key]: value
    })
  }

  const setNewPassword = () => {
    console.log(password)
    if (password?.old_password != password?.password) {
      setError('Password is not matching with Confirm Pasword')
      setTimeout(() => {
        setError('')
      }, 5000)
      return
    }
    setLoading(true)
    useFetch?.apiCallPost('/validate-token', {token:parameters?.token}, (err, data) => {
      if(err){
        
        setError(err?.message)
        setTimeout(() => {
          setError('')
        }, 5000)
        setLoading(false)
      }
      else{
        useFetch?.apiCallPost('/reset-password', {password:password?.password,password_reset_token:data?.token}, (err, data) => {
          if(err){
            setError(err?.message)
            setTimeout(() => {
              setError('')
            }, 5000)
            setLoading(false)
          }
          else{
            setError(data?.message)
            setDone(true)
            setTimeout(() => {
              setError('')
            }, 5000)
            setLoading(false)
          }
        })

      }
    })
  }
  return (
    <>
    {!done ? <div className='flex flex-col'>
      <div className='flex flex-col gap-y-2 justify-start items-start'>
        <h3 className='TitleHeader'>Create New Password</h3>
        <label className=' login-label'>Password*</label>
        <Input.Password
          className='login-fields '
          type='password'
          autoComplete='new-password'
          onChange={e => {
            updatePassword('password', e?.target?.value)
          }}
        />
      </div>
      <div className='flex flex-col gap-y-2 justify-start items-start mt-5'>
        <label className=' login-label'>Confirm Password*</label>
        <Input.Password
          className='login-fields '
          type='password'
          autoComplete='new-password'
          onChange={e => {
            updatePassword('old_password', e?.target?.value)
          }}
        />
      </div>
      <FieldValidation msg={error}></FieldValidation>
      <div className='flex justify-between mt-6 login-label'>
        <button
          className='login-btn'
          onClick={() => {
            console.log()
            setNewPassword()
          }}
        >
         {loading ? <Spin></Spin> :'Set new Password'}
        </button>
      </div>
      <div className='flex mt-20 justify-center'>
        <Link to='/' className='login-reset'>
          Take me to the Login
        </Link>
      </div>
    </div>:
      <div className='flex flex-col' style={{width:'90%'}}>
        <div className='flex flex-col gap-y-2 justify-start items-start'>
          <h3 className='TitleHeader'>Reset Password</h3>
        </div>
        <div className='reset-center-div'>
            <Check/>
            <h1>Success</h1>
            <p>Your password has been successfully updated.</p>
            <Button onClick={()=>{navigate('/')}} className='login-btnnn' type='primary'>Take me to the Login</Button>
        </div>
      </div>
    }
    </>
  )
}
export default ForgetPassword
