import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../store/UserContext';
import UseFetchAxios from '../Hooks/UseFetchAxios';

const AutoLogout = ({ children }) => {
    const timeoutRef = useRef(null);
    const navigate = useNavigate()
    const useFetch = UseFetchAxios()
    const { userState, setUserState } = useContext(UserContext)
    const INACTIVITY_LIMIT = 15 * 60 * 1000; // 15 minutes

    const resetTimer = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(logout, INACTIVITY_LIMIT);
    };

    const logout = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            await useFetch.apiCallPost('/logout', { token: localStorage.getItem('token') });
            // Clear user session, tokens, etc.
            // Redirect to login page or show a logged-out message
            console.log("Session TimeOut due to in activity");
            localStorage.removeItem('navigateUser');
            localStorage.setItem('session', 'expired');
            localStorage.removeItem('token')
            localStorage.removeItem('email')
            localStorage.removeItem('firstName')
            localStorage.removeItem('lastName')
            localStorage.removeItem('ListIds')

            setUserState({
                ...userState,
                isAuth: false,
                token: null,
                email: null,
                firstName: null,
                lastName: null
            })
            navigate('/');
        }
    };

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });
        // Set the initial timer
        resetTimer();

        // Cleanup event listeners on component unmount
        return () => {
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, []);

    return <>{children}</>;
};

export default AutoLogout;
