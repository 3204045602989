import React,{useEffect} from 'react'
import './loginstyle.css'
import { Link ,useLocation} from 'react-router-dom'
import { Row, Col, Checkbox, Button } from 'antd'
import { ReactComponent as Satelite } from '../../Assets/images/SateliteLayer_1-2.svg'
import { ReactComponent as Earth } from '../../Assets/images/EarthGroup.svg'
import { ReactComponent as Signaltower } from '../../Assets/images/SignalTower.svg'
import { ReactComponent as DockedShip } from '../../Assets/images/DockedShip.svg'
import { ReactComponent as NavLogo } from '../../Assets/images/NavLogo.svg'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

import Login from '../../Components/Login/login'
import ForgetPassword from '../../Components/forgetPassword/indx'
import ResetPassword from '../../Components/forgetPassword/ResetPassword'
import Footer from '../../Components/footer/Footer'


const HigherOrder = props => {
  const [passwordVisible, setPasswordVisible] = React.useState(false)
  const {pathname}= useLocation();
  const location = useLocation();
useEffect(() => {
  setTimeout(() => {
    let synth=window.speechSynthesis;
    synth.cancel();
  }, 300);
   
}, [location]);
  return (
    <>
      <Row className='login-page-wrapper'>
        <Col  md={{span:8}} className="hidden md:block">
          <div className='signaltowersvg'>
            <Signaltower />
          </div>
          <div className='dockedshipsvg'>
            <DockedShip />
          </div>
        </Col>
        <Col xs={{span:24}}sm={{span:24}} md={{span:8}}  >
          <div className='navlogosvg flex flex-col items-center gap-y-10'>
            <NavLogo />
            {props?.title != undefined ? (
              <p className='screen-title'>{props?.title}</p>
            ) : null}
            {props?.children}
            {
              pathname=="/reset-password" ?
              <ResetPassword/>:
              pathname?.includes("/forgetPassword")?
              <ForgetPassword/>
              :<Login/>
            }
    

          </div>
        </Col>
        <Col  md={{span:8}} className="hidden md:block">
          <div className='satelitesvg'>
            <Satelite />
          </div>
          <div className='earthsvg'>
            <Earth />
          </div>
        </Col>
      </Row>
      
    </>
  )
}

export default HigherOrder
