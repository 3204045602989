import { useNavigate } from 'react-router'

export const CustomNavigation = () => {
  const navigate = useNavigate()
  const handleHomeClick = path => {
    alert(path)
    navigate(path)
  }
  return [handleHomeClick]
}
