import { ReactComponent as EmptyIcon } from "../../Assets/images/icons/emptysearch.svg"
import { useEffect, useState } from 'react';
export const EmptySearch = (props) => {
    return (
        <div className="empty-search">
            <div className="empty-search-img">
                <EmptyIcon />
            </div>
            <p className="empty-search-title"> {props.searchType == 1 ? "You haven't saved any searches yet" : "No Search History Found"}</p>
            {props.searchType == 1 && <p className="empty-search-message">To save a search, head over to the <b>"Search History"</b> tab.
                Simply click on the Search you want to save and then choose <b>"Rename & Save"</b> to add it to your "Saved Searches" tab.</p>}
        </div>
    )
}