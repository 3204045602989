import React, { useState, useEffect } from 'react'
import PlayIcon from '../../Assets/images/Marketcompass/icons/play-icon.svg'
import PauseIcon from '../../Assets/images/Marketcompass/icons/Pausebtn.svg'
import VolumeIcon from '../../Assets/images/Marketcompass/icons/Volumeicon.svg'
const TextToSpeech = ({ text }) => {
  const [isPaused, setIsPaused] = useState(false)
  const [toggelVoldisplay, setToggelVoldisplay] = useState(false)

  const [utterance, setUtterance] = useState(null)
  const [voice, setVoice] = useState(null)
  const [pitch, setPitch] = useState(1)
  const [rate, setRate] = useState(1)
  const [volume, setVolume] = useState(1)
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    const synth = window.speechSynthesis
    const u = new SpeechSynthesisUtterance(text)
    setUtterance(u)

    // Add an event listener to the speechSynthesis object to listen for the voiceschanged event
    synth.addEventListener('voiceschanged', () => {
      const voices = synth.getVoices()
      setVoice(voices[1])
    })
    u.onboundary = event => {
      const currentWord = event.charIndex

      setCurrent(currentWord)
      console.log('I am Awas Niaz')
    }
    u.addEventListener('mark', event => {
      // Handle the mark event here
      const { name } = event
      console.log('Mark:', name)
    })

    return () => {
      synth.cancel()
      synth.removeEventListener('voiceschanged', () => {
        setVoice(null)
      })
    }
  }, [text])

  const handlePlay = (type = 'start', data = {}) => {
    const synth = window.speechSynthesis
    if (type == 'start') {
      if (window.speechSynthesis?.paused === true) {
        synth.resume()
      } else {
        const voices = window.speechSynthesis.getVoices()
        utterance.voice = voice == 'Male' ? voices[2] : voices[1]
        utterance.pitch = pitch
        utterance.rate = rate
        utterance.volume = volume
        synth.speak(utterance)
      }
      setIsPaused(!isPaused)
    } else {
      // if (isPaused == false) {
      const synth = window.speechSynthesis
      synth.cancel()
      const utterance = new SpeechSynthesisUtterance(
        text.substring(current, text?.length)
      )

      utterance.onboundary = event => {
        const currentWord = event.charIndex
        setCurrent(currentWord)
      }
      const voices = window.speechSynthesis.getVoices()
      utterance.voice = data?.voice == 'Male' ? voices[1] : voices[2]
      utterance.pitch = data?.pitch
      utterance.rate = data?.rate
      utterance.volume = data?.volume
      console.log(utterance)
      synth.speak(utterance)
      setUtterance(utterance)
      // }
      // synth.resume()
    }

    // setIsPaused(false);
  }

  const handlePause = () => {
    const synth = window.speechSynthesis
    synth.pause()
    setIsPaused(!isPaused)
  }

  const handleStop = () => {
    const synth = window.speechSynthesis
    setIsPaused(false)
    synth.cancel()
  }

  const handleVoiceChange = event => {
    setVoice(event?.target.value)
    handlePlay('change', {
      voice: event?.target.value,
      rate: rate,
      pitch: pitch,
      volume: volume
    })
  }

  const handleRateChange = event => {
    setRate(parseFloat(event.target.value))
    handlePlay('change', {
      voice: voice,
      rate: event.target.value,
      pitch: pitch,
      volume: volume
    })
  }

  const handleVolumeChange = event => {
    setVolume(parseFloat(event.target.value))
    handlePlay('change', {
      voice: voice,
      rate: rate,
      pitch: pitch,
      volume: event.target.value
    })
  }

  return (
    <ul className='flex justify-around text-white'>
      <li>
        <button className='text-white'>
          {isPaused ? (
            <img
              src={PauseIcon}
              alt='pause'
              className='mt-2'
              onClick={() => handlePause()}
            />
          ) : (
            <img src={PlayIcon} alt='play' onClick={() => handlePlay()} />
          )}
        </button>
      </li>
      <li className='pt-1'>
        {isPaused ? <button onClick={handleStop}>Stop</button> : null}
      </li>
      <li
        className='w-2/4 h-1 bg-white rounded-3xl animate-pulse'
        style={{ margin: 'auto 0' }}
      ></li>
      <li className='pt-1'>
        {toggelVoldisplay ? (
          <input
            type='range'
            min='0'
            max='1'
            step='0.1'
            value={volume}
            className='range accent-[#fff] w-10 cursor-pointer h-2'
            onChange={handleVolumeChange}
          />
        ) : (
          <img
            src={VolumeIcon}
            alt='vol'
            className='cursor-pointer'
            onClick={() => setToggelVoldisplay(true)}
          />
        )}
      </li>
      <li style={{ margin: 'auto 0' }}>
        {/* <p className="font-semibold text-xs ">Male Voice</p> */}
        <select
          className='font-semibold text-xs cursor-pointer'
          value={voice}
          onChange={handleVoiceChange}
          style={{ background: '#0071C1' }}
        >
          <option className='font-semibold text-xs ' value={'Male'}>
            Male Voice
          </option>
          <option className='font-semibold text-xs ' value={'Female'}>
            Female Voice
          </option>
        </select>
      </li>
      <li className=' pt-1'>
        <span className='mr-2 font-semibold text-xs'>Speed</span>
        <select
          value={rate}
          onChange={handleRateChange}
          defaultValue={1}
          className='cursor-pointer'
          style={{ background: '#0071C1' }}
        >
          <option value={0.5}>0.5x</option>
          <option value={1}>1x</option>
          <option value={1.5}>1.5x</option>
          <option value={2}>2x</option>
        </select>
      </li>
    </ul>
  )
}

export default TextToSpeech
