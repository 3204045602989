import React,{useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import './styles.css'
const IframeComponent = ({ link }) => {
  const location = useLocation();
useEffect(() => {
  setTimeout(() => {
    let synth=window.speechSynthesis;
    synth.cancel();
  }, 300);
   
}, [location]);
  return (
    <iframe
      className='power-bi'
      src={link}
      //   style='height:200px;width:300px;'
      title='Iframe Example'
    ></iframe>
  )
}

export default IframeComponent
