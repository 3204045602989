import { ReactComponent as Ship } from '../Assets/images/NavigateMarine.svg'
import { ReactComponent as Satellite } from '../Assets/images/NavigateEarth.svg'
import { ReactComponent as Compass } from '../Assets/images/NavigateCompass.svg'
import { ReactComponent as Directory } from '../Assets/images/NavigateDirectory.svg'
import { ReactComponent as Iron } from '../Assets/images/ic_iron-ore.svg'
import { ReactComponent as Ferrous } from '../Assets/images/ic_ferrous-scrap.svg'
import { ReactComponent as Steel } from '../Assets/images/ic_steel.svg'
import { ReactComponent as Coal } from '../Assets/images/ic_coal.svg'
export const access_levels = [
  {
    path: '/ferrousScrap',
    id: 2,
    icon: <Ship className='w-3 h-3 ' />,
    text: 'Navigate Marine',
    key: 1
  },
  {
    path: '/welcome',
    id: 12,
    icon: <Satellite className='w-3 h-3 ' />,
    text: 'Navigate Earth',
    key: 2
  },
  {
    path: '/market-compass',
    id: 11,
    icon: <Compass className='w-3 h-3 ' />,
    text: 'Market Compass',
    key: 3
  },
  {
    path: '/inter-dir',
    id: 1,
    icon: <Directory className='w-3 h-3 ' />,
    text: 'Interactive Directory',
    key: 4
  },
  {
    path: '/iron-ore',
    id: 13,
    icon: <Iron className='w-3 h-3 ' />,
    text: 'Iron Ore',
    key: 5
  },
  {
    path: '/ferrous-scrap',
    id: 8,
    icon: <Ferrous className='w-3 h-3 ' />,
    text: 'Ferrous Scrap & Metallics',
    key: 6
  },
  {
    path: '/semis-steel',
    id: 9,
    icon: <Steel className='w-3 h-3 ' />,
    text: ' Semis & Finished Steel',
    key: 7
  },
  {
    path: '/coal-coke',
    id: 10,
    icon: <Coal className='w-3 h-3 ' />,
    text: 'Metallurgical Coal & Coke',
    key: 8
  }
]

export const submenuAccessLevel = [
  {
    path: '/fixtures',
    id: 5,
    text: 'Fixtures/Line-ups'
  },
  {
    path: '/company',
    id: 3,
    text: 'Voyages'
  },
  {
    path: '/fifa',
    id: 4,
    text: 'FIFA Scrap'
  }
]
export const getAccessLevels = () => {
  const accessLevel =
    localStorage.getItem('AccessLevelData') == undefined
      ? []
      : JSON.parse(localStorage.getItem('AccessLevelData'))
  return accessLevel;
}

export const checkAccessLevelById = (id) => {
  const ListId =
    localStorage.getItem('ListIds') == undefined
      ? []
      : JSON.parse(localStorage.getItem('ListIds'))
  const accessLevel = getAccessLevels()
  console.log(accessLevel)
  const user = JSON.parse(localStorage.getItem('navigateUser'))
  let currentItem = accessLevel?.find(i => id == i?.id)
  console.log(user)
  console.log(currentItem)
  if (currentItem?.lists?.length == 0) {
    console.log("noaccess")
    return
  }
  if (
    accessLevel &&
    // List Array
    ListId?.some(ai =>
      currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
    )
  ) {
    return true;
  }
  return false;
}
// export const checkAccessLevel = () => {
//   const ListId =
//     localStorage.getItem('ListIds') == undefined
//       ? []
//       : JSON.parse(localStorage.getItem('ListIds'))
//   const accessLevel = getAccessLevels()
//   console.log(accessLevel)
//   const user = JSON.parse(localStorage.getItem('navigateUser'))

//   let currentItem = accessLevel?.find(i => 2 == i?.id)
//   console.log(user)
//   console.log(currentItem)
//   if (currentItem?.lists?.length == 0) {
//     console.log("noaccess")
//     return
//   }
//   if (
//     accessLevel &&
//     // List Array
//     ListId?.some(ai =>
//       currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
//     )
//   ) {
//     return '/fixtures';
//   } else {
//     currentItem = accessLevel?.find(i => 10 == i?.id)
//     if (currentItem?.lists?.length == 0) {
//       console.log("noaccess")
//       return
//     }
//     if (
//       accessLevel &&
//       // List Array
//       ListId?.some(ai =>
//         currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
//       )
//     ) {
//       return '/company';
//     }
//     else {
//       currentItem = accessLevel?.find(i => 11 == i?.id)
//       if (currentItem?.lists?.length == 0) {
//         console.log("noaccess")
//         return;
//       }
//       if (
//         accessLevel &&
//         // List Array
//         ListId?.some(ai =>
//           currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
//         )
//       ) {
//         return '/fifa';
//       }
//       else {
//         warning("Fixtures/Line-ups")
//         return '/welcome';
//       }
//     }
//     // setTrail(true)
//   }
// }

export const allAccessLevel = [...access_levels, ...submenuAccessLevel]
