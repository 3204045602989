import axios from 'axios'
export const TIMEOUT = 150000
// const token ='863d40d82bd3fa244e03c67809597f281932740592839b000cd';
const acesstoken = localStorage.getItem('token')
const instance = axios.create({
  baseURL:process.env.REACT_APP_BACKEND_URL,
  timeout: TIMEOUT
})

instance.interceptors.response.use(
  response => {
    console.log(response) 
    const { status, errorCode } = response?.data
    if (status == 'failed' && errorCode == 440) {
      localStorage.removeItem('token')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      window.location.href = '/'
    }
    return response
  },
  error => {
    return error
  }
)
export default instance
