// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper {
  min-height: 10vh;
  height: auto;
  width: 100%;
  background-color: #333333;
  padding: 0px 25px;
}
.footerItemsList {
  border-right: 1px solid #8e8e8e;
  font-weight:500;
  color:rgba(194, 194, 194, 1) !important;
}
.footer-item-spacing {
  padding: 0 2% 0 2%;
}

.footer-text {
  font-weight: 500 !important;
}

.ant-table-wrapper {
  height: calc(100vh - 231px) !important;
  overflow: scroll !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1360px) {
  .footer-setting {
    /* margin-top: 70px; */
  }
  .ant-table-wrapper {
    height: calc(100vh - 243px) !important;
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1605px) {
  .footer-setting {
    /* margin-top: 70px; */
  }
  .ant-table-wrapper {
    height: calc(100vh - 232px) !important;
  }
}

@media only screen and (min-width: 1605px) {
  /* STYLES GO HERE */
  .compass-part {
    width: 80%;
    margin: 0 10%;
  }
  .ant-table-wrapper {
    height: calc(100vh - 255px) !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,+BAA+B;EAC/B,eAAe;EACf,uCAAuC;AACzC;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,sCAAsC;EACtC,2BAA2B;AAC7B;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,sCAAsC;EACxC;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,sCAAsC;EACxC;AACF;;AAEA;EACE,mBAAmB;EACnB;IACE,UAAU;IACV,aAAa;EACf;EACA;IACE,sCAAsC;EACxC;AACF","sourcesContent":[".footer-wrapper {\n  min-height: 10vh;\n  height: auto;\n  width: 100%;\n  background-color: #333333;\n  padding: 0px 25px;\n}\n.footerItemsList {\n  border-right: 1px solid #8e8e8e;\n  font-weight:500;\n  color:rgba(194, 194, 194, 1) !important;\n}\n.footer-item-spacing {\n  padding: 0 2% 0 2%;\n}\n\n.footer-text {\n  font-weight: 500 !important;\n}\n\n.ant-table-wrapper {\n  height: calc(100vh - 231px) !important;\n  overflow: scroll !important;\n}\n\n@media only screen and (min-device-width: 768px) and (max-device-width: 1360px) {\n  .footer-setting {\n    /* margin-top: 70px; */\n  }\n  .ant-table-wrapper {\n    height: calc(100vh - 243px) !important;\n  }\n}\n\n@media only screen and (min-width: 1370px) and (max-width: 1605px) {\n  .footer-setting {\n    /* margin-top: 70px; */\n  }\n  .ant-table-wrapper {\n    height: calc(100vh - 232px) !important;\n  }\n}\n\n@media only screen and (min-width: 1605px) {\n  /* STYLES GO HERE */\n  .compass-part {\n    width: 80%;\n    margin: 0 10%;\n  }\n  .ant-table-wrapper {\n    height: calc(100vh - 255px) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
