import React, { useRef } from 'react'

const Marker = ({ children, feature ,map}) => {
const markerRef=useRef();

  const handleMarkerBtnClick=(map)=>{
    map.flyTo({
      center: [Number(feature?.lon),Number(feature?.lat)],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      zoom:18
      });
    
  }
  function handleBackgroundChange(){
    const element=markerRef.current;
    element.classList.remove('interactivemarker');
    element.classList.add('active-marker-bg');
    setTimeout(() => {
      element.classList.add('interactivemarker');
      element.classList.remove('active-marker-bg');
    }, 9000);
  
  }
    return (
      <div className='marker-wrapper'>
        {/* refactor */}
      <button className="interactivemarker" onClick={()=>{handleMarkerBtnClick(map);handleBackgroundChange()}} ref={markerRef}>
      {children}
    </button>
    </div>
    );
  };
  
export default Marker