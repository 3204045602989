import React, { useEffect } from 'react'
import { Drawer, Button, Spin } from 'antd'
// svg
import { ReactComponent as FilterIcon } from '../Assets/images/filter-icon.svg'
import { ReactComponent as LeftArrow } from '../Assets/images/icons/leftArrow.svg'

const GlobalFilterDrawer = ({
  children,
  Filters,
  placement,
  closable,
  open,
  onClose,
  extraFilteration,
  uniqueFilter,
  dwtValue,
  arrivalandshipment,
  getShipmentExtraFilter
  ////getCompaniesdata
}) => {
  useEffect(() => {
    if (!uniqueFilter && open && arrivalandshipment?.[Filters?.type] == undefined) {
      console.log("Handle unique call if data not avaliable", Filters?.type);
      getShipmentExtraFilter();
    }
  }, [arrivalandshipment, uniqueFilter])
  return (
    <>

      <Drawer
        placement={placement}
        closable={closable}
        onClose={onClose}
        open={open}
        className='antd-cutom-drawer overflow-hidden overscroll-none'
      > {uniqueFilter == true ? <div className='loader-container'>
        <Spin />
      </div> : null}
        <div
          className='bg-white absolute w-11  h-12 top-2/4 -right-10 flex justify-center items-center rounded-tr rounded-br cursor-pointer arrows'
          onClick={onClose}
        >
          <LeftArrow />
        </div>
        <div className='border-b border-blue-200 p-5 flex justify-between'>
          <div className='flex'>
            <FilterIcon />
            <p className='font-medium text-base leading-5 ml-1'> Filters</p>
          </div>
        </div>

        <div className='antd-custom-drawer-form-wrapper-g p-7 '>{children}</div>
        <div className='bg-white p-5 flex justify-between w-full absolute bottom-0'>
          <Button
            type='link'
            className='font-semibold text-base'
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            disabled={
              Object?.keys(Filters?.applyExtraFilter)?.length > 0 ? false :
                ((dwtValue.dwt_start !== undefined && dwtValue.dwt_start !== null) || (dwtValue.dwt_end !== undefined && dwtValue.dwt_end !== null)) ? false : true
            }
            type='primary'
            className='font-semibold text-base '
            style={{ lineHeight: 0, padding: '10px' }}
            onClick={() => {
              extraFilteration()
              onClose()
              //getCompaniesdata()
            }}
          >
            Apply Filters
          </Button>
        </div>
      </Drawer>
    </>
  )
}

export default GlobalFilterDrawer
