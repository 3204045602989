import { useState } from 'react';
import { Menu, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";


function HamburgerMenu() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <Menu mode="horizontal">
        <Menu.Item key="hamburger" icon={<MenuOutlined />} onClick={showDrawer}>
        </Menu.Item>
      </Menu>
      <Drawer placement="right" closable={false} onClose={onClose} open={visible}>
        <Menu style={{ width: 256 }} mode="vertical">
          <Menu.Item key="item1" onClick={onClose}>
            <NavLink to="/ferrousScrap" activeclassname="active">
              Ferrous Scrap
            </NavLink>
          </Menu.Item>
          <Menu.Item key="item2" onClick={onClose}>
            <NavLink to="/merchantPigIron" activeclassname="active">
              Merchant Pig Iron
            </NavLink>
          </Menu.Item>
          <Menu.Item key="item3" onClick={onClose}>
            <NavLink to="/driHbi" activeclassname="active">
              DRI/HBI
            </NavLink>
          </Menu.Item>
          <Menu.Item key="item4" onClick={onClose}>
            <NavLink to="/ironOre" activeclassname="active">
              Iron Ore
            </NavLink>
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
}
export default HamburgerMenu