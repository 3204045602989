import { useEffect, useState } from 'react';
import { LegendSvg } from './LegendSvg';
import { Checkbox } from 'antd';
export const Legend = (props) => {

    const handleClick = (legend) => {
        props?.legends?.includes(legend) ? props.removeLegend(legend) : props.addLegend(legend)
    }
    return (
        <div className="map-legend">
            <div className={"legend-item"} onClick={() => { handleClick('Mini') }}>
                <Checkbox className='legned-checkbox' checked={props?.legends?.includes('Mini')} />
                <div className='map-legend-img' ><LegendSvg color="#FFFFFF" /></div>
                <p className='legend-text'> Mini (0 to 9,999 DWT)</p>
            </div>
            <div className={"legend-item"} onClick={() => { handleClick('Handysize') }}>
                <Checkbox className='legned-checkbox' checked={props?.legends?.includes('Handysize')} />
                <div className='map-legend-img' ><LegendSvg color="#0FF7F7" /></div>
                <p className='legend-text'> Handysize (10,000-39,999 DWT)</p>
            </div>
            <div className={"legend-item"} onClick={() => { handleClick('Handymax') }}>
                <Checkbox className='legned-checkbox' checked={props?.legends?.includes('Handymax')} />
                <div className='map-legend-img' ><LegendSvg color="#FF44CC" /></div>
                <p className='legend-text'> Handymax (40,000-59,999 DWT)</p>
            </div>
            <div className={"legend-item"} onClick={() => { handleClick('Panamax') }}>
                <Checkbox className='legned-checkbox' checked={props?.legends?.includes('Panamax')} />
                <div className='map-legend-img' ><LegendSvg color="#FFFF00" /></div>
                <p className='legend-text'> Panamax (60,000-119,999 DWT)</p>
            </div>
            <div className={"legend-item"} onClick={() => { handleClick('Capesize') }} >
                <Checkbox className='legned-checkbox' checked={props?.legends?.includes('Capesize')} />
                <div className='map-legend-img' ><LegendSvg color="#0047FF" /></div>
                <p className='legend-text'> Capesize (120,000-199,999 DWT)</p>
            </div>
            <div className={"legend-item"} onClick={() => { handleClick('VLOC') }} >
                <Checkbox className='legned-checkbox' checked={props?.legends?.includes('VLOC')} />
                <div className='map-legend-img' ><LegendSvg color="#31D812" /></div>
                <p className='legend-text'> VLOC (200,000+ DWT)</p>
            </div>
        </div>
    )
}