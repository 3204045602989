import { Link, useNavigate } from 'react-router-dom'
import { Alert, Checkbox, Input } from 'antd'
import { useState, useContext, useEffect } from 'react'
import { utilities } from '../../utilities/commonfunction'
import FieldValidation from '../fieldsvalidation'
import UseFetchAxios from '../../Hooks/UseFetchAxios'
import UserContext from '../../store/UserContext'
import { useDispatch } from 'react-redux'
import { Spin } from 'antd'
import {
  getSeaRoutes,
  getCoLoadRoutes,
  RemoveAllDataFromDB
} from '../../Pages/Company/company-reducers'
import './style.css'

const Login_component = () => {
  const { userState, setUserState } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [authError, setAuthError] = useState('')
  const navigate = useNavigate()
  const [logindata, setLogindata] = useState({
    term: false,
    data: {
      email: '',
      password: ''
    },
    errors: {
      name: '',
      Password: ''
    }
  })

  const useFetch = UseFetchAxios()

  const setError = (msg, key) => {
    setLogindata({
      ...logindata,
      errors: {
        ...logindata.errors,
        [key]: msg
      }
    })

    // setTimeout(() => {
    //   setLogindata({
    //     ...logindata,
    //     errors: {
    //       ...logindata.errors,
    //       [key]: ''
    //     }
    //   })
    // }, 5000)
  }

  useEffect(() => {
    const session = localStorage.getItem('session')
    console.log(session)
    if (session === "expired") {
      localStorage.removeItem('session')
      setAuthError("Session Expired. Please log in again.")
    }
  }, [])

  const getAccessLevels = async () => {
    try {
      const response = await axios.get('/access-levels', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response?.data) {
        console.log('I am In Login Page', response?.data)
        // setAccessLevels(response?.data?.data)
        localStorage.setItem(
          'AccessLevel',
          JSON.stringify(response?.data?.data)
        )
      }
    } catch (error) { }
  }

  const [currentDateTime, setCurrentDateTime] = useState(null);

  const fetchCurrentDateTime = async () => {
    try {
      const response = await fetch('https://worldtimeapi.org/api/ip');
      const data = await response.json();
      const date = data.utc_datetime.substring(0, 10);
      setCurrentDateTime(date)
      return date
    } catch (error) {
      console.error('Error fetching current date and time:', error);
    }
  };

  useEffect(() => {
    fetchCurrentDateTime()
  }, [])

  const userLogin = () => {
    const email = logindata?.data?.email
    const password = logindata?.data?.password
    const emailcheck = utilities?.checkMail(email)
    if (emailcheck?.trim()?.length != 0) {
      setError(emailcheck, 'name')
      return
    }
    if (password?.trim()?.length == 0) {
      setError('Enter a Valid Password', 'Password')
      return
    }
    setLoading(true)
    useFetch?.apiCallPost('/login', logindata?.data, (err, data) => {
      // debugger

      if (data?.status == 'success') {
        if (data?.user?.SERVICE_END != null || data?.user?.SERVICE_START != null) {
          // const currentDate= currentDateTime? new Date(currentDateTime): new Date();
          const today = currentDateTime ? new Date(currentDateTime) : new Date();
          const todayYear = today.getFullYear()
          const todayMonth = today.getMonth()
          const todayDay = today.getDate()
          const todayUtc = Date.UTC(todayYear, todayMonth, todayDay)
          // const currentDate = new Date(currentDateTime)
          // currentDate.setHours(5, 0, 0, 0);
          let serviceStartUTC;
          let serviceEndUTC;
          if (data?.user?.SERVICE_START) {
            const dateArr = data?.user?.SERVICE_START.split('-')
            serviceStartUTC = Date.UTC(dateArr[0], dateArr[1] - 1, dateArr[2])
          }
          if (data?.user?.SERVICE_END) {
            const dateArr = data?.user?.SERVICE_END.split('-')
            serviceEndUTC = Date.UTC(dateArr[0], dateArr[1] - 1, dateArr[2])
          }

          // const serviceStartDate = data?.user?.SERVICE_START ? new Date(data?.user?.SERVICE_START) : null;
          // const serviceEndDate = data?.user?.SERVICE_END ? new Date(data?.user?.SERVICE_END) : null;
          console.log(todayUtc)

          // if ((serviceStartDate === null || currentDate >= serviceStartDate) &&
          //   (serviceEndDate === null || currentDate <= serviceEndDate)) {
          if ((serviceStartUTC === null || todayUtc >= serviceStartUTC) &&
            (serviceEndUTC === null || todayUtc <= serviceEndUTC)) {
            console.log('Current date is between SERVICE_START and SERVICE_END (or one of them is null)');
            localStorage.setItem('token', data?.authorisation?.token)
            localStorage.setItem('email', data?.user?.email)
            localStorage.setItem('firstName', data?.user?.FIRSTNAME)
            localStorage.setItem('lastName', data?.user?.LASTNAME)
            localStorage.setItem('ListIds', data?.user?.listIds)
            localStorage.setItem('userId', data?.user?.id)
            localStorage.setItem('navigateUser', JSON.stringify(data?.user))
            dispatch(getCoLoadRoutes(data?.authorisation?.token))
            dispatch(getSeaRoutes(data?.authorisation?.token))
            getAccessLevels()
            setUserState({
              ...userState,
              isAuth: true,
              token: data?.authorisation?.token,
              email: data?.user?.email,
              firstName: data?.user?.FIRSTNAME,
              lastName: data?.user?.LASTNAME
            })
            console.log(
              'token',
              data?.authorisation?.token,
              'email',
              data?.user?.email
            )

            navigate('/welcome')
          }
          else {
            setAuthError('Access Expired. Please contact your account manager or email phyllis.shung@navigatecommodities.com')
          }
        }
        else {
          localStorage.setItem('token', data?.authorisation?.token)
          localStorage.setItem('email', data?.user?.email)
          localStorage.setItem('firstName', data?.user?.FIRSTNAME)
          localStorage.setItem('lastName', data?.user?.LASTNAME)
          localStorage.setItem('ListIds', data?.user?.listIds)
          localStorage.setItem('userId', data?.user?.id)
          localStorage.setItem('navigateUser', JSON.stringify(data?.user))
          dispatch(getCoLoadRoutes(data?.authorisation?.token))
          dispatch(getSeaRoutes(data?.authorisation?.token))
          getAccessLevels()
          setUserState({
            ...userState,
            isAuth: true,
            token: data?.authorisation?.token,
            email: data?.user?.email,
            firstName: data?.user?.FIRSTNAME,
            lastName: data?.user?.LASTNAME
          })
          console.log(
            'token',
            data?.authorisation?.token,
            'email',
            data?.user?.email
          )

          navigate('/welcome')
        }
      } else {
        if ('Unauthorized'?.trim() == err?.response?.data?.message) {
          setAuthError('You have entered an invalid Email or Password')
        } else {
          setAuthError(err?.response?.data?.message)
        }
      }
      setLoading(false)
    })
  }

  const updateLogindata = (key, value) => {
    setLogindata({
      ...logindata,
      data: { ...logindata?.data, [key]: value }
      , errors: {
        name: '',
        Password: ''
      }
    })
    setAuthError('')
  }
  return (
    <div className='flex flex-col'>
      {authError !== '' ? (
        <div className='flex flex-col gap-y-2 justify-start items-center mt-5'>
          <p className='text-600 text-center font-semibold' style={{ color: '#ffdb00', maxWidth: '400px' }}>
            {authError}
          </p>
        </div>
      ) : null}
      <div className='flex flex-col gap-y-2 justify-start items-start mt-5'>
        <label className=' login-label'>Email address*</label>
        <Input
          className='login-fields'
          onChange={e => {
            updateLogindata('email', e?.target?.value)
          }}
        />
        {/* <FieldValidation style={{color:'yellow'}} msg={logindata?.errors?.name}></FieldValidation> */}
        {logindata?.errors?.name?.length > 0 ? <p style={{ color: 'rgb(255, 219, 0)' }}>{logindata?.errors?.name}</p> : null}

      </div>
      <div className='flex flex-col gap-y-2 justify-start items-start mt-5'>
        <label className=' login-label'>Password*</label>
        <Input.Password
          className='login-fields'
          type='password'
          onChange={e => {
            updateLogindata('password', e?.target?.value)
          }}
        />
        {/* <FieldValidation msg={logindata?.errors?.Password}></FieldValidation> */}
        {logindata?.errors?.Password?.length > 0 ? <p style={{ color: 'rgb(255, 219, 0)' }}>{logindata?.errors?.Password}</p> : null}
      </div>
      <div className='flex justify-center gap-y-4 md:justify-between mt-2 login-label flex-col md:flex-row '>
        {/* <div>
          <Checkbox className='login-label' onChange={e => {}}>
            Keep me logged in
          </Checkbox>
        </div> */}
        <label className='container'>
          Keep me logged in
          <input type='checkbox' />
          <span className='checkmark'></span>
        </label>
        <div>
          <Link to='/reset-password' className='login-label'>
            {' '}
            Forget your password?
          </Link>
        </div>
      </div>
      <div className='flex justify-between mt-6 login-label'>
        <button className='login-btn' onClick={userLogin}>
          {loading == true ? <Spin /> : <span>Login</span>}
        </button>
      </div>
    </div>
  )
}
export default Login_component
