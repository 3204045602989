import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import './marketcompass.css'
import { ReactComponent as LeftVector } from '../../Assets/images/Marketcompass/MC_vector_left.svg'
import { ReactComponent as RightVector } from '../../Assets/images/Marketcompass/MC_vector_right.svg'
import { ReactComponent as MediaIcon } from '../../Assets/images/Marketcompass/MC-icon.svg'
import ReactHtmlParser from 'react-html-parser'
import Carosel from '../../Components/Compass/Carosel'
import MediaCarosel from '../../Components/Compass/MediaCarosel'
import Mctabs from '../../Components/Compass/Mctabs'
import { Spin,Modal } from 'antd'
import UseFetch from '../../Hooks/UseFetch'
import ReadMoreLink from '../../Components/Compass/ReadMoreLink'
import InFocusLinks from '../../Components/Compass/InFocusLinks'
import axios from '../../axiosconfiguration/axios'
import dayjs from 'dayjs'

const MarketCompass = () => {
  const fetchMCInFocus = UseFetch('/market-compass/in-focus', 'get', '')
  const [slideIndex, setSlideIndex] = useState(0)
  const [ferrousData, setFerrousData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [SteelData, setSteelData] = useState(null)
  const [ironOreData, setIronOreData] = useState(null)
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      let synth = window.speechSynthesis
      synth.cancel()
    }, 300)
  }, [location])
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      })
    }, 400)
  }, [])

  useEffect(() => {
    getMC()
  }, [])

  const getMC = async () => {
    setLoading(true)
    try {
      const res = await axios.get('/market-compass/listing?limit=6', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      if (res?.data) {
        setFerrousData(
          Object.values(res?.data?.data)[0]?.['market-compass']['data']
        )
        setSteelData(
          Object.values(res?.data?.data)[1]?.['market-compass']['data']
        )
        setIronOreData(
          Object.values(res?.data?.data)[2]?.['market-compass']['data']
        )
      }
    } catch (error) {}
    setLoading(false)
  }

  // useEffect(() => {
  //  console.log('slide index lol-------------->',slideIndex)
  // }, [slideIndex])

  let content = null
  if (
    fetchMCInFocus?.data?.data !== null &&
    fetchMCInFocus?.data?.data !== undefined
  ) {
    content = [
      {
        flag:
          Object.values(fetchMCInFocus?.data?.data)[0]?.name ||
          'Content unavailable',
        title:
          Object.values(fetchMCInFocus?.data?.data)[0][
            'market-compass'
          ]?.title?.substring(0, 50) + ' ....',
        description:
          Object.values(fetchMCInFocus?.data?.data)[0][
            'market-compass'
          ]?.content?.substring(3, 101) || 'Content unavailable',
        image: Object.values(fetchMCInFocus?.data?.data)[0]['market-compass']
          ?.image
      },
      {
        flag:
          Object.values(fetchMCInFocus?.data?.data)[1]?.name ||
          'Content unavailable',
        title:
          Object.values(fetchMCInFocus?.data?.data)[1]?.[
            'market-compass'
          ]?.title?.substring(0, 50) + ' ....',
        description:
          Object.values(fetchMCInFocus?.data?.data)[1]?.[
            'market-compass'
          ]?.content?.substring(3, 101) || 'Content unavailable',
        image: Object.values(fetchMCInFocus?.data?.data)[1]?.['market-compass']
          ?.image
      },
      {
        flag:
          Object.values(fetchMCInFocus?.data?.data)[2]?.name ||
          'Content unavailable',
        title:
          Object.values(fetchMCInFocus?.data?.data)[2]?.[
            'market-compass'
          ]?.title?.substring(0, 50) + ' ....',
        description:
          Object.values(fetchMCInFocus?.data?.data)[2]?.[
            'market-compass'
          ]?.content?.substring(3, 101) || 'Content unavailable',
        image: Object.values(fetchMCInFocus?.data?.data)[2]?.['market-compass']
          ?.image
      }
    ]
  }

  const warning = text => {
    Modal.warning({
      title: <p className='text-xl'>Access Denied</p>,
      content: (
        <div>
          <p>
            We're sorry, but you don’t have permission to access <b>“{text}”</b>
          </p>
          <br />
          <p>
            We'd love to grant you access! Simply send us a request, and we'll
            take care of the rest. <br />{' '}
            <a
              className='text-sky-700'
              href='mailto:support@navigatecommodities.com'
            >
              support@navigatecommodities.com
            </a>{' '}
          </p>
        </div>
      ),
      okText: 'Got it',
      bodyStyle: {
        borderRadius: '0px !important'
      }
    })
  }

  const handleStateData= (index)=> {
    let trailversion=false
    if (
      fetchMCInFocus?.data?.data !== null &&
      fetchMCInFocus?.data?.data !== undefined &&
      ferrousData !== null
    ) {
      const category=(Object.values(fetchMCInFocus?.data?.data)[index])
      console.log(category)
      const accessLevel =
      localStorage.getItem('AccessLevelData') == undefined
        ? []
        : JSON.parse(localStorage.getItem('AccessLevelData'))
      const ListId =
      localStorage.getItem('ListIds') == undefined
        ? []
        : JSON.parse(localStorage.getItem('ListIds'))
      console.log(ListId)
      const categoryListIds = category.lists.map(list =>list.list_id);
      console.log(categoryListIds)

      let currentItem = accessLevel?.find(i => 6 == i?.id)
      if (currentItem?.lists?.length == 0) {
        trailversion=false
      }
      if (
        accessLevel &&
        // List Array
        ListId?.some(ai =>
          currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
        )
      )
        trailversion=true
      else
        trailversion=false

      console.log(trailversion)
      let actualAccess=false
      let access=false
      categoryListIds.forEach(categoryListId => {
        if (ListId.includes(categoryListId)) {
          access = true;
        }
      });
      console.log(access)
      if(access==true){

        const blog=(Object.values(fetchMCInFocus?.data?.data)[index]?.['market-compass'])
        console.log(blog)
        let blogExpired=false
        if(trailversion){
          const lastMonth = dayjs().subtract(1, 'month');
          const blogDate=dayjs(blog?.date)
          if(blogDate<lastMonth){
            blogExpired=true
          }
        }
        console.log(blogExpired)
        if(!blogExpired)
        {
          const blogListIds = blog.lists.map(list =>list.list_id);
          console.log(blogListIds)
          if(blogListIds.length>0)
          {
            blogListIds.forEach(blogListId => {
              if (ListId.includes(blogListId)) {
                actualAccess = true;
              }
            });
          }
          else
            actualAccess=true;
        }
      }
      

      return {
        id: Object.values(fetchMCInFocus?.data?.data)[index]?.['market-compass']
          ?.id,
        title: Object.values(fetchMCInFocus?.data?.data)[index]?.[
          'market-compass'
        ]?.title,
        content: Object.values(fetchMCInFocus?.data?.data)[index]?.[
          'market-compass'
        ]?.content,
        image: Object.values(fetchMCInFocus?.data?.data)[index]?.[
          'market-compass'
        ]?.image,
        supply: Object.values(fetchMCInFocus?.data?.data)[index]?.[
          'market-compass'
        ]?.supply,
        demand: Object.values(fetchMCInFocus?.data?.data)[index]?.[
          'market-compass'
        ]?.demand,
        date: Object.values(fetchMCInFocus?.data?.data)[index]?.[
          'market-compass'
        ]?.date,
        from: Object.values(fetchMCInFocus?.data?.data)[index]?.name,
        disclaimer: fetchMCInFocus?.data?.data?.disclaimers,
        relatedData: ferrousData,
        access:actualAccess
      }
    }
  }
  console.log('disclaimer data', fetchMCInFocus?.data?.data?.disclaimers)

  return loading == true ? (
    <div className='flex w-full h-screen justify-center items-center'>
      <Spin />
    </div>
  ) : (
    <>
      <div className='MC-bg-wrapper  '>
        <div className='absolute z-0' style={{ top: '1496px' }}>
          <LeftVector />
        </div>
        <div className='absolute z-0' style={{ top: '700px', right: 0 }}>
          <RightVector />
        </div>
        <div className='MC-bg-top '>
          <div className='MC-wrapper w-11/12 md:w-10/12'>
            <h1 className='font-bold text-2xl text-white ml-5 mt-4'>
              In Focus
            </h1>
            <div className='grid grid-cols-3 '>
              <div className='pl-5 pt-2 pr-5 pb-5 col-span-3  lg:col-span-2'>
                <div className='w-full'>
                  <Carosel
                    apiContent={fetchMCInFocus}
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                  />

                  <div className='h-64 sm:h-55 overflow-hidden w-full'>
                    <h1
                      className='text-white mt-7 font-bold  capitalize '
                      style={{ fontSize: '28px' }}
                    >
                      {slideIndex == 0
                        ? (fetchMCInFocus?.data?.data !== null &&
                            fetchMCInFocus?.data?.data !== undefined &&
                            Object.values(fetchMCInFocus?.data?.data)[0]?.[
                              'market-compass'
                            ]?.title) ||
                          'Content unavailable'
                        : slideIndex == 1
                        ? (fetchMCInFocus?.data?.data !== null &&
                            fetchMCInFocus?.data?.data !== undefined &&
                            Object.values(fetchMCInFocus?.data?.data)[1]?.[
                              'market-compass'
                            ]?.title) ||
                          'Content unavailable'
                        : (fetchMCInFocus?.data?.data !== null &&
                            fetchMCInFocus?.data?.data !== undefined &&
                            Object.values(fetchMCInFocus?.data?.data)[2]?.[
                              'market-compass'
                            ]?.title) ||
                          'Content unavailable'}
                    </h1>

                    {/* slide content */}
                    {slideIndex == 0 ? (
                      <div className='infocus-description-wrapper mt-3  text-white  '>
                        <p className='leading-6 font-normal text-sm '>
                          {(fetchMCInFocus?.data?.data !== null &&
                            fetchMCInFocus?.data?.data !== undefined &&
                            ReactHtmlParser(
                              Object.values(fetchMCInFocus?.data?.data)[0]
                                ?.['market-compass']?.content?.substring(3, 350)
                                ?.replace('- wp:paragraph -->', '')
                            )) ||
                            'data not found'}
                          {fetchMCInFocus?.data?.data !== null &&
                          fetchMCInFocus?.data?.data !== undefined ? (
                            handleStateData(0)?.access?
                            <span>
                              ...
                              <ReadMoreLink statedata={handleStateData(0)} />
                            </span>:
                            <span>
                              ...{' '}
                              <span onClick={()=>{warning(handleStateData(0).title)}} className='font-semibold text-xs underline leading-4 underline-offset-4 cursor-pointer'>READ MORE</span>
                            </span>
                          ) : null}
                        </p>
                      </div>
                    ) : slideIndex == 1 ? (
                      <div className='infocus-description-wrapper mt-3  text-white'>
                        <p className='leading-6 font-normal text-sm '>
                          {(fetchMCInFocus?.data?.data !== null &&
                            fetchMCInFocus?.data?.data !== undefined &&
                            ReactHtmlParser(
                              Object.values(fetchMCInFocus?.data?.data)[1]
                                ?.['market-compass']?.content?.substring(3, 350)
                                ?.replace('- wp:paragraph -->', '')
                            )) ||
                            'data not found'}
                          {fetchMCInFocus?.data?.data !== null &&
                          fetchMCInFocus?.data?.data !== undefined &&
                          Object.values(fetchMCInFocus?.data?.data)[1]?.[
                            'market-compass'
                          ]?.content?.substring(3, 240) != undefined ? (
                            handleStateData(1)?.access?
                            <span>
                              ...
                              <ReadMoreLink statedata={handleStateData(1)} />
                            </span>
                            :<span>
                            ...{' '}
                            <span onClick={()=>{warning(handleStateData(1)?.title)}} className='font-semibold text-xs underline leading-4 underline-offset-4 cursor-pointer'>READ MORE</span>
                          </span>
                          ) : null}
                        </p>
                      </div>
                    ) : (
                      <div className='infocus-description-wrapper mt-3  text-white'>
                        <p className='leading-6 font-normal text-sm '>
                          {(fetchMCInFocus?.data?.data !== null &&
                            fetchMCInFocus?.data?.data !== undefined &&
                            ReactHtmlParser(
                              Object.values(fetchMCInFocus?.data?.data)[2]
                                ?.['market-compass']?.content?.substring(3, 350)
                                ?.replace('- wp:paragraph -->', '')
                            )) ||
                            'data not found'}
                          {fetchMCInFocus?.data?.data !== null &&
                          fetchMCInFocus?.data?.data !== undefined &&
                          Object.values(fetchMCInFocus?.data?.data)[2]?.[
                            'market-compass'
                          ]?.content?.substring(3, 240) != undefined ? (
                            handleStateData(2)?.access?
                            <span>
                              ...
                              <ReadMoreLink statedata={handleStateData(2)} />
                            </span>
                            :<span>
                              ...{' '}
                              <span onClick={()=>{warning(handleStateData(2)?.title)}} className='font-semibold text-xs underline leading-4 underline-offset-4 cursor-pointer'>READ MORE</span>
                            </span>
                          ) : null}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* 2nd column focus links */}
              <div className='pl-5 pt-2 pr-5 pb-5 hidden  lg:col-span-1 lg:block  listing'>
                {fetchMCInFocus?.data !== null &&
                  content?.map((item, index) => {
                    return (
                      <div key={item.flag}>
                        <div
                          className={` ${
                            slideIndex == 0 &&
                            fetchMCInFocus?.data !== null &&
                            item?.flag ==
                              Object.values(fetchMCInFocus?.data?.data)[0]?.name
                              ? 'border-l-4  border-white'
                              : slideIndex == 1 &&
                                fetchMCInFocus?.data !== null &&
                                item?.flag ==
                                  Object.values(fetchMCInFocus?.data?.data)[1]
                                    ?.name
                              ? ' border-l-4  border-white'
                              : slideIndex == 2 &&
                                fetchMCInFocus?.data !== null &&
                                item?.flag ==
                                  Object.values(fetchMCInFocus?.data?.data)[2]
                                    ?.name
                              ? 'border-l-4  border-white'
                              : 'border-l-4 border-light-blue transition ease-in-out delay-75 hover:border-white'
                          } pl-4 mb-10 pt-1`}
                          style={{ height: '170px' }}
                        >
                          <InFocusLinks
                            flag={item.flag}
                            title={item?.title}
                            description={item?.description}
                            statedata={handleStateData(index)}
                            warning={()=>{warning(handleStateData(index).title)}}
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
            {/* media */}
            <div className='media-carosel-wrapper w-full rounded-md pt-6 pb-4  '>
              <h1 className='font-semibold flex ml-20 text-xl leading-6'>
                <span className='mr-2'>
                  <MediaIcon />
                </span>
                Media Coverage
              </h1>
              <MediaCarosel />
            </div>
            {/* tabs */}
            <Mctabs warning={(prop)=>{warning(prop)}}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default MarketCompass
