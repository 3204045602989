import { React, useRef, useEffect, useState, createRef } from 'react'
import ReactDOM from 'react-dom/client'
import { renderToString } from 'react-dom/server'

import mapboxgl from 'mapbox-gl'
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
import './Interactivedir.css'

import Dropdowns from './Dropdowns'
import Marker from '../../Components/Marker/index'
import CapacitySlider from './CapacitySlider'
import MarkerHtmL from './MarkerHtml'

import axios from '../../axiosconfiguration/axios'

mapboxgl.accessToken =
  'pk.eyJ1IjoiY2FzcGlhbmdyb3VwIiwiYSI6ImNpcWt1cnp0MzAwMmRpM21iN2hqbXZ3b2MifQ.s3g4iqhjULtckf5ZdjE9yg'

const InteractiveMap = () => {
  const [filterMetaData, setFilterMetaData] = useState({
    country: '',
    countryState: '',
    port: '',
    material: '',
    equipment: '',
    gcapacity: ''
  })
  const [interactiveD, setInteractiveD] = useState([])
  const [maxGroupCapacity, setMaxGroupCapacity] = useState(null)
  const acesstoken = localStorage.getItem('token')
  const getInteractiveDirectory = async () => {
    try {
      const res = await axios.post(
        'interactive-directories/filter',
        {
          country: filterMetaData?.country,
          state: filterMetaData?.countryState,
          port: filterMetaData?.port,
          materials: filterMetaData?.material,
          equipments: filterMetaData?.equipment,
          capacity: filterMetaData?.gcapacity
        },
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (
        res?.data &&
        typeof res?.data !== 'object' &&
        !Array.isArray(res?.data)
      ) {
        setInteractiveD(res?.data?.data)

        console.log('gcapacity', maxGroupCapacity)
        console.log('interactive data async', interactiveD)
      } else {
        setInteractiveD(Object.values(res?.data?.data))
      }
    } catch (error) {}
  }
  const getGroupCapacity = async () => {
    try {
      const response = await axios.get(
        'interactive-directories/get-group-capacity',
        {
          headers: { Authorization: `Bearer ${acesstoken}` }
        }
      )
      if (response?.data) {
        setMaxGroupCapacity(response?.data?.group_capacity?.max)
      }
    } catch (error) {
      setError(error.message)
    }
  }

  useEffect(() => {
    let mounted = true
    if (mounted && filterMetaData.country != '') {
      getInteractiveDirectory()
    }
    return () => (mounted = false)
  }, [
    filterMetaData.country,
    filterMetaData.countryState,
    filterMetaData.port,
    filterMetaData.equipment,
    filterMetaData.material,
    filterMetaData.gcapacity
  ])
  useEffect(() => {
    getGroupCapacity()
  }, [])

  console.log('filter meta data', filterMetaData)

  const mapContainerRef = useRef(null)
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      projection: 'globe',
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      center: [12.1950411, 55.205301],
      zoom: 1
    })

    interactiveD?.forEach((item, i) => {
      const MarkerStringHtml = renderToString(<MarkerHtmL item={item} />)
      const ref = createRef()
      ref.current = document.createElement('div')
      const root = ReactDOM.createRoot(ref.current)
      root.render(
        <Marker feature={item} map={map} key={i}>
          <p className='MarkerText'> {item?.company}</p>

          <CapacitySlider
            defaultcapacity={item?.group_capacity_mt}
            maxCapacity={maxGroupCapacity}
            widthHeight='w-full h-4'
          />
        </Marker>
      )
      new mapboxgl.Marker(ref.current)
        //  new mapboxgl.Marker()
        .setLngLat([Number(item?.lon), Number(item?.lat)])
        .setPopup(
          new mapboxgl.Popup({
            offset: 10
          }).setHTML(MarkerStringHtml)
        )
        .addTo(map)
    })
    map.flyTo({
      center: [
        interactiveD[0]?.lon || 12.1950411,
        interactiveD[0]?.lat || 55.205301
      ],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      zoom: interactiveD[0]?.lon ? 3.5 : 1
    })
    // map.addControl(new mapboxgl.NavigationControl(), "top-right");
    return () => map.remove()
  }, [interactiveD])

  return (
    <>
      <div className='interactive-mapcontainer' ref={mapContainerRef}>
        <div className='absolute top-0 w-full z-10'>
          <Dropdowns
            maxGroupCapacity={maxGroupCapacity}
            filterMetaData={filterMetaData}
            setFilterMetaData={setFilterMetaData}
          />
        </div>
      </div>
    </>
  )
}

export default InteractiveMap
