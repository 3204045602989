import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes, useNavigate, NavLink, Navigate } from 'react-router-dom'

import ErrorPage from './error-page'
import Login from './Pages/Login'
import Welcome from './Pages/Welcome'
import MyAccount from './Pages/MyAccount'
import Fixtures from './Pages/Fixtures'
import Company from './Pages/Company/Company'
import Fifa from './Pages/Fifa/Fifa'
import Tradeflows from './Pages/Tradeflows'
import InteractiveDirectory from './Pages/InteractiveDirectory'
import MarketCompass from './Pages/MarketCompass'
import Mclinkpage from './Pages/MarketCompass/Mclinkpage'
import ForgetPassword from './Components/forgetPassword/indx'
import IframeComponent from './Pages/IframeComponent'
import UserContext from './store/UserContext'
import Footer from './Components/footer/Footer'
import { checkNewDataAvailable, getAllData } from './Pages/Company/company-reducers'
import { MerchantPigIron } from './Pages/MerchantPigIron'
import { DRIHBI } from './Pages/DRIHBI'
import { FerrousScrap } from './Pages/FerrousScrap'
import { IronOre } from './Pages/IronOre'
function Router() {
  const {
    userState: { isAuth, token, email },
    setUserState
  } = useContext(UserContext)
  const [isLoaded, setIsLoaded] = useState(false)
  const navigate = useNavigate()

  const fetchCurrentDateTime = async () => {
    try {
      const response = await fetch('https://worldtimeapi.org/api/ip');
      const data = await response.json();
      const date = data.utc_datetime.substring(0, 10);
      // setCurrentDateTime(date)
      return date
    } catch (error) {
      console.error('Error fetching current date and time:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email');

      if (!token) {
        setIsLoaded(true);
        return;
      }

      const user = localStorage.getItem('navigateUser');
      const userData = JSON.parse(user);
      const date = await fetchCurrentDateTime();
      console.log(userData);

      if (userData) {
        if (userData.SERVICE_END != null || userData.SERVICE_START != null) {
          const currentDate = new Date(date);
          currentDate.setHours(5, 0, 0, 0);
          const serviceStartDate = userData.SERVICE_START ? new Date(userData.SERVICE_START) : null;
          const serviceEndDate = userData.SERVICE_END ? new Date(userData.SERVICE_END) : null;
          console.log(currentDate);

          if ((serviceStartDate === null || currentDate >= serviceStartDate) &&
            (serviceEndDate === null || currentDate <= serviceEndDate)) {
            console.log('Current date is between SERVICE_START and SERVICE_END (or one of them is null)');
          } else {
            setIsLoaded(true);
            localStorage.removeItem('navigateUser');
            localStorage.setItem('session', 'expired');
            return;
          }
        }
      } else {
        setIsLoaded(true);
        return;
      }
      const result = await checkNewDataAvailable(token);
      if (result) {
        getAllData(token);
        navigate("welcome")
      }
      setUserState({ isAuth: true, token: token, email: email, isUpdated: false });
      setIsLoaded(true);
    };

    fetchData(); // Immediately invoke the asynchronous function
  }, [setUserState]);


  const iFrameLink = [
    {
      link: '/iron-ore',
      ifram_link:
        'https://app.powerbi.com/view?r=eyJrIjoiMTU4NmI1MzktNTExZC00NWNjLWI4ZTItMzM5YTU3MGZmYThkIiwidCI6ImI4YzQ3NTk4LWY5Y2UtNDRiMi05NjIxLTQ0YmJhZjg1NWRlZiIsImMiOjEwfQ%3D%3D&pageName=ReportSection7193ed35dd4e3f452816'
    },
    {
      link: '/ferrous-scrap',
      ifram_link:
        'https://app.powerbi.com/view?r=eyJrIjoiYmU5NWUyZjctZWQ0NC00YjcwLTllMjEtNzA3M2I3YTQ4ZTYyIiwidCI6ImI4YzQ3NTk4LWY5Y2UtNDRiMi05NjIxLTQ0YmJhZjg1NWRlZiIsImMiOjEwfQ%3D%3D&pageName=ReportSection7193ed35dd4e3f452816'
    },
    {
      link: '/semis-steel',
      ifram_link: 'https://navigatecommodities.clicdata.com/b/HwJ8vNRqYELG'
    },
    {
      link: '/coal-coke',
      ifram_link:
        'https://app.powerbi.com/view?r=eyJrIjoiMGRkYThhNjQtNTNhZC00YzcwLWIyN2YtMTY1Y2E0MjE3MzU0IiwidCI6ImI4YzQ3NTk4LWY5Y2UtNDRiMi05NjIxLTQ0YmJhZjg1NWRlZiIsImMiOjEwfQ%3D%3D'
    }
  ]

  return (
    <>
      {isLoaded ? (
        <Routes>
          {isAuth ? (
            <Route path='/'>
              <Route path='welcome' element={<Welcome />} />
              <Route path='account' element={<MyAccount />} />
              {/* <Route path='fixtures' element={<Fixtures />} /> */}
              {/* <Route path='company' element={<Company />} />
              <Route path='fifa' element={<Fifa />} /> */}
              <Route path='merchantPigIron' element={<MerchantPigIron />} />
              <Route path='driHbi' element={<DRIHBI />} />
              <Route path='ferrousScrap' element={<FerrousScrap />} />
              <Route path='ironOre' element={<IronOre />} />
              {/* <Route path='trade' element={<Tradeflows />} /> */}
              {/* <Route path='inter-dir' element={<InteractiveDirectory />} /> */}
              <Route path='market-compass' element={<MarketCompass />} />
              <Route
                path='market-compass/:title/:postid'
                element={<Mclinkpage />}
              />
              <Route path='/' element={<Navigate to={'welcome'} />} />
              <Route
                path='*'
                element={
                  <div
                    style={{ height: '83vh' }}
                    className='flex justify-center items-center flex-col'
                  >
                    <h1>Page not found</h1>
                  </div>
                }
              />
            </Route>
          ) : (
            <>
              <Route path='/' element={<Login />} />
            </>
          )}
          <Route path='/' element={<Login />} />
          <Route path='/reset-password' element={<Login />} />
          <Route path='/forgetPassword/:token' element={<Login />} />
          <Route path='*' element={<Login />} />
          {iFrameLink?.map(item => {
            return (
              <Route
                key={item.link}
                path={item?.link}
                element={<IframeComponent link={item?.ifram_link} />}
              />
            )
          })}
        </Routes>
      ) : (
        <></>
      )}
    </>
  )
}

export default React.memo(Router)
