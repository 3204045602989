import React from "react";
import { Slider } from "antd";

const CapacitySlider = ({ widthHeight,defaultcapacity,groupCapacity,maxCapacity,setGroupCapacity,filterMetaData,setFilterMetaData}) => {
  const sliderStyle= {
    background:"linear-gradient(to right, rgba(199, 6, 6, 1), rgba(255, 170, 6, 1))",
  }
  return (
    <>
      <div className={`${widthHeight} h-4`}>
        {
          // from dropdowns header interactive directory
          widthHeight == "pt-1" ? (
            <>
              <p className="text-white font-medium text-xs"  style={{ lineHeight:'0.1rem'}}>
                Group Capacity (t)
              </p>
              <Slider
                defaultValue={groupCapacity}
                value={groupCapacity}
                min={0}
                max={maxCapacity}
                style={{
                  width: 230,
                }}
                className="ml-auto text-white"
                trackStyle={{ background: "white" }}
                railStyle={{ background: "rgba(78, 78, 78, 1)" }}
                onChange={(value)=>{
                  setGroupCapacity(value)
                  setFilterMetaData({...filterMetaData,
                    gcapacity:value,
                    })

                }}
                
              />
              <div className="flex justify-between" style={{ lineHeight:'0.1rem'}}>
                <span className="text-white">0</span>
                <span className="text-white">{maxCapacity || 75000}</span>
              </div>
            </>
          ) : (
            // from marker body interactive directory
            <>
              <p className="text-black capacitySlidertext">
                Group Capacity (t)
              </p>
              <Slider
                defaultValue={(defaultcapacity/maxCapacity)*100}
                className=" text-black"
                trackStyle={sliderStyle}
                handleStyle={{ display: "none" }}
                disabled={true}
                railStyle={{ background: "rgba(78, 78, 78, 1)" }}
              />
              <div className="flex justify-between capacityValues w-full">
                <span className="">0</span>
                <span className="">{maxCapacity || 100 }</span>
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

export default CapacitySlider;
