import React, { useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import './style.css'
import { Button, message, Radio, Modal, Table } from 'antd'
import { getAllDataFromDB } from '../../Pages/Company/company-reducers'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import { createTable, drawData, getColumns } from './mapUtils'
import { Legend } from './mapBox/legend'
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken =
  'pk.eyJ1IjoiY2FzcGlhbmdyb3VwIiwiYSI6ImNpcWt1cnp0MzAwMmRpM21iN2hqbXZ3b2MifQ.s3g4iqhjULtckf5ZdjE9yg'

const Map = React.memo(
  ({
    showDrawer,
    showChartsDrawer,
    companydata,
    getCompaniesdata,
    mapType,
    changeMapStyles,
    currentMap,
    setCurrentMap,
    projection,
    setProjections,
    Filters,
    loader,
    setLoader,
    done,
    setDone,
    comodity,
    legends,
    addLegend,
    removeLegend
  }) => {
    // const {data,isPending,error}=UseFetchAxios('/smelter-map-data?type=steel','get');
    const SOURCE_EARTHQUAKE = 'earthquakes'
    const [mapdata, setMap] = useState(null)
    const [style, setStyle] = useState(mapType)
    const [project, setProjection] = useState(projection)
    const [mapboaxLoaded, setMapBox] = useState(false)
    const [modalVisible, setModalVisible] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const [loadingLimit, setLoadingLimit] = useState(0)


    const company = useSelector(state => state) // Spiderify after zoom N, zoom otherwise
    var customMarker = null
    let currentLayer = []
    let markers = []
    var maindata = {}
    let currentZoomLevel = null
    let currentMapShow = null
    console.log(mapType)
    let clusterMarkers = []
    let spiderifiedCluster = {}
    let points = []
    let routesPoint = []
    let LayerPoint = []
    let SourcePoint = []
    var highlightedMarker = null
    var currentOpenSpiral = null
    let routeLayers = [];
    let currentSpiralId = null
    let zoomLevel = null
    const [messageApi, contextHolder] = message.useMessage()
    const mapContainerRef = React.useRef(null)
    const btngroupRef = React.useRef(null)
    const [tableId, setTableId] = useState(null)
    let tableCompanies = {}
    let currentPopup = null;
    let logoPopup = null;

    var typeColumns = getColumns(Filters, comodity);

    useEffect(() => {
      // window?.location?.href?.includes('/company') ? 'app-container' : ''
      const element = document?.getElementById('App-container')
      if (element) {
        element.classList.add('app-container')
      }
      return () => {
        if (element) {
          element.classList.remove('app-container')
        }
      }
    }, [])
    // useEffect(() => {
    //   if (loader) {
    //     setLegends([])
    //   }
    // }, [loader])

    useEffect(() => {
      ; () => {
        mapdata?.remove()
      }
    })

    useEffect(() => {
      setProjection(projection)
    }, [projection])

    // const drawaZoomLevel = (e, map, datapoint, id) => {
    //   // clearSpiderifiedCluster(map)
    //   console.log(e)
    //   let features = datapoint
    //   console.log(features)
    //   // console.log(e?.features[0])
    //   currentOpenSpiral = null
    //   if (customMarker != null) {
    //     customMarker?.remove()
    //   }
    //   markers?.map(item => {
    //     item?.remove()
    //   })
    //   // currentZoomLevel = map.getZoom() + 1
    //   markers = []
    //   console.log(currentLayer)
    //   let currentCompany = companydata?.find(item => item?.id == id)
    //   console.log(currentCompany)
    //   console.log(Filters?.type)
    //   const allFiltersCompany = companydata?.filter(item => {
    //     const query =
    //       (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //         ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat}`
    //         : `${currentCompany?.origin_long}-${currentCompany?.origin_lat}`

    //     const found =
    //       (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //         ? `${item?.destination_long}-${item?.destination_lat}`
    //         : `${item?.origin_long}-${item?.origin_lat}`
    //     const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
    //     const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
    //     if (
    //       query == found &&
    //       mills != mill2
    //     ) {
    //       return item
    //     }
    //   })
    //   let filterCompany = []
    //   let allCompanies = [currentCompany, ...allFiltersCompany]?.filter(

    //     item => {
    //       const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
    //       const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
    //       const found =
    //         (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //           ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat
    //           }-${mill2}`
    //           : `${currentCompany?.origin_long}-${currentCompany?.origin_lat
    //           }-${mill2}`
    //       if (!filterCompany.includes(found)) {
    //         filterCompany.push(found)
    //         return item
    //       }
    //     }
    //   )
    //   console.log(currentLayer)
    //   currentLayer?.map(item => {
    //     if (map.getLayer(item)) {
    //       map.removeLayer(item)
    //     }
    //     if (map.getSource(item)) {
    //       map.removeSource(item)
    //     }
    //   })
    //   console.log(allCompanies)
    //   currentLayer = []

    //   if (allCompanies?.length <= 1) {
    //     let coordinates =
    //       (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //         ? [
    //           currentCompany?.destination_long,
    //           currentCompany?.destination_lat
    //         ]
    //         : [currentCompany?.origin_long, currentCompany?.origin_lat]
    //     customMarker = document.createElement('div')
    //     customMarker.style.width = '20px'
    //     customMarker.style.height = '20px'
    //     customMarker.style.borderRadius = '50%'
    //     customMarker.style.backgroundColor = 'red'
    //     customMarker.style.border = '3px solid #ffff'
    //     console.log(coordinates)
    //     new mapboxgl.Marker(customMarker).setLngLat(coordinates).addTo(map)
    //     showDestinationPop(
    //       map,
    //       e?.features[0]?.properties?.internalNodeId,
    //       e,
    //       currentCompany
    //     )
    //     return
    //   }

    //   currentOpenSpiral = e
    //   let value = allCompanies?.length > 10 ? 6 : 5
    //   let radius = 0.9 / Math.pow(2, map.getZoom() - value)
    //   console.log(radius)

    //   var spiderPositions = calculateSpiderPositions(
    //     features[0].geometry.coordinates,
    //     allCompanies,
    //     radius
    //   )

    //   if (map.getLayer('line0')) {
    //     map.removeLayer('line0')
    //   }
    //   if (map.getSource('line0')) {
    //     map.removeSource('line0')
    //   }
    //   console.log(spiderPositions)
    //   spiderPositions.forEach(function (location, index) {
    //     currentLayer.push(`line${index}`)
    //     var customMarker = document.createElement('div')
    //     customMarker.className = 'custom-marker'
    //     customMarker.textContent = ''
    //     const popup = new mapboxgl.Popup({
    //       closeButton: false, // Show or hide the close button
    //       closeOnClick: true,
    //       class: 'SinglePop' // Keep the popup open when the user clicks outside it
    //     })
    //     map.setLayerZoomRange(`line${index}`, 10, Infinity)
    //     let url =
    //       (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //         ? `https://${location?.mill_logo}`
    //         : `https://${location?.company_logo}`
    //     popup.setHTML(
    //       `<div class="main-pop-container"><div class="popup-data"><img src=${url?.includes('https') || url?.includes('http')
    //         ? url
    //         : `https://${url}`
    //       }></img><div><div class="arrow"></div><div>`
    //     )
    //     let logoPopup = popup;

    //     let marker = new mapboxgl.Marker(customMarker)
    //       .setLngLat(location?.location)
    //       .setPopup(popup)
    //       .addTo(map)
    //     markers?.push(marker)
    //     marker.getElement().addEventListener('click', function () {

    //       highlightMarker(marker)
    //       let SameCompany = companydata?.filter(item => {
    //         const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? location?.mill?.trim() : location?.company?.trim()
    //         const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
    //         const query =
    //           (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //             ? `${location?.destination_long}-${location?.destination_lat
    //             }-${mills}`
    //             : `${location?.origin_long}-${location?.origin_lat
    //             }-${mills}`
    //         const found =
    //           (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //             ? `${item?.destination_long}-${item?.destination_lat
    //             }-${mill2}`
    //             : `${item?.origin_long}-${item?.origin_lat
    //             }-${mill2}`
    //         if (query == found) {
    //           return item
    //         }
    //       })
    //       console.log(SameCompany)
    //       // showDestinationPop(map, currentCompany?.id, location?.location)
    //       console.log(map?.getStyle())
    //       const mapStyle = map?.getStyle()
    //       const layer = mapStyle?.layers
    //       const source = mapStyle?.sources
    //       let layerIndex = layer?.findIndex(item => item?.id == "unclustered-point")
    //       layer?.map((item, index) => {
    //         if (index > layerIndex) {
    //           if (item?.id?.includes('route') || item?.id?.includes('line-dashed')) {
    //             map?.removeLayer(item?.id)
    //           }
    //         }
    //       })
    //       Object.keys(source)?.map((item, index) => {
    //         if (index > 1 && item?.includes('route')) {
    //           map?.removeSource(item)
    //         }
    //       })
    //       console.log(map?.getStyle())
    //       LayerPoint?.map(item => {
    //         if (map.getLayer(item)) {
    //           map.removeLayer(item)
    //         }
    //       })
    //       SourcePoint?.map(item => {
    //         if (map.getSource(item)) {
    //           map.removeSource(item)
    //         }
    //       })
    //       SameCompany = [...SameCompany, location]
    //       LayerPoint = []
    //       SourcePoint = []
    //       console.log("this is first")
    //       // showPath2(map,allCompanies)
    //       setLoadingLimit(SameCompany.length)
    //       tableCompanies = {}
    //       SameCompany?.map((item, index) => {
    //         LayerPoint.push(`route${index}`)
    //         LayerPoint.push(`line-dashed${index}`)
    //         SourcePoint.push(`route${index}`)
    //         SourcePoint.push(`line-dashed${index}`)
    //         showPath(map, item?.id, index, SameCompany)
    //       })
    //       // alert(location?.id)
    //     })
    //     marker.getElement().addEventListener('mouseenter', function () {
    //       map.getCanvas().style.cursor = 'pointer'
    //       if (currentPopup) {
    //         currentPopup.remove();
    //       }
    //       if (logoPopup) {
    //         logoPopup.remove();
    //       }

    //       // const loc =[parseFloat(currentCompany.destination_long),parseFloat(currentCompany.destination_lat)]
    //       // console.log(loc)
    //       const locPixels = (map.project(location?.location))

    //       console.log(e)
    //       // Create a new popup with the company name
    //       const popup = new mapboxgl.Popup({
    //         closeButton: false, // Show or hide the close button
    //         closeOnClick: true,
    //         className: 'SinglePop', // Apply a custom CSS class
    //       })
    //         .setLngLat(location?.location)
    //         .setHTML(
    //           `<div class="main-pop-container"><div><div class="popup-data1"><h1>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? location.mill : location.company}</h1><h3>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? location.actual_dest : location.port_name}, ${Filters?.type == 'arrivals' ? location.dest_country : Filters?.type == 'barges' ? location.actual_dest_country : location.country}</h3></div><div><div class="arrow"></div><div>`
    //         )
    //         .addTo(map);

    //       const popupContainer = document.querySelector('.popup-data1');
    //       const h1Width = popupContainer.querySelector('h1').scrollWidth;
    //       const h3Width = popupContainer.querySelector('h3').scrollWidth;
    //       const totalWidth = Math.max(h1Width, h3Width) + 20; // Add padding
    //       console.log(totalWidth)

    //       // Set the width of the container
    //       popupContainer.style.width = totalWidth + 'px';

    //       const popupDiv = document.querySelector('.SinglePop');
    //       popupDiv.style.transform = `translate(-50%, -100%) translate(${locPixels.x}px, ${locPixels.y}px)`;

    //       currentPopup = popup;
    //     })
    //     marker.getElement().addEventListener('mouseleave', function () {
    //       map.getCanvas().style.cursor = ''
    //       if (currentPopup) {
    //         currentPopup.remove();
    //         currentPopup = null; // Clear the reference to the closed popup
    //       }
    //     })

    //     var coordinates = [features[0].geometry.coordinates, location?.location]

    //     if (map.getLayer(`line${index}`)) {
    //       map.removeLayer(`line${index}`)
    //     }
    //     if (map.getSource(`line${index}`)) {
    //       map.removeSource(`line${index}`)
    //     }
    //     var line = {
    //       type: 'Feature',
    //       geometry: {
    //         type: 'LineString',
    //         coordinates: coordinates
    //       }
    //     }
    //     console.log(`line${index}`)
    //     map.addSource(`line${index}`, {
    //       type: 'geojson',
    //       data: line
    //     })
    //     map.addLayer({
    //       id: `line${index}`,
    //       type: 'line',
    //       source: `line${index}`,
    //       layout: {
    //         'line-join': 'round',
    //         'line-cap': 'round'
    //         // visibility: 'visible',
    //         // 'z-index': 100
    //       },
    //       paint: {
    //         'line-color': '#b283ce',
    //         'line-width': 3
    //       }
    //     })
    //   })
    // }

    // function calculateSpiderPositions(centerLngLat, numPoints, radius = 0.01) {
    //   var positions = numPoints?.map((item, index) => {
    //     var angle = (360 / numPoints?.length) * index
    //     var radian = (angle * Math.PI) / 180
    //     var x = centerLngLat[0] + radius * Math.cos(radian)
    //     var y = centerLngLat[1] + radius * Math.sin(radian)
    //     return { ...item, location: [x, y] }
    //   })

    //   return positions
    // }

    // const drawData = map => {
    //   const mapElement = document?.getElementsByClassName(
    //     'mapboxgl-canvas-container'
    //   )
    //   console.log(mapElement)
    //   if (mapElement?.length > 1) {
    //     for (var i = 0; i < mapElement?.length - 1; i++) {
    //       mapElement?.[i]?.remove()
    //     }
    //   }
    //   const toggleButton = document.querySelector('.toggle-btn')
    //   const expandableRow = document.querySelector('.expandable-row')

    //   toggleButton?.addEventListener('click', function () {
    //     expandableRow.classList.toggle('show-additional')
    //   })

    //   let data_collection = {}
    //   console.log(companydata)
    //   companydata?.forEach?.((data, i) => {
    //     let key = `${data?.destination_lat}-${data?.destination_long}`
    //     if (data_collection.hasOwnProperty(key)) {
    //       data_collection[key] = [...data_collection[key], data]
    //     } else {
    //       data_collection[key] = [data]
    //     }
    //   })

    //   if (companydata?.length > 0) {
    //     (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //       ? map.setCenter([
    //         companydata?.[0]?.destination_long,
    //         companydata?.[0]?.destination_lat
    //       ])
    //       : map.setCenter([
    //         companydata?.[0]?.origin_long,
    //         companydata?.[0]?.origin_lat
    //       ])
    //   }

    //   let items = []
    //   console.log(companydata)
    //   let newFeature =
    //     (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //       ? companydata?.map(item => {
    //         if (
    //           items?.includes(
    //             `${item?.destination_long}-${item?.destination_lat}`
    //           )
    //         ) {
    //           return
    //         }
    //         items?.push(`${item?.destination_long}-${item?.destination_lat}`)
    //         return {
    //           type: 'Feature',
    //           properties: {
    //             id: `${item?.destination_long}-${item?.destination_lat}`,
    //             mag: 2.3,
    //             time: 1507425650893,
    //             felt: null,
    //             tsunami: 0,

    //             internalNodeId: item?.id
    //           },
    //           geometry: {
    //             type: 'Point',
    //             coordinates: [item?.destination_long, item?.destination_lat]
    //           }
    //         }
    //       })
    //       : companydata?.map(item => {
    //         if (items?.includes(`${item?.origin_long}-${item?.origin_lat}`)) {
    //           return
    //         }
    //         items?.push(`${item?.origin_long}-${item?.origin_lat}`)
    //         return {
    //           type: 'Feature',
    //           properties: {
    //             id: `${item?.origin_long}-${item?.origin_lat}`,
    //             mag: 2.3,
    //             time: 1507425650893,
    //             felt: null,
    //             tsunami: 0,

    //             internalNodeId: item?.id
    //           },
    //           geometry: {
    //             type: 'Point',
    //             coordinates: [item?.origin_long, item?.origin_lat]
    //           }
    //         }
    //       })
    //   console.log(newFeature)
    //   newFeature = newFeature?.filter(item => item != null)
    //   console.log(newFeature)

    //   //console.log(map)
    //   if (map.getLayer('route')) {
    //     // alert('I am layer data')
    //     map.removeLayer('route')
    //   }

    //   if (map.getSource('route')) {
    //     map.removeSource('route')
    //   }
    //   if (map.getLayer('clusters')) {
    //     // alert('I am layer data')
    //     map.removeLayer('clusters')
    //   }
    //   if (map.getLayer('cluster-count')) {
    //     // alert('I am layer data')
    //     map.removeLayer('cluster-count')
    //   }

    //   if (map.getLayer('unclustered-point')) {
    //     map.removeLayer('unclustered-point')
    //   }

    //   if (map.getSource(SOURCE_EARTHQUAKE)) {
    //     map.removeSource(SOURCE_EARTHQUAKE)
    //   }
    //   // if (mapboaxLoaded == false) {
    //   map.on('load', () => {
    //     //console.log(map)
    //     setMap(map)
    //     setMapBox(true)

    //     map.on('zoom', () => {
    //       const currentZoom = map.getZoom()

    //       console.log(currentZoom)
    //       console.log(currentZoomLevel)

    //       if (currentZoom < 1.27) {
    //         map.setZoom(1.27)
    //       }
    //       else {
    //         if (zoomLevel < currentZoom) {
    //           if (currentZoomLevel < currentZoom) {
    //             if (currentOpenSpiral != null) {
    //               drawaZoomLevel(
    //                 currentOpenSpiral,
    //                 map,
    //                 currentMapShow,
    //                 currentSpiralId
    //               )
    //               currentZoomLevel = map.getZoom() + 1
    //             }
    //           }
    //         } else {
    //           if (currentZoomLevel > currentZoom) {
    //             if (currentOpenSpiral != null) {
    //               drawaZoomLevel(
    //                 currentOpenSpiral,
    //                 map,
    //                 currentMapShow,
    //                 currentSpiralId
    //               )
    //               currentZoomLevel = map.getZoom() - 1
    //             }
    //           }
    //         }
    //       }
    //     })

    //     map
    //       .on('click', 'clusters', e => {
    //         localStorage.removeItem('id')
    //         localStorage.removeItem('currentElement')

    //         let features = map.queryRenderedFeatures(e.point, {
    //           layers: ['clusters']
    //         })

    //         let clusterId = features[0].properties.cluster_id
    //         map
    //           .getSource(SOURCE_EARTHQUAKE)
    //           .getClusterExpansionZoom(clusterId, (err, zoom) => {
    //             //console.log(clickedFeature.properties.id)
    //             if (err) return
    //             map.easeTo({
    //               center: features[0].geometry.coordinates,
    //               zoom: zoom
    //             })
    //           })
    //       })
    //       .on('click', e => {
    //         // const mapStyle = map?.getStyle()
    //         // const source = mapStyle?.sources
    //         // const includesRoute = Object.keys(source).some(key => key.includes('route'));

    //         // console.log(source)
    //         // console.log(includesRoute)
    //         // if(currentOpenSpiral!=null && !includesRoute){
    //         //   const dist=haversineDistance(e.lngLat,currentOpenSpiral.lngLat)
    //         //   if(dist>250)
    //         //   {
    //         //   currentSpiralId=null
    //         //   if (customMarker != null) {
    //         //     customMarker?.remove()
    //         //   }
    //         //   markers?.map(item => {
    //         //     item?.remove()
    //         //   })
    //         //   // currentZoomLevel = map.getZoom() + 1
    //         //   markers = []
    //         //   currentLayer?.map(item => {
    //         //     if (map.getLayer(item)) {
    //         //       map.removeLayer(item)
    //         //     }
    //         //     if (map.getSource(item)) {
    //         //       map.removeSource(item)
    //         //     }
    //         //   })
    //         //   currentLayer= []
    //         //   if (map.getLayer('line0')) {
    //         //     map.removeLayer('line0')
    //         //   }
    //         //   if (map.getSource('line0')) {
    //         //     map.removeSource('line0')
    //         //   }
    //         //   currentOpenSpiral=null
    //         // }
    //         // }
    //       })
    //       .on('zoomstart', () => { })

    //     // From https://docs.mapbox.com/mapbox-gl-js/example/cluster/

    //     // Start of Mapbox cluster example

    //     map.on('click', 'unclustered-point', e => {
    //       // currentZoomLevel = map?.getZoom() + 1
    //       let features = map.queryRenderedFeatures(e?.point, {
    //         layers: ['unclustered-point']
    //       })
    //       zoomLevel = map?.getZoom()
    //       let id = e?.features[0]?.properties?.internalNodeId
    //       drawaZoomLevel(e, map, features, id)
    //       currentMapShow = features

    //       currentSpiralId = id
    //     })



    //     map.on(
    //       'mouseenter',
    //       'unclustered-point',
    //       e => {
    //         map.getCanvas().style.cursor = 'pointer';
    //         // console.log(e)
    //         // console.log(currentOpenSpiral)
    //         let dist = 260
    //         if (currentOpenSpiral !== null)
    //           dist = haversineDistance(e.lngLat, currentOpenSpiral.lngLat)
    //         console.log(dist)
    //         if (dist > 250) {
    //           const id = e.features[0].properties.internalNodeId;
    //           const currentCompany = companydata.find(item => item.id == id);

    //           const allFiltersCompany = companydata?.filter(item => {
    //             const query =
    //               (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //                 ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat}`
    //                 : `${currentCompany?.origin_long}-${currentCompany?.origin_lat}`

    //             const found =
    //               (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //                 ? `${item?.destination_long}-${item?.destination_lat}`
    //                 : `${item?.origin_long}-${item?.origin_lat}`
    //             const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
    //             const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
    //             if (
    //               query == found &&
    //               mills != mill2
    //             ) {
    //               return item
    //             }
    //           })
    //           let filterCompany = []
    //           let allCompanies = [currentCompany, ...allFiltersCompany]?.filter(

    //             item => {
    //               const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
    //               const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
    //               const found =
    //                 (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //                   ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat
    //                   }-${mill2}`
    //                   : `${currentCompany?.origin_long}-${currentCompany?.origin_lat
    //                   }-${mill2}`
    //               if (!filterCompany.includes(found)) {
    //                 filterCompany.push(found)
    //                 return item
    //               }
    //             }
    //           )

    //           console.log(allCompanies)

    //           // Close the current popup if one is open
    //           if (currentPopup) {
    //             currentPopup.remove();
    //           }
    //           console.log(logoPopup)
    //           if (logoPopup) {
    //             logoPopup.remove();
    //           }
    //           console.log(e.features[0].geometry.coordinates)

    //           const locPixels = e.point;//(map.project(e.features[0].geometry.coordinates))

    //           // Create a new popup with the company name
    //           const popup = new mapboxgl.Popup({
    //             closeButton: false, // Show or hide the close button
    //             closeOnClick: true,
    //             className: 'SinglePop', // Apply a custom CSS class
    //           })
    //             .setLngLat(e.features[0].geometry.coordinates)
    //             .setHTML(
    //               `<div class="main-pop-container"><div><div class="popup-data1"><h1>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany.mill : currentCompany.company}</h1><h3>${(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany.actual_dest : currentCompany.port_name}, ${Filters?.type == 'arrivals' ? currentCompany.dest_country : Filters?.type == 'barges' ? currentCompany.actual_dest_country : currentCompany.country}</h3></div><div><div class="arrow"></div><div>`
    //             )
    //             .addTo(map);

    //           const popupContainer = document.querySelector('.popup-data1');
    //           const h1element = popupContainer.querySelector('h1')
    //           if (allCompanies.length > 1) {
    //             h1element.style.display = 'none';
    //           }
    //           const h1Width = popupContainer.querySelector('h1').scrollWidth;
    //           const h3Width = popupContainer.querySelector('h3').scrollWidth;
    //           const totalWidth = Math.max(h1Width, h3Width) + 20; // Add padding
    //           console.log(totalWidth)
    //           console.log(popup)

    //           // Set the width of the container
    //           popupContainer.style.width = totalWidth + 'px';

    //           const popupDiv = document.querySelector('.SinglePop');
    //           popupDiv.style.transform = `translate(-50%, -100%) translate(${locPixels.x}px, ${locPixels.y}px)`;

    //           currentPopup = popup;
    //         }
    //       }
    //     );

    //     map.on(
    //       'mouseleave',
    //       'unclustered-point',
    //       () => {
    //         map.getCanvas().style.cursor = '';

    //         // Remove the popup on mouse leave
    //         if (currentPopup) {
    //           currentPopup.remove();
    //           currentPopup = null; // Clear the reference to the closed popup
    //         }
    //       }
    //     );



    // maindata = {
    //   type: 'FeatureCollection',
    //   crs: {
    //     type: 'name',
    //     properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }
    //   },
    //   features: newFeature
    // }
    // map.addSource(SOURCE_EARTHQUAKE, {
    //   type: 'geojson',
    //   data: maindata,
    //   cluster: true,
    //   clusterMaxZoom: 30, // Max zoom to cluster points on
    //   clusterRadius: 30 // Radius of each cluster when clustering points (defaults to 50)
    // })

    // map.addLayer({
    //   id: 'clusters',
    //   type: 'circle',
    //   interactive: true,
    //   source: SOURCE_EARTHQUAKE,
    //   filter: ['has', 'point_count'],
    //   paint: {
    //     'circle-color': [
    //       'step',
    //       ['get', 'point_count'],
    //       '#362554',
    //       100,
    //       '#362554',
    //       750,
    //       '#362554'
    //     ],
    //     'circle-radius': [
    //       'step',
    //       ['get', 'point_count'],
    //       20,
    //       100,
    //       30,
    //       750,
    //       40
    //     ],
    //     'circle-stroke-color': '#fff',
    //     'circle-stroke-width': 2
    //   }
    // })

    // map.addLayer({
    //   id: 'cluster-count',
    //   type: 'symbol',
    //   source: SOURCE_EARTHQUAKE,
    //   filter: ['has', 'point_count'],
    //   layout: {
    //     'text-field': '{point_count_abbreviated}',
    //     'text-size': 20,
    //     'text-font': ['Open Sans Bold']
    //   },
    //   paint: {
    //     'text-color': 'white' // Change the color of the cluster count text
    //   }
    // })

    // map.addLayer({
    //   id: 'unclustered-point',
    //   type: 'circle',
    //   interactive: true,
    //   source: SOURCE_EARTHQUAKE,
    //   filter: ['!', ['has', 'point_count']],
    //   paint: {
    //     'circle-color': '#675689',
    //     'circle-radius': 10,
    //     'circle-stroke-width': 1,
    //     'circle-stroke-color': '#675689'
    //   }
    // })

    // map.addControl(
    //   new mapboxgl.ScaleControl({
    //     maxWidth: 80,
    //     unit: 'metric'
    //   })
    // )

    //     map.on(
    //       'mouseenter',
    //       'clusters',
    //       () => (map.getCanvas().style.cursor = 'pointer')
    //     )

    //     map.on(
    //       'mouseleave',
    //       'clusters',
    //       () => (map.getCanvas().style.cursor = '')
    //     )
    //     // End of Mapbox cluster example
    //     let currenId = localStorage.getItem('id')
    //     let currentElement = localStorage.getItem('currentElement')
    //     if (currenId != undefined) {
    //       // showDestinationPopAuto(map, currenId, currentElement)
    //     }
    //   })
    // }
    useEffect(() => {
      if (mapdata != null) {
        mapdata?.setStyle(mapType)
        drawData(mapdata, Filters, companydata, showPath, comodity)
      }
    }, [companydata, project])

    useEffect(() => {
      if (mapdata != null) {
        mapdata?.setStyle(mapType)

      }
    }, [mapType])

    useEffect(() => {
      if (mapdata == null || done == false) {
        let map = new mapboxgl.Map({
          container: mapContainerRef.current,
          projection: 'mercator',
          style: style,
          center: Filters?.type == 'barges' ? [10.452764000000002, 51.1657065] : [-8.841969, 38.49458],
          zoom: Filters?.type == 'barges' ? 5 : 1.27
        })
        // console.log(companydata)
        // console.log(done)
        // console.log(loader)
        // console.log(mapdata)

        if (mapdata == null) {
          drawData(map, Filters, companydata, showPath, comodity)
          setMap(map)
        }
        else {
          drawData(mapdata, Filters, companydata, showPath, comodity)
        }
        if (companydata.length > 0) {
          setDone(true)
          setLoader(false)
        }
      }
    }, [companydata])

    // const showDestinationPop = async (map, id, e, currentSelected = {}) => {
    //   console.log(currentSelected)
    //   if (Object.keys(currentSelected)?.length == 0) {
    //     return
    //   }
    //   localStorage.setItem('id', id)
    //   let currentCompany = {}
    //   const currentCompany1 = companydata?.find(item => item?.id == id)
    //   if (currentCompany1 != undefined) {
    //     currentCompany = currentCompany1
    //   }
    //   console.log(currentCompany)

    //   const found =
    //     (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //       ? `${currentCompany?.destination_long}-${currentCompany?.destination_lat}`
    //       : `${currentCompany?.origin_long}-${currentCompany?.origin_lat}`
    //   let allCompanies = companydata?.filter(item => {
    //     let query =
    //       (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //         ? `${item?.destination_long}-${item?.destination_lat}`
    //         : `${item?.origin_long}-${item?.origin_lat}`
    //     const mills = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? currentCompany?.mill?.trim() : currentCompany?.company?.trim()
    //     const mill2 = (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? item?.mill?.trim() : item?.company?.trim()
    //     if (
    //       query == found &&
    //       mills == mill2
    //     ) {
    //       return item
    //     }
    //   })
    //   const element = document?.querySelectorAll(
    //     '.mapboxgl-popup-anchor-bottom'
    //   )

    //   console.log(element)
    //   if (element?.length > 0) {
    //     element?.forEach(item => {
    //       item?.remove()
    //     })
    //   }
    //   const popup = new mapboxgl.Popup({
    //     closeButton: false, // Show or hide the close button
    //     closeOnClick: true // Keep the popup open when the user clicks outside it
    //   })
    //   let url =
    //     (Filters?.type == 'arrivals' || Filters?.type == 'barges')
    //       ? `https://${currentCompany?.mill_logo}`
    //       : `https://${currentCompany?.company_logo}`
    //   popup.setHTML(
    //     `<div class="main-pop-container"><div class="popup-data"><img src=${url}></img><div><div class="arrow"></div><div>`
    //   )
    //   const feature = e?.features[0]
    //   const coordinates = feature.geometry.coordinates.slice()
    //   console.log(coordinates)
    //   localStorage.setItem('currentElement', coordinates)
    //   popup.setLngLat(coordinates).addTo(map)
    //   logoPopup = popup;

    //   console.log(map?.getStyle())
    //   const mapStyle = map?.getStyle()
    //   const layer = mapStyle?.layers
    //   const source = mapStyle?.sources
    //   let layerIndex = layer?.findIndex(item => item?.id == "unclustered-point")
    //   layer?.map((item, index) => {
    //     if (index > layerIndex) {
    //       if (item?.id?.includes('route') || item?.id?.includes('line-dashed')) {
    //         map?.removeLayer(item?.id)
    //       }
    //     }
    //   })
    //   Object.keys(source)?.map((item, index) => {
    //     if (index > 1 && item?.includes('route')) {
    //       map?.removeSource(item)
    //     }
    //   })
    //   console.log(map?.getStyle())
    //   LayerPoint?.map(item => {
    //     if (map.getLayer(item)) {
    //       map.removeLayer(item)
    //     }
    //   })
    //   SourcePoint?.map(item => {
    //     if (map.getSource(item)) {
    //       map.removeSource(item)
    //     }
    //   })

    //   LayerPoint = []
    //   SourcePoint = []
    //   console.log(allCompanies)
    //   allCompanies = [...allCompanies, currentCompany]
    //   console.log("this is second")
    //   // showPath2(map,allCompanies)
    //   console.log(allCompanies.length)
    //   setLoadingLimit(allCompanies.length)
    //   tableCompanies = {}
    //   await getAllDataFromDB();
    //   allCompanies?.map((item, index) => {
    //     LayerPoint.push(`route${index}`)
    //     LayerPoint.push(`line-dashed${index}`)
    //     SourcePoint.push(`route${index}`)
    //     SourcePoint.push(`line-dashed${index}`)
    //     showPath(map, item?.id, index, allCompanies)
    //   })
    //   console.log("this is third")
    // }

    function getRandomNumber(min, max) {
      // Generate a random decimal number between 0 (inclusive) and 1 (exclusive)
      const randomDecimal = Math.random();

      // Scale the random decimal to fit within the specified range
      const randomNumber = min + (randomDecimal * (max - min));

      // Return the random number
      return randomNumber;
    }
    // const showPath2 = async (map, companiesdata) => {
    //   let alldata = (await getAllDataFromDB()) || []
    //   let number = getRandomNumber(1, 6)
    //   console.log(number)
    //   // let seaRoutes = alldata?.[0]
    //   // seaRoutes = null

    //   // number = null
    //   // return 

    //   //   const coloads = JSON.parse(localStorage.getItem('coloads'))
    //   let currentCompanyRoute = alldata?.[0]?.[6]
    //   console.log(currentCompanyRoute)
    //   let data = currentCompanyRoute?.coords?.map(item => {
    //     return [item?.longitude, item?.latitude]
    //   })
    //   const geojson = {
    //     type: 'FeatureCollection',
    //     features: {
    //       type: 'Feature',
    //       properties: { id: number },
    //       geometry: {
    //         // coordinates: Filters?.type != 'arrivals' ? data.reverse() : data,
    //         coordinates: data,
    //         type: 'LineString'
    //       }
    //     }
    //   }

    //   data = null
    //   currentCompanyRoute = null



    //   if (map?.getSource("route") && map?.getLayer("route")) {
    //     console.log(map?.getStyle())
    //     map?.removeLayer("route")
    //     map?.removeSource("route")

    //   }

    //   map.addSource(`route`, {
    //     type: 'geojson',
    //     lineMetrics: true,
    //     data: geojson
    //   })
    //   map.addLayer({
    //     id: `route`,
    //     type: 'line',
    //     source: `route`,

    //     // paint: {
    //     //   'line-width': 20,
    //     //   'line-gradient': [
    //     //     'interpolate',
    //     //     ['linear'],
    //     //     ['line-progress'],
    //     //     0,
    //     //     'blue',
    //     //     0.1,
    //     //     'royalblue',
    //     //     0.3,
    //     //     'cyan',
    //     //     0.5,
    //     //     'lime',
    //     //     0.7,
    //     //     'yellow',
    //     //     1,
    //     //     'red'
    //     //   ],
    //     //   'line-width': 6,
    //     //   'line-opacity': 0.4
    //     // }
    //   })
    //   alldata = null

    // }

    function haversineDistance(coord1, coord2) {
      const earthRadius = 6371; // Earth's radius in kilometers

      const toRadians = (degrees) => (degrees * Math.PI) / 180;
      const dLat = toRadians(coord2.lat - coord1.lat);
      const dLng = toRadians(coord2.lng - coord1.lng);

      // console.log(dLng,dLat)

      const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRadians(coord1.lat)) *
        Math.cos(toRadians(coord2.lat)) *
        Math.sin(dLng / 2) ** 2;

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return earthRadius * c;
    }

    const showPath = async (companydata, Filters, map, id, index, companiesdata) => {
      const company = companydata?.find(item => item?.id == id)
      const currentCompanyid = company?.unique_route_id
      let alldata = (await getAllDataFromDB()) || []
      let indx = 0
      let length = 0
      alldata?.map((item, ind) => {
        if (item?.length > length) {
          indx = ind
          length = item?.length
        }
      })
      let currentCompanyRoute = alldata?.[indx]?.find(item => {
        return item?.id == currentCompanyid
      })
      indx = null
      length = null
      let data = currentCompanyRoute?.coords?.map(item => {
        return [item?.longitude, item?.latitude]
      })
      console.log(data)
      const tempcompanyData = {
        company: company,
        coordinates: data,
      };
      tableCompanies[index] = tempcompanyData
      alldata = null
      currentCompanyRoute = null
      console.log("End of Data")
      const geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              // coordinates: Filters?.type != 'arrivals' ? data.reverse() : data,
              coordinates: data,
              type: 'LineString'
            }
          }
        ]
      }
      data = null
      map.addSource(`route${index}`, {
        type: 'geojson',
        lineMetrics: true,
        data: geojson
      })
      map.addLayer({
        id: `route${index}`,
        type: 'line',
        source: `route${index}`,

        paint: {
          'line-width': 20,
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            'blue',
            0.1,
            'royalblue',
            0.3,
            'cyan',
            0.5,
            'lime',
            0.7,
            'yellow',
            1,
            'red'
          ],
          'line-width': 6,
          'line-opacity': 0.4
        }
      })

      // let endColor = Filters?.type == 'arrivals' ? 'red' : 'blue'
      // let startColor = Filters?.type == 'arrivals' ? 'blue' : 'red'

      map.addLayer({
        type: 'line',
        source: `route${index}`,
        id: `line-dashed${index}`,
        paint: {
          // 'line-color': 'yellow',
          'line-width': 20,
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0,
            'blue',
            0.1,
            'royalblue',
            0.3,
            'cyan',
            0.5,
            'lime',
            0.7,
            'yellow',
            1,
            'red'
          ],
          'line-width': 6,
          'line-dasharray': [0, 4, 3]
        }
      })


      const dashArraySequence = [
        [0, 4, 3],
        [0.5, 4, 2.5],
        [1, 4, 2],
        [1.5, 4, 1.5],
        [2, 4, 1],
        [2.5, 4, 0.5],
        [3, 4, 0],
        [0, 0.5, 3, 3.5],
        [0, 1, 3, 3],
        [0, 1.5, 3, 2.5],
        [0, 2, 3, 2],
        [0, 2.5, 3, 1.5],
        [0, 3, 3, 1],
        [0, 3.5, 3, 0.5]
      ]

      // let step = 0
      // function animateDashArray (timestamp) {
      //   const newStep = parseInt((timestamp / 125) % dashArraySequence.length)

      //   if (newStep !== step) {
      //     map.setPaintProperty(
      //       `line-dashed${index}`,
      //       'line-dasharray',
      //       dashArraySequence[step]
      //     )
      //     step = newStep
      //   }
      //   requestAnimationFrame(animateDashArray)
      // }
      // if (map.getLayer(`line-dashed${index}`)) {
      //   animateDashArray(0)
      // }
      var popupshow = null

      map.on('click', `route${index}`, e => {
        const element = document?.querySelectorAll(
          '.mapboxgl-popup-anchor-bottom'
        )
        // let SameCompany = companydata?.filter(item => {
        //   const query =
        //     Filters?.type == 'arrivals'
        //       ? `${company?.destination_long}-${company?.destination_lat}`
        //       : `${company?.origin_long}-${company?.origin_lat}`
        //   const found =
        //     Filters?.type == 'arrivals'
        //       ? `${item?.destination_long}-${item?.destination_lat}`
        //       : `${item?.origin_long}-${item?.origin_lat}`
        //       const mills = Filters?.type == 'arrivals' ? company?.mill?.trim() :company?.company?.trim()
        //     const mill2 = Filters?.type == 'arrivals' ? item?.mill?.trim() :item?.company?.trim() 
        //   if (
        //     query == found &&
        //     mills == mill2
        //   ) {
        //     return item
        //   }
        // })

        console.log(element)
        if (element?.length > 0) {
          element?.forEach(item => {
            item?.remove()
          })
        }

        if (popupshow) {
          popupshow.remove() // Close the current popup
          popupshow = null
          return // Return to prevent opening another popup
        }
        // alert('I am Awas Niaz')
        const coordinates = e.lngLat
        let datalist = ''
        typeColumns?.map(item => {
          if (company[item?.dataIndex] == null) {
            return
          }
          datalist =
            datalist +
            `<div class="datalist"><p>${item?.title}:</p><b><p>${company[item?.dataIndex]
            }</p></b></div>`
        })
        // popupshow = new mapboxgl.Popup({
        //   closeButton: SameCompany?.length > 0 ? false : true,
        //   closeOnClick: true
        // })

        // console.log(SameCompany)
        // if(Filters?.type == 'arrivals')
        // {
        //   SameCompany=SameCompany.map((item) => {
        //     const dwt = parseInt(item.dwt, 10).toLocaleString();
        //     const total_cargo_load= parseInt(item.total_cargo_load, 10).toLocaleString();

        //     return { ...item, dwt,total_cargo_load };
        //   })
        // }
        // else{
        //   SameCompany=SameCompany.map((item) => {
        //     const dwt = parseInt(item.dwt, 10).toLocaleString();
        //     const total_cargo_load= parseInt(item["Estimated Cargo Load (t)"], 10).toLocaleString();

        //     return { ...item, dwt,total_cargo_load };
        //   })
        // } 

        let headerTitle =
          Filters?.type == 'barges'
            ? {
              'Timestamp UTC': 'ata_utc',
              Vessel: 'ship_name',
              'Est Cargo Load': 'estimated_cargo_load',
              'Buyer': 'mill',
              'Arrival Country': 'actual_dest_country',
              'Supplier': 'company',
              'Origin Country': 'country'
            } :
            Filters?.type == 'arrivals'
              ? {
                'Timestamp UTC': 'ata_utc',
                Vessel: 'ship_name',
                'Est Cargo Load': comodity == 'ironore' ? 'arr_total_cargo_load' : 'total_cargo_load',
                'Buyer': 'mill',
                'Country': comodity == 'ironore' ? 'actual_dest_country' : 'dest_country',
                'Supplier': 'company',
                'Origin Country': 'country'
              }
              : {
                'Timestamp UTC': 'timestamp_utc',
                Vessel: 'ship_name',
                'Est Cargo Load': 'total_cargo_load',
                'Supplier': 'company',
                'Country': 'country',
                'Dest Port': 'destination',
                'Dest Country': 'dest_country'
              }

        let title = `<tr><th></th>`
        let rowList = ``
        // let newcomp=[company]
        // console.log(company)
        // console.log(index)
        // console.log(e.lngLat)
        // console.log(tableCompanies)

        // console.log(map.getLayer(`route${index}`))
        // console.log(map.getSource(`route${index}`))
        // const x=map.queryRenderedFeatures([-85.85424109801191,25.879157343300463], {
        //   layers: ['route0','route1','route2'],
        // })
        // console.log(x)

        let uniqueCompanyIDs = [];
        let uniquecomps = [];
        let closestCompany = null;
        let closestDistance = Infinity;
        let foundCloseCompany = false;

        for (const key in tableCompanies) {
          const companyData = tableCompanies[key];
          const companyID = companyData.company.id;


          for (const coordinates of companyData.coordinates) {
            const companyCoords = {
              lng: parseFloat(coordinates[0]),
              lat: parseFloat(coordinates[1]),
            };

            const distance = haversineDistance(e.lngLat, companyCoords);

            const distanceThreshold = 50;

            if (distance < distanceThreshold) {
              if (!uniqueCompanyIDs.includes(companyID)) {
                uniquecomps.push(companyData.company);
                uniqueCompanyIDs.push(companyID);
              }
              foundCloseCompany = true;
              break; // No need to check other coordinates for the same company
            }

            // Check for the closest company when no company is within the threshold
            if (distance < closestDistance) {
              closestDistance = distance;
              closestCompany = companyData.company;
            }
          }
        }
        if (!foundCloseCompany && closestCompany) {
          uniquecomps.push(closestCompany);
          uniqueCompanyIDs.push(closestCompany.id);
        }

        console.log(uniqueCompanyIDs);
        console.log(uniquecomps)

        if (Filters?.type == 'arrivals') {
          uniquecomps = uniquecomps.map((item) => {
            const dwt = parseInt(item.dwt, 10).toLocaleString();
            const total_cargo_load = parseInt(item.total_cargo_load, 10).toLocaleString();
            const arr_total_cargo_load = parseInt(item.arr_total_cargo_load, 10).toLocaleString();

            return { ...item, dwt, total_cargo_load, arr_total_cargo_load };
          })
        } else if (Filters?.type == 'barges') {
          uniquecomps = uniquecomps.map((item) => {
            const estimated_cargo_load = parseInt(item.estimated_cargo_load, 10).toLocaleString();

            return { ...item, estimated_cargo_load };
          })
        }
        else {
          uniquecomps = uniquecomps.map((item) => {
            const dwt = parseInt(item.dwt, 10).toLocaleString();
            let total_cargo_load = parseInt(item["Estimated Cargo Load (t)"], 10).toLocaleString();
            if (total_cargo_load == "NaN")
              total_cargo_load = parseInt(item["total_cargo_load"]).toLocaleString();
            return { ...item, dwt, total_cargo_load };
          })
        }

        uniquecomps?.map((item, index) => {
          rowList =
            rowList +
            `<tr class="collapsed" id="collapsed-row-${index}" onclick="const expandedRow = document.getElementById('expanded-row-${index}');
            if (expandedRow.style.display === 'table-row') {
              expandedRow.style.display = 'none';
            } else {
              const allExpandedRows = document.querySelectorAll('.expanded-row');
              allExpandedRows.forEach(row => {
                row.style.display = 'none';
              });
              expandedRow.style.display = 'table-row';
            }
            const collapsedRow = document.getElementById('collapsed-row-${index}');
            if (collapsedRow.classList.contains('expanded')) {
              collapsedRow.classList.remove('expanded');
            } else {
              collapsedRow.classList.add('expanded');
            }
            "
            ><td></td>`
          Object.keys(headerTitle).map(key => {
            let currentKey = headerTitle[key]
            rowList = rowList + `<td>${item[currentKey]}</td>`
          })
          rowList = rowList + `<td class="rowarrow">&#x2304;</td></tr>`

          // Additional row for expanded content
          rowList =
            rowList +
            `<tr class="expanded-row" id="expanded-row-${index}" style="display: none;"><td  colspan="9"><div class="detail-show">`
          typeColumns?.map(i => {
            if (item[i?.dataIndex] == null) {
              return
            }
            rowList =
              rowList +
              `<div class="company-data"><span class="righttitle"><b>${i?.title
              }:   </b> </span><span>      </span><span>${item[i?.dataIndex]
              }</span></div>`
          })
          rowList = rowList + `</div></td></tr>`
        })

        Object.keys(headerTitle).map(item => {
          title = title + `<th>${item}</th>`
        })
        title = title + `<th></th></tr>`
        const uniqueNumberString = new Date().getTime().toString().slice(-6);
        setTableId('table' + uniqueNumberString)
        var newPopup = `<div class="secondcontainer"><table>${title}${rowList}</table></div>`
        setPopupContent(`<table class="modaltable1" id="table${uniqueNumberString}"><thead>${title}</thead><tbody class="modal-tbody">${rowList}</tbody></table>`)

        var popupContent = `<div class="popcontainer" ><div class="header"><h5>
       ${company?.company}
        </h5><button onclick="function close(){var popup = document.querySelector('.mapboxgl-popup-close-button')
        console.log(popup)
        if (popup) {
          popup?.click()
        }}()"
        class="close-btn"></button></div><div class="datacontainer">
        ${datalist}
        </div></div>`
        // Set the popup coordinates and content and add it to the map.
        let popuptemplate = newPopup
        // popupshow.setLngLat(coordinates).setHTML(popuptemplate).addTo(map)
        setModalVisible(true)

        const elementdata = document?.querySelectorAll(
          '.mapboxgl-popup-anchor-top'
        )

        // console.log(elementdata)
        if (elementdata?.length > 1) {
          elementdata?.forEach((item, index) => {
            if (index != 0) {
              item?.remove()
            }
          })
        }
      })
      map.on('mouseenter', `route${index}`, function () {
        map.getCanvas().style.cursor = 'pointer' // Change cursor style
      })
      map.on('mouseleave', `route${index}`, function () {
        map.getCanvas().style.cursor = '' // Reset cursor style
      })
      const element = document?.querySelectorAll(
        '.mapboxgl-popup-anchor-bottom'
      )
      console.log(loadingLimit)
      setLoadingLimit(prev => prev - 1)

      console.log(element)
      alldata = null
      return null
    }

    const changeMapStyle = (style, type) => {
      // getCompaniesdata()

      changeMapStyles(style)
      setCurrentMap(type)
      if (mapdata) {
        const currentZoom = mapdata.getZoom()
        const currentCenter = mapdata.getCenter()
        mapdata.remove()
        const newMap = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: style,
          center: currentCenter,
          zoom: currentZoom,
          projection: 'mercator',
        })
        setMap(newMap)
        drawData(newMap, Filters, companydata, showPath, comodity)
      }
    }

    useEffect(() => {
      if (modalVisible)
        adjustColumnWidths();
    }, [modalVisible]);

    const adjustColumnWidths = () => {
      // console.log(tableId)
      const table = document.querySelector(`#${tableId}`);
      const table2 = document.querySelector('.modaltable1')
      // console.log(table)
      // console.log(table2)
      if (table) {
        const headerColumns = table.querySelectorAll('thead th:nth-child(n+2)');
        const bodyColumns = table.querySelectorAll('tbody td:nth-child(n+2)');

        headerColumns?.forEach((headerCol, index) => {
          const headerColWidth = headerCol.offsetWidth;

          const bodyCol = bodyColumns[index];
          const bodyColWidth = bodyCol?.offsetWidth;

          const greaterWidth = Math.max(headerColWidth, bodyColWidth);
          // console.log(greaterWidth)

          headerCol.style.width = `${greaterWidth}px`;
          bodyCol.style.width = `${greaterWidth}px`;
        });
      }
    };


    useEffect(() => {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.target.classList.contains('modal-body') && mutation.addedNodes.length > 0) {
            // When modal-body is populated, call your function
            console.log("first")
            adjustColumnWidths();
          }
        }
      });

      const modalBodyElement = document.querySelector('.modal-body');
      if (modalBodyElement) {
        observer.observe(modalBodyElement, { childList: true });
      }

      return () => {
        observer.disconnect();
      };
    }, [popupContent]);

    const closeModal = () => {
      setModalVisible(false)
      setPopupContent(null)
      setTableId(null)
    }

    return (
      <>
        {(!loader && (loadingLimit > 0 || !done)) &&
          <div className='map-loading-overlay'>
            <Spin tip='' size='large' className='routespin' style={{ zIndex: '99' }}>
              <div className='content' />
            </Spin>
          </div>}
        {contextHolder}
        <div className='map-container' id='company-map' ref={mapContainerRef}>
          <Button
            className='absolute top-2/4 z-10 transform -rotate-90 -left-14 pt-3 h-auto font-medium'
            type='primary'
            onClick={showDrawer}
          >
            More Filters
            <DownOutlined />
          </Button>
          {Filters.type !== 'intransit' && <Button
            className='absolute top-2/4 z-10 transform -rotate-90 -right-14 pb-3 h-auto font-medium'
            type='primary'
            onClick={showChartsDrawer}
          >
            View Charts
            <UpOutlined />
          </Button>}

          <Radio.Group
            id='menu'
            ref={btngroupRef}
            defaultValue={currentMap}
            buttonStyle='solid'
            className='custom-map-radio-group absolute w-full flex justify-center bottom-8 z-10'
          >
            <Radio.Button
              id='outdoors-v12'
              value='outdoors'
              className='map-radio-btn'
              onClick={() => {
                // mapdata.setStyle(
                //   'mapbox://styles/caspiangroup/cljpfar92003e01qo2jpfc83b'
                // )
                changeMapStyle(
                  'mapbox://styles/caspiangroup/cljpfar92003e01qo2jpfc83b',
                  'outdoors'
                )
                // drawData()
              }}
            >
              Outdoors
            </Radio.Button>
            <Radio.Button
              id='streets-v12'
              value='streets'
              className='map-radio-btn'
              onClick={() => {
                // mapdata.setStyle('mapbox://styles/mapbox/streets-v12')
                changeMapStyle('mapbox://styles/mapbox/streets-v12', 'streets')
                // drawData()
              }}
            >
              Streets
            </Radio.Button>
            <Radio.Button
              id='satellite-streets-v12'
              value='satelite'
              className='map-radio-btn'
              onClick={() => {
                // mapdata.setStyle('mapbox://styles/mapbox/satellite-streets-v12')
                changeMapStyle(
                  'mapbox://styles/mapbox/satellite-streets-v12',
                  'satelite'
                )
                // drawData()
              }}
            >
              Satellite
            </Radio.Button>
          </Radio.Group>
        </div>
        <div id='map'></div>

        <Modal centered open={modalVisible} className="secondcontainer" footer={null} onCancel={closeModal}>
          <div className='modal-body' dangerouslySetInnerHTML={{ __html: popupContent }}>
          </div>
        </Modal>
        {Filters.type == 'intransit' && !loader ? <Legend legends={legends} addLegend={addLegend} removeLegend={removeLegend} /> : null}
      </>
    )
  }
)

export default Map
