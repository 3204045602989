import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { company_apis } from './api'
const initialState = {
  seaRoutes: [],
  coloadRoute: []
}

var allSeaRoutes = []
var allCoload = []
const dbName = 'myDatabase'
const dbVersion = 1
let allDbData;
export const getSeaRoutes = createAsyncThunk('data/seaRoutes', async token => {
  // const response = await getAllSeaRoutes(1, token)
  const response = await getAllData(token);
  console.log("All routes added", response)
  localStorage.setItem('seaRoutes', JSON.stringify(response?.alldata))
  return response
})

export const getCoLoadRoutes = createAsyncThunk('data/coload', async token => {
  response = await getAllSeaCoload(1, token)
  console.log(response)
  return response
})

export function getAllDataFromDB() {
  if (allDbData && allDbData.length > 0)
    return allDbData;

  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readonly')
        const objectStore = transaction.objectStore('myObjectStore')

        const getRequest = objectStore.getAll()

        getRequest.onsuccess = function (event) {
          allDbData = event.target.result;
          resolve(event.target.result)
        }

        getRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function RemoveAllDataFromDB() {
  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readwrite')
        const objectStore = transaction.objectStore('myObjectStore')

        const getRequest = objectStore.clear();

        getRequest.onsuccess = function (event) {
          allDbData = event.target.result;
          resolve(event.target.result)
        }

        getRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
export const checkNewDataAvailable = async (token) => {
  const result1 = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?page=${1}`, token)
  if (result1) {
    const allData = await getAllDataFromDB();
    allData && allData.length > 0 && console.log("Local Ship data count: ", allData[0].length)
    console.log("Remote Ship data count: ", result1?.data?.meta?.total)
    if (allData && allData.length > 0 && allData[0].length >= result1?.data?.meta?.total) {
      return false;
    } else {
      await RemoveAllDataFromDB();
    }
    return result1;
  }
  return false;
}
export const getAllData = async (token) => {
  const result1 = await checkNewDataAvailable(token);
  if (!result1) return;
  allDbData = null;
  allSeaRoutes = [];
  // allSeaRoutes = [...allSeaRoutes, ...result1?.data?.data]
  // const allPromises = [];
  // const last_index = result1?.data?.meta?.last_page;
  // for (let index = 2; index <= 45; index++) {
  //   const p = new Promise(async (resolve, reject) => {
  //     const result = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?page=${index}`, token)
  //     allSeaRoutes = [...allSeaRoutes, ...result?.data?.data]
  //     resolve(...result?.data?.data)
  //   });
  //   allPromises.push(p);
  // }
  // const allData = await Promise.all(allPromises);
  // console.log("All Promise Data", allData)

  const result = await company_apis?.getSeaRoutes(`/shipData/companies/routes/shipdata?limit=1000000&page=${1}`, token)
  allSeaRoutes = result?.data?.data
  console.log("All Combined Data", allSeaRoutes)
  addDataToDB(allSeaRoutes);
}
// const getAllSeaRoutes = async (pageNo, token) => {
//   await company_apis
//     ?.getSeaRoutes(`/shipData/companies/routes/shipdata?page=${pageNo}`, token)
//     .then(data => {
//       console.log(data)
//       allSeaRoutes = [...allSeaRoutes, ...data?.data?.data]
//       if (pageNo < data?.data?.meta?.last_page) {
//         getAllSeaRoutes(data?.data?.meta?.current_page + 1, token)
//       } else {
//         data.alldata = allSeaRoutes
//         addDataToDB(allSeaRoutes);
//         console.log("All Sea Routes", allSeaRoutes)

//         return data
//       }
//     })
//     .catch(err => {
//       return []
//     })
// }

const getAllSeaCoload = async (pageNo, token) => {
  await company_apis
    ?.getSeaRoutes(`/shipData/companies/routes/coload?page=${pageNo}`, token)
    .then(data => {
      console.log(data)
      allCoload = [...allCoload, ...data?.data?.data]
      if (pageNo < data?.data?.meta?.last_page) {
        getAllSeaCoload(data?.data?.meta?.current_page + 1, token)
      } else {
        localStorage.setItem('coloads', JSON.stringify(allCoload))
        data.alldata = allCoload

        return data
      }
    })
    .catch(err => {
      return []
    })
}
function addDataToDB(data) {
  return new Promise((resolve, reject) => {
    openDB()
      .then(db => {
        const transaction = db.transaction(['myObjectStore'], 'readwrite')
        const objectStore = transaction.objectStore('myObjectStore')
        console.log("Data store in db", data);
        const addRequest = objectStore.add(data)

        addRequest.onsuccess = function (event) {
          resolve(event.target.result)
        }

        addRequest.onerror = function (event) {
          reject(event.target.error)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
function openDB() {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName, dbVersion)

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result
      if (!db.objectStoreNames.contains('myObjectStore')) {
        db.createObjectStore('myObjectStore', {
          keyPath: 'id',
          autoIncrement: true
        })
      }
    }

    openRequest.onsuccess = function (event) {
      resolve(event.target.result)
    }

    openRequest.onerror = function (event) {
      reject(event.target.error)
    }
  })
}
export const companySlice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSeaRoutes.fulfilled, (state, action) => {
        state.seaRoutes = action?.payload?.data?.data
      })
      .addCase(getSeaRoutes.rejected, (state, action) => {
        state.seaRoutes = []
      })
      .addCase(getCoLoadRoutes.fulfilled, (state, action) => {
        state.coloadRoute = action?.payload?.data?.data
      })
      .addCase(getCoLoadRoutes.rejected, (state, action) => {
        state.coloadRoute = []
      })
  }
})

export default companySlice?.reducer
