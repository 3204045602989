import React,{useEffect} from 'react'
import { useLocation } from 'react-router-dom';

const Tradeflows = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      let synth=window.speechSynthesis;
      synth.cancel();
    }, 300);
     
}, [location]);
  return (
  <>
  
  <h1>
    Trade Flows
  </h1>
  </>
  )
}

export default Tradeflows