import React, { useEffect, useState } from 'react';
import { Input, Modal, Button } from 'antd';
import { ReactComponent as Close } from '../../Assets/images/icons/close.svg'
const ModalDialog = (props) => {
    const [filterName, setFilterName] = useState(props.filterName);
    useEffect(() => {
        setFilterName(props.filterName)
    }, [props.filterName])
    return (
        <>
            {props.operation == "1" ?
                <Modal closeIcon={<Close />}
                    width={props.width ?? 520}
                    title={"Rename Filter"}
                    className={props.className}
                    open={props.isModalOpen}
                    onCancel={props.handleCancel}
                    footer={<Button type="primary" onClick={() => props.handleOk(filterName)}> Save </Button>}
                >
                    <div className='mt-5 text-xs text-neutral-700' >Update Filter Name</div>
                    <div className="mt-1">
                        <Input placeholder="Unsaved Filter" value={filterName} onChange={(e) => { setFilterName(e.target.value) }} />
                    </div>
                </Modal>
                :
                props.operation == "2" ?
                    <Modal
                        width={props.width ?? 520}
                        title={"Delete Filter"}
                        open={props.isModalOpen}
                        closeIcon={<Close />}
                        onCancel={props.handleCancel}
                        footer={<Button type="primary" onClick={() => props.handleOk()} > Delete </Button>}
                    >
                        <div className='mt-5 text-xs text-neutral-700' >Are you sure you want to <b>delete</b> this filter?</div>
                    </Modal>
                    : null
            }
        </>
    );
};
export default ModalDialog;