// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.reset-center-div{
    background-color: white;
    position: relative;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6% 9%;
    border-radius: 2%;
    min-width: 20%;
}

.reset-center-div >h1{
    position: relative;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 4%;
    margin-bottom: 2%;
}

.login-btnnn{
    position: relative;
    margin-top: 7%;
    min-width: 65%;
    padding-top: 3%;
    padding-bottom: 9%;
    font-weight: 500;
    font-size: 1rem;
}

.login-btnnn:hover{
    background-color: #0071c1 !important;
    opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/Components/forgetPassword/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,YAAY;AAChB","sourcesContent":[".TitleHeader{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 18px;\n    font-weight: 600;\n    color: white;\n}\n\n.reset-center-div{\n    background-color: white;\n    position: relative;\n    margin-top: 15%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 6% 9%;\n    border-radius: 2%;\n    min-width: 20%;\n}\n\n.reset-center-div >h1{\n    position: relative;\n    font-weight: 700;\n    font-size: 1rem;\n    margin-top: 4%;\n    margin-bottom: 2%;\n}\n\n.login-btnnn{\n    position: relative;\n    margin-top: 7%;\n    min-width: 65%;\n    padding-top: 3%;\n    padding-bottom: 9%;\n    font-weight: 500;\n    font-size: 1rem;\n}\n\n.login-btnnn:hover{\n    background-color: #0071c1 !important;\n    opacity: 0.9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
