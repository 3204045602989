import { useEffect, useState } from "react"
import { SubMenu } from "../Components/subMenu"
import { useLocation, useNavigate } from "react-router-dom";
import Fixtures from "./Fixtures";
import Company from "./Company/Company";
import Fifa from "./Fifa/Fifa";
import { checkAccessLevel, checkAccessLevelById, getAccessLevels, submenuAccessLevel } from "../Components/access-level";
import { WarningModal } from "../Components/Misc/WarningModal";

export const FerrousScrap = () => {

    const navigate = useNavigate();
    const [selectedSubMenu, setSeletectedSubMenu] = useState("");
    useEffect(() => {
        const access = checkAccessLevelById(2);
        if (!access) {
            navigate('/welcome')
            WarningModal("Fixtures/Line-ups")
        }
        else
            setSeletectedSubMenu('/fixtures');
    }, [])
    return (
        <div>
            <SubMenu submenuAccessLevel={submenuAccessLevel} accessLevels={getAccessLevels()} selectedSubMenu={selectedSubMenu} setSeletectedSubMenu={setSeletectedSubMenu} />
            {selectedSubMenu == "/fixtures" ? <Fixtures comodity={'ferrous'} /> :
                selectedSubMenu == "/company" ? <Company comodity={'shipData'} /> :
                    selectedSubMenu == "/fifa" ? <Fifa /> : ""}
        </div>
    )
}   