// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-main-wrapper {
  height: calc(100vh - 117px) !important;
}
.company-reset {
  background-color: #0071c1;
  color: #ffff;
}
.company-reset:hover {
  color: #dedede;
}
.antd-custom-company-drawer-form-wrapper {
    /* height: calc(100% - 120px) */
}

.companyLoader {
  position: absolute;
  height: 100vh;
  width: 100%;
  background: black;
  z-index: 92;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapspin .ant-spin-dot .ant-spin-dot-item{
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Company/company.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;IACI,+BAA+B;AACnC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".company-main-wrapper {\n  height: calc(100vh - 117px) !important;\n}\n.company-reset {\n  background-color: #0071c1;\n  color: #ffff;\n}\n.company-reset:hover {\n  color: #dedede;\n}\n.antd-custom-company-drawer-form-wrapper {\n    /* height: calc(100% - 120px) */\n}\n\n.companyLoader {\n  position: absolute;\n  height: 100vh;\n  width: 100%;\n  background: black;\n  z-index: 92;\n  opacity: 0.7;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.mapspin .ant-spin-dot .ant-spin-dot-item{\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
