import React, { useEffect, useState } from 'react'
import { Form, Select, DatePicker, InputNumber } from 'antd'
import dayjs from 'dayjs'
const { RangePicker } = DatePicker
const FiltersForm = ({
  Filters,
  arrivalandshipment,
  applyFilter,
  applyExtraFilter,
  setDwtValues,
  dwtValue,
  trailversion
}) => {
  const [filterdata, setFilterdata] = useState({})
  const [form] = Form.useForm()

  useEffect(() => {
    let filterkeys = Object.keys(Filters?.extraFilter || {})?.map(item => {
      return {
        key: item,
        defaultvalue: Filters?.applyExtraFilter[item],
        value: Filters?.extraFilter[item],
        values:
          arrivalandshipment?.[Filters?.type] == undefined
            ? [{}]
            : [
              { value: '', label: `${item}` },
              ...arrivalandshipment?.[Filters?.type]?.[
                Filters?.extraFilter[item]
              ]?.map(keys => {
                return { value: keys, label: keys }
              })
            ]
      }
    })
    setFilterdata({ ...Filters, alldata: filterkeys })
  }, [arrivalandshipment, Filters])

  const calculateDefaultDate = () => {
    const end = new Date()

    const start = new Date()
    start.setDate(start.getDate() - 29)
    end.setDate(end.getDate())
    let ata_utc_start = `${start?.getFullYear()}-${start?.getMonth() + 1 < 10
      ? '0' + (start?.getMonth() + 1)
      : start?.getMonth() + 1
      }-${start?.getDate() < 10 ? '0' + start?.getDate() : start?.getDate()}`
    let ata_utc_end = `${end?.getFullYear()}-${end?.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1
      }-${end?.getDate() < 10 ? '0' + end?.getDate() : end?.getDate()}`
    return [ata_utc_start, ata_utc_end]
  }

  const disabledDate = current => {
    const today = dayjs();
    const oneMonthAgo = today.subtract(30, 'days');


    // Define the disabled date function
    // Disable dates that are not within the allowed range
    if (trailversion) {
      if (current > today || current < oneMonthAgo) {
        return true
      }
      return false
    }
    else {
      if (current > today) {
        return true
      }
      return false
    }
  }

  return (
    <>
      <Form form={form} name='filter' layout='vertical' autoComplete='off'>
        {Filters?.dateFilter.length > 0 && <Form.Item
          label={<label className='font-semibold'>{Filters?.dateFilter[0].title}</label>}
          name='atd_utc'
          key={'atd_utc'}
        >
          <div className='flex justify-between'>
            {Filters?.dateFilter.map(item => {
              return (<RangePicker
                name='atd_utc'
                className='w-full'
                value={
                  Filters?.applyExtraFilter?.hasOwnProperty(item.key1)
                    ? [
                      dayjs(
                        Filters?.applyExtraFilter?.[item?.key1] ||
                        calculateDefaultDate()?.[0],
                        'YYYY-MM-DD'
                      ),
                      dayjs(
                        Filters?.applyExtraFilter?.[item?.key2] ||
                        calculateDefaultDate()?.[1],
                        'YYYY-MM-DD'
                      )
                    ] : []
                }
                format={'YYYY-MM-DD'}
                disabledDate={disabledDate}
                onChange={(dates, dateStrings) => {
                  applyExtraFilter('atd_utc', {
                    [item?.key1]: dateStrings[0],
                    [item?.key2]: dateStrings[1],
                  })
                }}
              />)
            })}
          </div>
        </Form.Item>}
        {filterdata?.alldata?.map((item1, key) => {
          return (
            <Form.Item
              label={<label className='font-semibold'>{item1?.key}</label>}
              name='ship-name'
              key={item1?.key}
            >
              {' '}
              <p>{Filters?.applyExtraFilter?.hasOwnProperty(item1.value)}</p>
              <Select
                //showSearch
                // defaultValue={Filters?.applyExtraFilter?.item1?.value}
                id={item1?.value}
                defaultValue={
                  Filters?.applyExtraFilter?.hasOwnProperty(item1.value)
                    ? Filters?.applyExtraFilter?.[item1?.value]
                    : ''
                }
                value={
                  Filters?.applyExtraFilter?.hasOwnProperty(item1.value)
                    ? Filters?.applyExtraFilter?.[item1?.value]
                    : ''
                }
                name={item1}
                placeholder='Search'
                onChange={e => {
                  applyExtraFilter(item1?.value, e)
                }}
                options={item1?.values}
              />
              {/* {item1?.value}
              {Filters?.applyExtraFilter?.[item1?.value]} */}
            </Form.Item>
          )
        })}
        {Filters?.childFilter.length > 0 &&
          Filters?.childFilter?.map((child, key) => {
            return (<Form.Item
              label={<label className='font-semibold'>{child.title}</label>}
              name='ship-name'
              key={key}
            >

              <div key={key} className='flex justify-between'>
                {child.children?.map(item => {
                  return (
                    <>
                      <label>{item?.title}</label>
                      <InputNumber
                        key={item?.title}
                        value={Filters?.applyExtraFilter?.hasOwnProperty(item.key)
                          ? Filters?.applyExtraFilter?.[item?.key]
                          : ''}
                        onChange={value =>
                          applyExtraFilter(item.key, value)
                        }
                      />
                    </>
                  )
                })}
              </div>

            </Form.Item>)
          })}
      </Form>
    </>
  )
}

export default FiltersForm
