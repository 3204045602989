import React, { useState } from "react";
import vectorExpand from "../../Assets/images/Marketcompass/VectorExpand.svg";

const Disclaimer = ({ disclaimertext }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="mt-2 mc-link-disclaimer-wrapper w-full ">
      <p className="font-normal text-base">Disclaimer</p>
      <p className="font-medium text-xs mc-link-disclaimer-text mt-2 inline">
        {disclaimertext?.slice(3, disclaimertext?.length / 2)}
        <span className={`${
          show ? "hidden" : "inline"
        }`}>...</span>
        <span
        className={`font-medium text-xs mc-link-disclaimer-text  ${
          show ? "inline" : "hidden"
        }`}
      >
        {disclaimertext?.slice(  
          disclaimertext?.length / 2,
          disclaimertext?.length - 4
        )}
      </span>
      </p>
     
     

      <div className="w-full flex items-center mt-2">
        <button
          className="mx-auto flex flex-col text-xs font-semibold text-color-primary"
          onClick={() => setShow(!show)}
        >
          {show == false ? (
            <>
              <span>Expand</span>
              <span className="mx-auto mt-2">
                <img src={vectorExpand} />
              </span>
            </>
          ) : (
            <>
              <span>Collapse</span>
              <span className="mx-auto mt-2 transform rotate-180">
                <img src={vectorExpand} />
              </span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;
