import React, { useEffect } from 'react'
import './footer.css'
function Footer () {
  useEffect(() => {
    if (window.location?.pathname == '/account') {
      let wrapper = document?.getElementById('footer-wraper')
      if (wrapper) {
        wrapper?.classList?.add('footer-setting')
      }
    } else {
      let wrapper = document?.getElementById('footer-wraper')
      if (wrapper) {
        wrapper?.classList?.remove('footer-setting')
      }
    }
  }, [])
  return (
    <div
      className='footer-wrapper text-white  flex items-center '
      id='footer-wraper'
    >
      <div className='w-full  m-auto flex  h-full flex-col md:flex-row '>
        <div className='w-full   md:w-3/5 '>
          <p className='text-xs md:text-sm  footer-text'>
            <span className='mr-2 text-xs md:text-sm'>©</span>Navigate
            Commodities Pte. Ltd. 2022-2023. All Rights Reserved.
          </p>
        </div>
        <div className='w-full md:w-3/5 h-auto'>
          <ul className='flex text-xs md:text-sm justify-end'>
            <li className='footerItemsList footer-item-spacing'>
              <a
                href='https://navigate-web.sys.mwancloud.com/cookies-policy'
                target={'_blank'}
              >
                Cookies
              </a>
            </li>
            <li className='footerItemsList footer-item-spacing'>
              <a
                href='https://navigate-web.sys.mwancloud.com/term-condition'
                target={'_blank'}
              >
                Terms & Conditions
              </a>
            </li>
            <li className='footerItemsList footer-item-spacing'>
              <a
                href='https://navigate-web.sys.mwancloud.com/privacy-policy'
                target={'_blank'}
              >
                Privacy Policy
              </a>
            </li>
            <li className='footer-item-spacing'>
              <a
                href='https://navigate-web.sys.mwancloud.com/salvery-statement'
                target={'_blank'}
              >
                Modern Slavery Statement
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer
