import { Link,useNavigate } from 'react-router-dom'
import { Input,Spin,Button } from 'antd'
import { useState } from 'react'
import FieldValidation from '../fieldsvalidation'
import './styles.css'
import UseFetchAxios from '../../Hooks/UseFetchAxios'
// import Check from '../../Assets/images/Check.png'
import { ReactComponent as Check } from '../../Assets/images/Check.svg'


const ResetPassword = () => {
  const useFetch = UseFetchAxios()
  const [emails, setEmail] = useState({
    email: ''
  })
  const [loading,setLoading] = useState(false)
  const [error, setError] = useState('')
  const [done,setDone]=useState(false)
  const navigate=useNavigate()

  const updatePassword = (key, value) => {
    setEmail({
     
      [key]: value
    })
  }

  const setNewPassword = () => {
    console.log(emails?.email)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emails?.email?.trim()?.length == 0 || emailPattern.test(emails?.email) == false) {
      setError('Please Enter Valid Email')
      setTimeout(() => {
        setError('')
      }, 5000)
      return
    }
    setLoading(true)
    useFetch?.apiCallPost('/forget-password', {...emails,redirect_url:`${process.env.REACT_APP_FRONTEND_URL}/forgetPassword/`}, (err, data) => {
      if(err){
        console.log(err)
        if(err?.response?.data?.errors=="Email not found")
        setError(err?.response?.data?.errors)
        else
        setError("Something went wrong. Please try again or email phyllis.shung@navigatecommodities.com")
        setTimeout(() => {
          setError('')
        }, 5000)
        setLoading(false)
      }
      else{
        console.log(data)
        setError(data?.message)
        setDone(true)
        setTimeout(() => {
          setError('')
        }, 5000)
        setLoading(false)
        setLoading(false)
      }
   

    })
  }
  return (
    <>
    {!done ? <div className='flex flex-col'>
      <div className='flex flex-col gap-y-2 justify-start items-start'>
        <h3 className='TitleHeader'>Reset Password</h3>
        <label className=' login-label'>Email Address*</label>
        <Input
          className='login-fields '
          type='email'
          autoComplete='new-password'
          onChange={e => {
            updatePassword('email', e?.target?.value)
          }}
        />
      </div>
      
      {/* <FieldValidation msg={error}></FieldValidation> */}
      {error.length>0? <p style={{color:'rgb(255, 219, 0)'}}>{error}</p>:null}
      <div className='flex justify-between mt-6 login-label'>
        <button
          className='login-btn'
          onClick={() => {
            setNewPassword()
          }}
          disabled={loading}
        >
          {loading ? <Spin></Spin>:'Reset Password'}
        </button>
      </div>
      <div className='flex mt-20 justify-center'>
        <Link to='/' className='login-reset'>
          Take me to the Login
        </Link>
      </div>
    </div>
  :
    <div className='flex flex-col' style={{width:'90%'}}>
      <div className='flex flex-col gap-y-2 justify-start items-start'>
        <h3 className='TitleHeader'>Reset Password</h3>
      </div>
      <div className='reset-center-div'>
          <Check/>
          <h1>Reset password request received</h1>
          <p>Check your email for instructions to reset your password</p>
          <Button onClick={()=>{navigate('/')}} className='login-btnnn' type='primary'>Take me to the Login</Button>
      </div>
    </div>
  }
  </>
  )
}
export default ResetPassword