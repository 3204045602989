import React from "react";
import { Link } from "react-router-dom";

const ReadMoreLink = ({ statedata }) => {
  console.log("statedata-------------", statedata);
  return (
    <div className="inline ml-2">
     
      <Link
        to={`/market-compass/${statedata?.title?.replaceAll(" ", "-")}/${statedata?.id}`}
        //state set
        state={statedata}
        className="font-semibold text-xs underline leading-4 underline-offset-4"
      >
        READ MORE
      </Link>
    </div>
  );
};

export default ReadMoreLink;
