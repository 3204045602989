import { createContext, useState } from "react";

const UserContext = createContext({ userState: { isAuth: false, token: null, email: null,firstName:null,lastName:null,isUpdated:false }, setUserState: () => { } })

// provider component
export const UserProvider =({children})=>{
    const [userState, setUserState] = useState({ isAuth: false, token: null, email: null,firstName:null,lastName:null,isUpdated:false })
    const context = { userState, setUserState }
    return <UserContext.Provider value={context}>
        {children}

    </UserContext.Provider>


}
// custom hook
export default UserContext
