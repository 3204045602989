import React, { useEffect } from 'react';
import axios from '../axiosconfiguration/axios'

const LogUserIp = () => {

  useEffect(() => {
    // Function to send the API call
    const logIp = async () => {
      try {
        const token = localStorage.getItem('token');
        const email = localStorage.getItem('email');
        const id = localStorage.getItem('userId');

        if (token && email && id) {
          await axios.post(
            '/log/user', { email, id },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )
          console.log("User's IP Logged");
        }
      } catch (error) {
        console.error('Error logging IP', error);
      }
    };

    // Log the IP immediately
    logIp();

    // Set up the interval to log every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(logIp, 300000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  return (
    <></>
  );
};

export default LogUserIp;