
import "./style.css";
import { ReactComponent as Close } from '../../Assets/images/icons/close.svg'
import { ReactComponent as SaveSetting } from '../../Assets/images/icons/save-setting-black.svg'
import { Button, Modal } from 'antd'
import { SavedFilter } from "./SavedFilter";
import { useEffect, useState } from "react";
export const ManageSearches = (props) => {
    const [searchType, setSearchType] = useState(0);
    useEffect(() => {
        if (props.isSavedFilterCall)
            setSearchType(0);
    }, [props.isSavedFilterCall])
    return (

        <>
            <Modal className={"manage-search-dialog"} closeIcon={<Close />}
                title={
                    <div className='manage-search-header-title'>
                        <div ><SaveSetting /></div>
                        <p  >Manage Searches</p>
                    </div>
                }
                open={props.openManageSearch}
                onCancel={props.onClose}
                footer={<></>}
            >
                <div className="border-line"></div>
                <div className="manage-search-buttons">
                    <div> <Button className={searchType == 1 ? 'manage-search-selected' : 'manage-search-unselected'} type="primary" onClick={() => { setSearchType(1) }}>Saved Searches</Button></div>
                    <div> <Button className={searchType == 0 ? 'manage-search-selected' : 'manage-search-unselected'} type="primary" onClick={() => { setSearchType(0) }}>Search History</Button></div>
                </div>
                <div>
                    <SavedFilter setIsSavedFilterCall={props.setIsSavedFilterCall} isSavedFilterCall={props.isSavedFilterCall} searchType={searchType} filtersdata={props.filtersdata} savedSearches={props.savedSearches} onSearchMenuClick={props.onSearchMenuClick} onDeleteAllSearches={props.onDeleteAllSearches} />
                </div>
            </Modal>
        </>
    )
}