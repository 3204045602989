import axios from '../../axiosconfiguration/axios'

export const company_apis = {
  getCompaniesdata: (url, filter, token, cb) => {
    axios
      .post(url, filter, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(data => {
        cb(null, data)
      })
      .catch(err => {
        cb(err, null)
      })
  },
  getFilters: (url, data, token, cb) => {
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(data => {
        cb(null, data)
      })
      .catch(err => {
        err, null
      })
  },
  getSeaRoutes: async (url, token) => {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response
  }
}
