// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }
  
  .yellow-fill {
    color: #FDC32D;
  }
  
  .yellow-border {
    border: 1px solid #FDC32D;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/Components/rate/RatingStars.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;EACV;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".rating-stars {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 3px;\n  }\n  \n  .yellow-fill {\n    color: #FDC32D;\n  }\n  \n  .yellow-border {\n    border: 1px solid #FDC32D;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
