import React from "react";
import Slider from "react-slick";
import Sliderimg from '../../Assets/images/Marketcompass/MarketCompass1.png'
import leftArrow from '../../Assets/images/Marketcompass/icons/LeftArrow.svg'

const Carosel = ({apiContent,slideIndex,setSlideIndex}) => {
  const sliderref = React.useRef(null);

console.log('apicontent',apiContent)


  var settings = {
    dots: true,
    infinite: true,
    arrows:false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function(i) { 
      return (
        <ul>
          <li style={{width:'10px', height:'10px', borderRadius:'50%', border:'2px solid #ffff', }} key={i} className='dot' >

          </li>
        </ul>
      );
    },
    // slide index to change slide indexing and text after coursel accordingly
    
    afterChange:(index)=>{
      setSlideIndex(index);
    },

  };
 
function handleImageurl(index){
  let InFocusImageUrl='';
  if (
    Object.values(apiContent?.data?.data)[index]?.['market-compass']?.image !== null &&
    Object.values(apiContent?.data?.data)[index]?.['market-compass']?.image.includes('https')
     ) {
    return  InFocusImageUrl=Object.values(apiContent?.data?.data)[index]['market-compass']?.image;
     
  }
  else if(Object.values(apiContent?.data?.data)[index]?.['market-compass']?.image==null || Object.values(apiContent?.data?.data)[index]?.['market-compass']?.image=="" ){
    return InFocusImageUrl='https://navigatecms.s3.ap-southeast-1.amazonaws.com/images/al26VA4GsM1HqpX7xhYQI9Sbh34dR6oOKWbVmP1t.png'
  }
  else{
    
       InFocusImageUrl = 'https://dev-navigate-cms.mwancloud.com/'
       let appendedUrl=Object.values(apiContent?.data?.data)[index]?.['market-compass']?.image;
       let NewUrl=InFocusImageUrl.concat(appendedUrl);
       return NewUrl;
  }

}


  return (
    apiContent?.data?.data!==null && apiContent?.data?.data!==undefined && 
    <div className="Infocus-carosel-wrapper">
      <Slider ref={sliderref} {...settings}>
        <div>
          <img src={handleImageurl(0)}  className="rounded-md w-full h-24 md:h-72 object-cover" />
        </div>
        <div >
        <img src={handleImageurl(1)} className="rounded-md w-full h-24 md:h-72 object-cover"/>
        </div>
        <div >
        <img src={handleImageurl(2)} className="rounded-md w-full h-24 md:h-72 object-cover"/>
        </div>
          
      </Slider>
     
      <div className="w-9/12 md:w-7/12 flex justify-evenly z-20  mx-auto">
      
      <button onClick={() => sliderref?.current?.slickPrev()} className =' w-10 flex justify-center items-center h-7'>
        <img src={leftArrow} width='10' height='10' alt="left arrow"/>
      </button>
    
      <button onClick={() => sliderref?.current?.slickNext()} className ='w-10 flex justify-center items-center h-7'>

      <img src={leftArrow} width='10' height='10' alt="left arrow" className="transform rotate-180"/>
      </button>
      </div>
      
    </div>
    
  );
};

export default Carosel;
