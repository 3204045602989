import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
// svg
import { ReactComponent as Satellite } from '../Assets/images/SateliteLayer_1-2.svg'
import { ReactComponent as VectorUp } from '../Assets/images/Vector5.svg'
import { ReactComponent as VectorDown } from '../Assets/images/Vector6.svg'
import { ReactComponent as Frameicon } from '../Assets/images/Frame.svg'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { accountApi } from './api'
// antd
import { Input, Spin, message, Alert } from 'antd'
import { Button } from 'antd'
import { Avatar } from 'antd'
//css
import '../App.css'
import UserContext from '../store/UserContext'
function handleStatePersist() {
  let firstName = localStorage.getItem('firstName')
  let lastName = localStorage.getItem('lastName')
  let email = localStorage.getItem('email')
  return {
    firstName,
    lastName,
    email
  }
}

const MyAccount = () => {
  const { userState, setUserState } = useContext(UserContext)
  const [error, setError] = useState('')
  const [passwordData, setPassword] = useState({
    password: '',
    confirm: '',
    firstName: handleStatePersist()?.firstName,
    lastName: handleStatePersist().lastName
  })

  const [constData, setConstData] = useState({
    password: '',
    confirm: '',
    firstName: handleStatePersist()?.firstName,
    lastName: handleStatePersist().lastName
  })
  const [updatedData, setUpdate] = useState({})
  const location = useLocation()
  const [loader, setLoader] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();

  const failed = (prop) => {
    messageApi.open({
      type: 'error',
      content: prop ? prop : 'Profile updation failed',
      duration: 5
    });
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Profile Updated',
      duration: 5
    });
  };

  useEffect(() => {
    setTimeout(() => {
      let synth = window.speechSynthesis
      synth.cancel()
    }, 300)
  }, [location])
  const setupdateData = (key, value) => {
    if (value?.trim().length == 0) {
      let mydata = updatedData
      delete mydata[key]
      setUpdate(mydata)
    } else {
      setUpdate({
        ...updatedData,
        [key]: value
      })
    }
  }
  const updateProfile = () => {
    setLoader(true)
    if (passwordData?.firstName?.trim()?.length == 0) {
      setLoader(false)
      setError('Enter Your First Name')
      // failed('Enter Your First Name')
      setTimeout(() => {
        setError('')
      }, 4000)
      return
    }
    if (passwordData?.lastName?.trim()?.length == 0) {
      setError('Enter Your Last Name')
      // failed('Enter Your Last Name')
      setLoader(false)
      setTimeout(() => {
        setError('')
      }, 4000)
      return
    }

    if (passwordData?.password?.trim()?.length != 0) {
      if (passwordData?.password?.trim() != passwordData?.confirm?.trim()) {
        setError('Your Password and Confirm Password is Not Matched')
        // failed('Your Password and Confirm Password is Not Matched')
        setLoader(false)
        setTimeout(() => {
          setError('')
        }, 4000)
        return
      }
    }
    let id = localStorage.getItem('userId')
    let token = localStorage.getItem('token')
    let data = {
      first_name: passwordData?.firstName,
      last_name: passwordData?.lastName,
      id: id
    }
    if (passwordData?.password?.trim()?.length > 0) {
      data['password'] = passwordData?.password
    }
    accountApi?.updateProfile(data, token, (err, response) => {
      setUpdate({})
      if (err == null) {
        localStorage?.setItem('firstName', response?.data?.user?.FIRSTNAME)
        localStorage?.setItem('lastName', response?.data?.user?.LASTNAME)
        setConstData(passwordData)
        setLoader(false)
        // success()
        setError('Profile Updated')
        setUserState((prevUserState) => ({ ...prevUserState, isUpdated: !prevUserState.isUpdated }));
        setTimeout(() => {
          setError('')
        }, 4000)
      } else {
        // failed()
        setLoader(false)
        setError(err?.message)
        setTimeout(() => {
          setError('')
        }, 4000)
      }
    })
  }

  return (
    <>
      {/* vectors background */}


      <div className='my-Account-vector5'>
        <VectorUp />
      </div>
      <div className='my-Account-vector6'>
        <VectorDown />
      </div>
      <div className='myAccount-background-wrapper w-full '>
        <div className='w-full h-40 satelite-banner-div'>
          <Satellite className='opacity-50 w-48 h-auto satelite-pos' />
        </div>
        <div className='my-Account-form-wrapper-parrent w-full h-full'>
          <div className='my-Account-form-wrapper bg-white z-10  rounded '>
            <div className='Avatar-wrapper flex h-15 '>
              <div className='name-container'>
                {constData.firstName?.split('')?.[0]}
                {constData.lastName?.split('')?.[0]}
              </div>
              <p
                style={{ lineHeight: '3.5rem' }}
                className='font-semibold ml-3'
              >
                {constData.firstName + ' '}
                {constData.lastName}
              </p>
            </div>
            <div className='form-wrapper'>
              <form action=''>
                <div className='grid grid-cols-2 gap-4 mt-5'>
                  <div>
                    <label
                      htmlFor='firstname'
                      className='font-semibold text-sm leading-6 form-labels'
                    >
                      First Name
                    </label>
                    <Input
                      onChange={e => {
                        setPassword({
                          ...passwordData,
                          firstName: e?.target?.value
                        })
                        setupdateData(e?.target?.name, e?.target?.value)
                      }}
                      name='firstname'
                      type='text'
                      size='large'
                      className=' mt-2 font-semibold text-base'
                      defaultValue={passwordData.firstName}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='lastname'
                      className='font-semibold text-sm leading-6 form-labels'
                    >
                      Last Name
                    </label>
                    <Input
                      onChange={e => {
                        setPassword({
                          ...passwordData,
                          lastName: e?.target?.value
                        })
                        setupdateData(e?.target?.name, e?.target?.value)
                      }}
                      name='lastname'
                      type='text'
                      autoComplete='no'
                      size='large'
                      className=' mt-2 font-semibold text-base'
                      defaultValue={passwordData.lastName}
                    />
                  </div>
                </div>

                <div className='mt-3'>
                  <label
                    htmlFor='email'
                    className='font-semibold text-sm leading-6 form-labels'
                  >
                    Email
                  </label>
                  <Input
                    type='email'
                    name='email'
                    size='large'
                    className=' mt-2 font-semibold text-base'
                    disabled={true}
                    defaultValue={userState?.email}
                  />
                </div>
                <div className='my-Account-Form-Note w-full mt-4 rounded '>
                  <div className='flex'>
                    <Frameicon className='mr-3' />
                    <p className='text-xs leading-4 font-medium'>
                      If you would like to change the password, type a new one.
                      Otherwise leave this blank
                    </p>
                  </div>
                </div>
                <div className='mt-3'>
                  <label
                    htmlFor='password'
                    className='font-semibold text-sm leading-6 form-labels'
                  >
                    New Password
                  </label>
                  <Input
                    type='password'
                    name='password'
                    size='large'
                    defaultValue={passwordData?.password}
                    onChange={e => {
                      setPassword({
                        ...passwordData,
                        password: e?.target?.value
                      })
                      setupdateData(e?.target?.name, e?.target?.value)
                    }}
                    value={passwordData?.password}
                    className=' mt-2 font-semibold text-base'
                  />
                </div>
                <div className='mt-3'>
                  <label
                    htmlFor='cpassword'
                    className='font-semibold text-sm leading-6 form-labels'
                  >
                    Confirm Password
                  </label>
                  <Input
                    type='password'
                    onChange={e => {
                      setPassword({
                        ...passwordData,
                        confirm: e?.target?.value
                      })
                      setupdateData(e?.target?.name, e?.target?.value)
                    }}
                    name='cpassword'
                    size='large'
                    value={passwordData?.confirm}
                    className=' mt-2 font-semibold text-base'
                  />
                </div>
                {/* <div className='error-msg mt-3' style={{color:error==="Profile Updated" && "#3ab73a",fontSize:'16px'}}>{error}
                {error==="Profile Updated" ? <CheckCircleOutlined className='ml-1'/>:error.length>0 &&<CloseCircleOutlined className='ml-1'/>}
                  </div> */}
                {error.length > 0 ?

                  error === "Profile Updated" ?
                    <Alert message={error} type="success" showIcon closable onClose={() => { setError('') }} className='mt-6' />
                    :
                    <Alert message={error} type="error" showIcon closable onClose={() => { setError('') }} className='mt-6' />
                  :
                  <Button
                    disabled={Object.keys(updatedData)?.length == 0 || loader}
                    onClick={() => {
                      updateProfile()
                    }}
                    block
                    className={`${error.length > 0 ? 'mt-6' : 'mt-6'} font-semibold my-Account-form-button`}
                  >
                    {loader ? <Spin className='accpage-spin' /> : "Update Profile"}
                  </Button>}
                {contextHolder}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyAccount
