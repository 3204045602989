import React, { useEffect, useState } from 'react'
import { Drawer, Button } from 'antd'
import { ReactComponent as LeftArrow } from '../../Assets/images/icons/leftArrow.svg'
import { Line, Column, Treemap } from '@ant-design/plots'
import Graphcomponent from './Graphcomponent'
import { ParseChartData, ResetProp, getDateOfWeek, getMonth, monthArray, setResetValue } from '../../utilities/chartData'

let dynamicChart = -1;
let totalMonths = 0;
let weekIndex = 0;
const ChartDrawer = ({
  placement,
  closable,
  open,
  onClose,
  dataforgrapg,
  Filters,
  loading,
}) => {
  const [zoomedInGraph, setZoomedInGraph] = useState(null)
  const [selectedMillGraph, setSelectedMillGraph] = useState(0)
  const [dataSmoothness, setDataSmoothness] = useState('none')
  const [dataForSesonality, setDataForSesonality] = useState(false)
  const [dataForVesselSesonality, setDataForVesselSesonality] = useState(false)
  const [renderChartForVesselsesonality, setRenderChartForVesselSesonality] = useState(false);
  const [renderChartForsesonality, setRenderChartForSesonality] = useState(false);
  const [selectedPortsGraph, setSelectedPortsGraph] = useState(0)
  const [filtertype, setFiltertype] = useState({
    noOfvessel: 'daily',
    load: 'daily'
  })

  const [loadconfig, SetLoadconfig] = useState({})
  const [vesselconfig, setVesselconfig] = useState({})

  const menuList = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Annually' }
  ]

  const menuList1 = [
    { value: 'none', label: 'None' },
    { value: '7', label: '7 Days moving avg' },
    { value: '14', label: '14 Days moving avg' },
    { value: '30', label: '30 Days moving avg' }
  ]

  useEffect(() => {
    if (filtertype?.load !== 'daily') {
      dataSmoothness !== 'none' && setDataSmoothness('none');
    }
  }, [filtertype])

  const setFilterType = (type, graphType) => {
    if (graphType == 'load') {
      if (type == 'annually') {
        setDataForSesonality(false);
        setFiltertype({
          ...filtertype,
          load: type
        })
        return;
      }
      if (type !== 'weekly') {
        renderChartForsesonality && setRenderChartForSesonality(false);
      }
      setFiltertype({
        ...filtertype,
        load: type
      })
    } else {
      if (type == 'annually') {
        setDataForVesselSesonality(false);
        setFiltertype({
          ...filtertype,
          noOfvessel: type
        })
        return;
      }
      if (type !== 'weekly') {
        renderChartForVesselsesonality && setRenderChartForVesselSesonality(false);
      }
      setFiltertype({
        ...filtertype,
        noOfvessel: type
      })
    }
  }

  useEffect(() => {
    let data = dataforgrapg?.load?.[filtertype?.load];
    if (_.isEmpty(data)) {
      data = {
        cargoLoadDaily: [],
        sesonalityData: []
      }
    }

    if (filtertype?.load == 'daily')
      data = data.cargoLoadDaily?.slice().reverse();
    if (filtertype?.load == 'weekly')
      data = data.cargoLoadWeekly?.slice().reverse();
    if (filtertype?.load == 'monthly')
      data = data.cargoLoadMonthly?.slice().reverse();
    if (filtertype?.load == 'annually')
      data = data?.slice().reverse();

    if (filtertype?.load != 'annually' && dataForSesonality) {
      const d = dataforgrapg?.load?.[filtertype?.load]?.sesonalityData?.slice().reverse();
      const config = filtertype?.load == 'weekly' ? {
        data: d,
        xField: filtertype?.load,
        yField: "load",
        seriesField: 'year',
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
            formatter: (text, list, index) => {
              if (index == 0) {
                weekIndex = 0;
                ResetProp();
              }
              if (dynamicChart !== -1) {
                const val = 11 / (totalMonths - 1) * weekIndex;
                if (Math.round(val) === index) {
                  weekIndex++;
                  return monthArray[weekIndex - 1];
                }

                return null;
              }
              const m = monthArray[index];
              if (setResetValue(m)) {
                return m;
              } else
                return null;
            }
          },
          tickMethod: (cfg) => {
            dynamicChart = -1;
            totalMonths = 0;
            if (cfg.values.length < 52) {
              dynamicChart = cfg.values.length;
              totalMonths = Math.ceil(dynamicChart / 4.19);
            }
            return [];
          },
          ticks: [{ id: "1", name: "Jan", value: 0.001366120218579235 }, { id: "2", name: "Feb", value: 0.09153005464480876 }, { id: "3", name: "Mar", value: 0.1816939890710383 }, { id: "4", name: "Apr", value: 0.2718579234972678 }, { id: "5", name: "May", value: 0.36202185792349734 },
          { id: "6", name: "June", value: 0.4521857923497268 }, { id: "7", name: "July", value: 0.5423497267759563 }, { id: "8", name: "Aug", value: 0.6325136612021858 }, { id: "9", name: "Sep", value: 0.7226775956284154 }, { id: "10", name: "Oct", value: 0.8128415300546449 },
          { id: "11", name: "Nov", value: 0.9030054644808743 }, { id: "12", name: "Dec", value: 0.9931693989071039 }
          ],
        },
        yAxis: {
          label: {
            formatter: (value) => {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
        },
        tooltip: {
          shared: true,
          showCrosshairs: false,
          crosshairs: {
            type: 'x',
          },
          customItems: (originalItems) => {
            const filtered = originalItems.filter(item => !_.isNaN(item.data.load))
            return filtered;

          },
          formatter: (datum) => {
            const formattedValue = datum.load.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return { name: datum.year, value: formattedValue == "NaN" ? "" : formattedValue };
          },
        },
      } : {
        data: d,
        xField: filtertype?.load,
        yField: "load",
        seriesField: 'year',
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
            // offsetX: filtertype?.load == 'weekly' ? 14 : 2,
            formatter: (text, list, index) => {
              if (index == 0)
                ResetProp();
              const m = getMonth(filtertype?.load, text, 2023, index)
              if (setResetValue(m)) {
                return m;
              } else
                return null;
            }
          },
          tickCount: filtertype?.load == 'weekly' ? 13 : filtertype?.load == 'monthly' ? 12 : 12
        },
        yAxis: {
          label: {
            formatter: (value) => {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
        },
        tooltip: {
          shared: true,
          showCrosshairs: false,
          crosshairs: {
            type: 'x',
          },
          customItems: (originalItems) => {
            const filtered = originalItems.filter(item => !_.isNaN(item.data.load))
            return filtered;

          },
          formatter: (datum) => {
            const formattedValue = datum.load.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return { name: datum.year, value: formattedValue == "NaN" ? "" : formattedValue };
          },
        },
      }

      SetLoadconfig(config);
      setRenderChartForSesonality(true)
    } else
      if (dataSmoothness === 'none' || filtertype?.load != 'daily') {
        SetLoadconfig({
          data: data,
          xField: filtertype?.load,
          yField: 'load',
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: true
            },
            tickCount: data?.length
          },
          yAxis: {
            label: {
              formatter: (value) => {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              },
            },
          },
          tooltip: {
            shared: true,
            showCrosshairs: true,
            crosshairs: {
              type: 'x',
            },
            formatter: (datum) => {
              const formattedValue = datum.load.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return { name: filtertype?.load, value: formattedValue };
            },
          },
        })
        setRenderChartForSesonality(false)
      }
      else {
        const result = [];
        const filterType = filtertype?.load

        for (let i = 0; i < data?.length; i++) {
          const load = data[i].load;

          let startIndex = Math.max(0, i - dataSmoothness + 1);
          const endIndex = i + 1;

          const sum = data
            .slice(startIndex, endIndex)
            .reduce((acc, item) => acc + item.load, 0);

          const average = Math.round(sum / (endIndex - startIndex));
          result.push({ load: average, [filterType]: data[i][filterType] });
        }
        SetLoadconfig({
          data: result,
          xField: filtertype?.load,
          yField: 'load',
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: true
            },
            tickCount: result?.length
          },
          yAxis: {
            label: {
              formatter: (value) => {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              },
            },
          },
          tooltip: {
            shared: true,
            showCrosshairs: true,
            crosshairs: {
              type: 'x',
            },
            formatter: (datum) => {
              const formattedValue = datum.load.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return { name: filtertype?.load, value: formattedValue };
            },
          },
        })
        setRenderChartForSesonality(false)
      }

  }, [dataSmoothness, filtertype?.load, dataforgrapg, dataForSesonality])

  useEffect(() => {

    let data = dataforgrapg?.vessel?.[filtertype?.noOfvessel];
    if (_.isEmpty(data)) {
      data = {
        numberOfVesselsdaily: [],
        sesonalityData: []
      }
    }

    if (filtertype?.noOfvessel == 'daily')
      data = data.numberOfVesselsdaily?.slice().reverse();
    if (filtertype?.noOfvessel == 'weekly')
      data = data.numberOfVesselsWeekly?.slice().reverse();
    if (filtertype?.noOfvessel == 'monthly')
      data = data.numberOfVesselsmonthly?.slice().reverse();
    if (filtertype?.noOfvessel == 'annually')
      data = data?.slice().reverse();

    let vesseldata = [];
    vesseldata = data;

    const maxNoOfVessel = Math.ceil(Math.max(...vesseldata?.map(item => item.noOfVessel)));

    const desiredTickCount = 5;
    const tickInterval = Math.ceil(maxNoOfVessel / (desiredTickCount));

    if (filtertype?.noOfvessel != 'annually' && dataForVesselSesonality) {
      const d = dataforgrapg?.vessel?.[filtertype?.noOfvessel]?.sesonalityData?.slice().reverse();
      const config = filtertype?.noOfvessel == 'weekly' ? {
        data: d,
        xField: filtertype?.noOfvessel,
        yField: "noOfVessel",
        seriesField: 'year',
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
            formatter: (text, list, index) => {
              if (index == 0) {
                weekIndex = 0;
                ResetProp();
              }
              if (dynamicChart !== -1) {
                const val = 11 / (totalMonths - 1) * weekIndex;
                if (Math.round(val) === index) {
                  weekIndex++;
                  return monthArray[weekIndex - 1];
                }

                return null;
              }
              const m = monthArray[index];
              if (setResetValue(m)) {
                return m;
              } else
                return null;
            }
          },
          tickMethod: (cfg) => {
            dynamicChart = -1;
            totalMonths = 0;
            if (cfg.values.length < 52) {
              dynamicChart = cfg.values.length;
              totalMonths = Math.ceil(dynamicChart / 4.19);
            }
            return [];
          },
          ticks: [{ id: "1", name: "Jan", value: 0.001366120218579235 }, { id: "2", name: "Feb", value: 0.09153005464480876 }, { id: "3", name: "Mar", value: 0.1816939890710383 }, { id: "4", name: "Apr", value: 0.2718579234972678 }, { id: "5", name: "May", value: 0.36202185792349734 },
          { id: "6", name: "June", value: 0.4521857923497268 }, { id: "7", name: "July", value: 0.5423497267759563 }, { id: "8", name: "Aug", value: 0.6325136612021858 }, { id: "9", name: "Sep", value: 0.7226775956284154 }, { id: "10", name: "Oct", value: 0.8128415300546449 },
          { id: "11", name: "Nov", value: 0.9030054644808743 }, { id: "12", name: "Dec", value: 0.9931693989071039 }
          ],
        },
        yAxis: {
          label: {
            formatter: (value) => {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
        },
        tooltip: {
          shared: true,
          showCrosshairs: false,
          crosshairs: {
            type: 'x',
          },
          customItems: (originalItems) => {
            const filtered = originalItems.filter(item => !_.isNaN(item.data.noOfVessel))
            return filtered;

          },
          formatter: (datum) => {
            const formattedValue = datum.noOfVessel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return { name: datum.year, value: formattedValue == "NaN" ? "" : formattedValue };
          },
        },
      } : {
        data: d,
        xField: filtertype?.noOfvessel,
        yField: "noOfVessel",
        seriesField: 'year',
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
            formatter: (text, list, index) => {
              if (index == 0)
                ResetProp();
              const m = getMonth(filtertype?.noOfvessel, text, 2023, index)
              if (setResetValue(m)) {
                return m;
              } else
                return null;
            }
          },
          tickCount: filtertype?.noOfvessel == 'weekly' ? 13 : filtertype?.noOfvessel == 'monthly' ? 12 : 12
        },
        yAxis: {
          label: {
            formatter: (value) => {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
        },
        tooltip: {
          shared: true,
          showCrosshairs: false,
          crosshairs: {
            type: 'x',
          },
          customItems: (originalItems) => {
            const filtered = originalItems.filter(item => !_.isNaN(item.data.noOfVessel))
            return filtered;

          },
          formatter: (datum) => {
            const formattedValue = datum.noOfVessel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return { name: datum.year, value: formattedValue == "NaN" ? "" : formattedValue };
          },
        },
      }

      setVesselconfig(config);
      setRenderChartForVesselSesonality(true);
    } else {

      const vesselconfig = {
        data: vesseldata,
        xField: filtertype?.noOfvessel,
        yField: 'noOfVessel',

        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true
          }
        },
        yAxis: {
          tickInterval: tickInterval,
        },
        meta: {
          type: {
            alias: '类别'
          },
          sales: {
            alias: '销售额'
          }
        }
      }
      setVesselconfig(vesselconfig);
      setRenderChartForVesselSesonality(false);
    }
  }, [filtertype?.noOfvessel, dataforgrapg, dataForVesselSesonality])


  const milldata = {
    name: 'root',
    children: dataforgrapg?.mills
  }
  const millconfig = {
    data: milldata,
    colorField: 'name',
    tooltip: {
      shared: true,
      showCrosshairs: true,
      crosshairs: {
        type: 'x',
      },
      formatter: (datum) => {
        const formattedValue = datum.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return { name: datum?.name, value: formattedValue };
      },
    },
  }

  const companydata = {
    name: 'root',
    children: dataforgrapg?.company
  }
  const companyconfig = {
    data: companydata,
    colorField: 'name',
    tooltip: {
      shared: true,
      showCrosshairs: true,
      crosshairs: {
        type: 'x',
      },
      formatter: (datum) => {
        const formattedValue = datum.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return { name: datum?.name, value: formattedValue };
      },
    },
  }

  const portdata = {
    name: 'root',
    children: dataforgrapg?.ports
  }
  const portconfig = {
    data: portdata,
    colorField: 'name',
    color: ['#f59dc8', '#657798', '#66d9b6', '#f5be2a', '#9b65be', '#fcebbc', '#89c5c9', '#6385da', '#d7e1ee'],
    tooltip: {
      shared: true,
      showCrosshairs: true,
      crosshairs: {
        type: 'x',
      },
      formatter: (datum) => {
        const formattedValue = datum.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return { name: datum?.name, value: formattedValue };
      },
    },
  }

  const portdata1 = {
    name: 'root',
    children: dataforgrapg?.ports1
  }
  const portconfig1 = {
    data: portdata1,
    colorField: 'name',
    color: ['#f59dc8', '#657798', '#66d9b6', '#f5be2a', '#9b65be', '#fcebbc', '#89c5c9', '#6385da', '#d7e1ee'],
    tooltip: {
      shared: true,
      showCrosshairs: true,
      crosshairs: {
        type: 'x',
      },
      formatter: (datum) => {
        const formattedValue = datum.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return { name: datum?.name, value: formattedValue };
      },
    },
  }

  const setZoomedIn = (type) => {
    setZoomedInGraph(type)
  }

  const handleSesonality = (e, type) => {
    if (type == 'load')
      setDataForSesonality(e);
    if (type == 'vessel')
      setDataForVesselSesonality(e);
  }

  return (
    <>
      <Drawer
        placement={placement}
        closable={closable}
        onClose={onClose}
        open={open}
        width='70%'
        height='70vh'
        className='antd-cutom-drawer overflow-hidden overscroll-none p-10'
      >
        <div
          className='bg-white absolute w-11 transform rotate-180 top-2/4 -left-11  h-12  flex justify-center items-center rounded-tr rounded-br cursor-pointer arrows'
          onClick={onClose}
        >
          <LeftArrow />
        </div>
        {/* {children} */}
        {zoomedInGraph != null ? (
          // Render the graph based on the selected option
          <div className='grid'>
            {<div className='w-full graph-container-zoom'>
              <Graphcomponent
                header={zoomedInGraph === 'load' ? 'Est. Cargo Load Volumes' : zoomedInGraph === 'vessel' ? 'No. of Vessels' : zoomedInGraph === 'buyer' ? (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Buyer' : 'Supplier' : (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Arrival Port' : 'Departure Port'}
                header2={zoomedInGraph === 'buyer' ? (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Supplier' : '' : zoomedInGraph === 'port' ? (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Origin Port' : 'Destination Port' : ''}
                Chart={zoomedInGraph === 'load' ? renderChartForsesonality == dataForSesonality ? <Line {...loadconfig} /> : null : zoomedInGraph === 'vessel' ? renderChartForVesselsesonality ? <Line {...vesselconfig} /> : <Column {...vesselconfig} /> : zoomedInGraph === 'buyer' ? (Filters?.type == 'arrivals' || Filters?.type == 'barges') ? selectedMillGraph === 0 ? <Treemap {...millconfig} /> : <Treemap {...companyconfig} /> : <Treemap {...millconfig} /> : selectedPortsGraph === 0 ? <Treemap {...portconfig} /> : <Treemap {...portconfig1} />}
                menu={zoomedInGraph === 'load' || zoomedInGraph === 'vessel' ? menuList : []}
                menu1={zoomedInGraph === 'load' ? menuList1 : []}
                type={zoomedInGraph}
                setFilterType={zoomedInGraph === 'load' || zoomedInGraph === 'vessel' ? setFilterType : () => { }}
                selectedOption={zoomedInGraph === 'load' ? filtertype.load : filtertype.noOfvessel}
                setZoomedIn={setZoomedIn}
                dataSmoothness={dataSmoothness}
                setDataSmoothness={(e) => { setDataSmoothness(e); dataForSesonality && setDataForSesonality(false) }}
                zoomedInGraph={zoomedInGraph}
                selectedGraph={zoomedInGraph === 'buyer' ? selectedMillGraph : zoomedInGraph === 'port' ? selectedPortsGraph : null}
                setSelectedGraph={zoomedInGraph === 'buyer' ? setSelectedMillGraph : zoomedInGraph === 'port' ? setSelectedPortsGraph : null}
                handleSesonality={handleSesonality}
                dataForSesonality={zoomedInGraph === 'load' ? dataForSesonality : dataForVesselSesonality}
              />
            </div>}
          </div>
        ) : (
          <>
            <div className='grid grid-cols-2 gap-6 mb-8'>
              {loadconfig?.data?.length > 0 ? <div className='w-full h-96 graph-container'>
                <Graphcomponent
                  header={'Est. Cargo Load Volumes '}
                  Chart={renderChartForsesonality == dataForSesonality ? <Line {...loadconfig} /> : null}
                  menu={menuList}
                  menu1={menuList1}
                  type={'load'}
                  setFilterType={setFilterType}
                  selectedOption={filtertype.load}
                  dataSmoothness={dataSmoothness}
                  setDataSmoothness={(e) => { setDataSmoothness(e); dataForSesonality && setDataForSesonality(false) }}
                  handleSesonality={handleSesonality}
                  dataForSesonality={dataForSesonality}
                  setZoomedIn={setZoomedIn}
                  zoomedInGraph={zoomedInGraph}
                  loading={loading}
                ></Graphcomponent>
              </div> :
                <div className='w-full h-96 graph-container'>
                  {' '}
                  <Graphcomponent
                    header={'Est. Cargo Load Volumes '}
                    Chart={<Column {...loadconfig} />}
                    menu={menuList}
                    type={'vessel'}
                    setFilterType={setFilterType}
                    selectedOption={filtertype.noOfvessel}
                    setZoomedIn={setZoomedIn}
                    zoomedInGraph={zoomedInGraph}
                    loading={loading}
                  ></Graphcomponent>
                </div>
              }
              <div className='w-full h-96 graph-container'>
                {' '}
                <Graphcomponent
                  header={'No. of Vessels '}
                  Chart={renderChartForVesselsesonality ? <Line {...vesselconfig} /> : <Column {...vesselconfig} />}
                  menu={menuList}
                  type={'vessel'}
                  setFilterType={setFilterType}
                  selectedOption={filtertype.noOfvessel}
                  setZoomedIn={setZoomedIn}
                  zoomedInGraph={zoomedInGraph}
                  loading={loading}
                  handleSesonality={handleSesonality}
                  dataForSesonality={dataForVesselSesonality}
                ></Graphcomponent>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <div className='w-full h-96 graph-container'>
                <Graphcomponent
                  header={(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Buyer' : 'Supplier'}
                  header2={(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Supplier' : ''}
                  Chart={(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? selectedMillGraph === 0 ? <Treemap {...millconfig} /> : <Treemap {...companyconfig} /> : <Treemap {...millconfig} />}
                  menu={[]}
                  setFilterType={() => { }}
                  setZoomedIn={setZoomedIn}
                  zoomedInGraph={zoomedInGraph}
                  selectedGraph={selectedMillGraph}
                  setSelectedGraph={setSelectedMillGraph}
                  loading={loading}
                  type={'buyer'}
                ></Graphcomponent>
              </div>
              <div className='w-full h-96 graph-container'>
                <Graphcomponent
                  header={(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Arrival Port' : 'Departure Port'}
                  header2={(Filters?.type == 'arrivals' || Filters?.type == 'barges') ? 'Origin Port' : 'Destination Port'}
                  Chart={selectedPortsGraph === 0 ? <Treemap {...portconfig} /> : <Treemap {...portconfig1} />}
                  menu={[]}
                  setFilterType={() => { }}
                  setZoomedIn={setZoomedIn}
                  zoomedInGraph={zoomedInGraph}
                  selectedGraph={selectedPortsGraph}
                  setSelectedGraph={setSelectedPortsGraph}
                  type={'port'}
                  loading={loading}
                ></Graphcomponent>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  )
}

export default ChartDrawer;
