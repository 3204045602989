import React from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
const InFocusLinks = ({ flag, title, description, statedata,warning }) => {
  console.log(statedata)
  return (
    <Link
      to={statedata.access && `/market-compass/${statedata?.title?.replaceAll(' ', '-')}/${
        statedata?.id
      }`}
      //state set
      state={statedata}
      onClick={!statedata.access && warning}
    >
      <span className='rounded-md bg-white h-auto w-auto p-1 pl-3 pr-3 font-semibold'>
        {flag}
      </span>
      <h1 className='text-xl text-white font-semibold leading-6 mt-3  mb-2 capitalize'>
        {title}
      </h1>
      <p className=' text-sm font-normal text-white leading-6'>
        {ReactHtmlParser(description?.replace('- wp:paragraph -->', ''))}...
      </p>
    </Link>
  )
}

export default InFocusLinks
