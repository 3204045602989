import React from 'react'
import './graphcomponent.css'
import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { Select, Button, Spin, Switch } from 'antd'
export default function Graphcomponent({
  header,
  header2,
  dropdown,
  menu,
  Chart,
  selectedOption,
  setFilterType,
  type,
  setZoomedIn,
  zoomedInGraph,
  selectedGraph,
  setSelectedGraph,
  dataSmoothness,
  setDataSmoothness,
  menu1,
  loading,
  dataForSesonality,
  handleSesonality,
}) {
  console.log(type)
  return (
    <div className='main-container'>
      <div className='chart-header'>
        {header2?.length > 0 ?
          <div style={{ display: 'flex', gap: '2px' }}>
            <p style={{ cursor: 'pointer', color: selectedGraph === 0 ? "rgba(0, 113, 193, 1)" : "black" }} onClick={() => { setSelectedGraph(0) }}>{header}</p>
            <p>/</p>
            <p style={{ cursor: 'pointer', color: selectedGraph === 1 ? "rgba(0, 113, 193, 1)" : "black" }} onClick={() => { setSelectedGraph(1) }}>{header2}</p>
          </div>
          :
          <p>{header}</p>
        }
        <div className='right-header'>
          <Button
            className='expendable-btn'
            onClick={() => {
              console.log(zoomedInGraph)
              if (zoomedInGraph == null) {
                setZoomedIn(type)
              }
              else {
                setZoomedIn(null)
              }
            }}
          >
            {zoomedInGraph == null ? <ExpandAltOutlined /> : <ShrinkOutlined />}
          </Button>
        </div>
      </div>
      <div className='down-header'>
        {(menu?.length > 0 && selectedOption !== "annually" && type == 'load' && dataSmoothness === "none") || (menu?.length > 0 && selectedOption !== "annually" && type == 'vessel') ? (
          <div className='flex gap-1'>
            <p>Sesonality</p>
            <Switch style={dataForSesonality ? {} : { backgroundColor: "rgba(0, 0, 0, 0.45)" }} size="small" checked={dataForSesonality} onChange={(e) => handleSesonality(e, type)} />
          </div>
        ) : null}
        {menu1?.length > 0 && selectedOption == "daily" ? (
          <Select
            className={zoomedInGraph != null ? 'avg-select' : 'avg-select-small'}
            defaultValue={dataSmoothness === "none" ? "Data Smoothing" : dataSmoothness}
            style={{ width: zoomedInGraph != null ? 200 : 120 }}
            onChange={e => {
              setDataSmoothness(e)
            }}
            options={menu1}
          />
        ) : null}

        {menu?.length > 0 ? (
          <Select
            defaultValue={selectedOption}
            style={{ width: type === "load" ? selectedOption === "daily" ? zoomedInGraph != null ? 140 : 89 : 140 : 140 }}
            onChange={e => {
              setFilterType(e, type)
            }}
            options={menu}
          />
        ) : null}
      </div>
      {/* <chart /> */}
      <div className={loading ? "graph-section-loading" : zoomedInGraph == null ? 'graph-section' : 'graph-section_zoom'} id='root1'>

        {
          (loading || Chart == null) ?
            <div >
              <Spin tip='' size='large' className='routespin' style={{ zIndex: '1002' }}>
                <div className='content' />
              </Spin>
            </div>
            : Chart.props?.data?.length == 0 || (Chart.props?.data?.children == undefined && (type == 'buyer' || type == 'port')) || Chart.props?.data?.children?.length == 0 ?
              <div className='graphaltdiv'>
                <h1>No Data To Display</h1>
              </div>
              :
              (Chart.props?.data?.length > 0 || Chart.props?.data?.children?.length > 0) && Chart}
      </div>
    </div>
  )
}
