import React from "react";
import { Link,useNavigate } from "react-router-dom";
import "./index.css";

import Arrow from "../../Assets/images/Marketcompass/Tab-arrow.svg";
import {Skeleton} from 'antd'

const TabLinks = ({ title, statedata,loading,warning }) => {
  const navigate = useNavigate();
  return (
    <>
    {
      loading ? <Skeleton.Input  active={true}  size="large" block className="mb-2"/>
      :
      <div className="w-full h-auto tablink-wrapper ">
      <Link
      to={statedata?.access && `/market-compass/${statedata?.title?.replaceAll(" ", "-")}/${statedata?.id}`}
      state={statedata}
      // onClick={()=>navigate()}
    >
      <div className="tablink-header flex" onClick={()=>{!statedata?.access && warning(statedata?.title)}}> 
        <p className=" tablinkbullet flex-1 leading-6">
          
            <div className="tablinkbullet-img float-left ">
              {/* svg img */}
            </div>
         <span className="ml-4 font-medium text-base"> {title}</span>
         
        </p>
        <img src={Arrow} />
      </div>
    </Link>
  
    
  </div>
    }
   
    </>
    
  );
};

export default TabLinks;
