import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import './marketlink.css'

import backgroundVector from '../../Assets/images/Marketcompass/Mcvectorbg.svg'
import ReadIcon from '../../Assets/images/Marketcompass/icons/book-icon.svg'
import PlayIcon from '../../Assets/images/Marketcompass/icons/play-icon.svg'
import PauseIcon from '../../Assets/images/Marketcompass/icons/Pausebtn.svg'
import VolumeIcon from '../../Assets/images/Marketcompass/icons/Volumeicon.svg'

import Disclaimer from '../../Components/Compass/Disclaimer'
import RelatedContent from '../../Components/Compass/RelatedContent'
import ImagePreview from '../../Components/Compass/ImagePreview'
import LeftArrowBlue from '../../Assets/images/Marketcompass/icons/leftArrowBlue.svg'

import parse from 'html-react-parser'
import { ConvertDateTOLocalUtility } from '../../utilities/DateToLocalUtility.js'
import TextToSpeech from '../../Components/Compass/TextToSpeech'
// import pagemap from 'pagemap'

const Mclinkpage = () => {
  const { state } = useLocation()
  const audioPlayerRef = useRef()
  const listenbtnRef = useRef()
  const sourceRef = useRef()
  const [toggelPause, setToggelPause] = useState(false)
  const location = useLocation()
  console.log('state-----------', state)
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    }, 300)
  }, [])
  useEffect(() => {
    setTimeout(() => {
      synth.cancel()
      // listen to content  btn
      let listenbtnele = listenbtnRef.current
      let audioplayerele = audioPlayerRef.current
      listenbtnele.classList.remove('hidden')
      audioplayerele.classList.remove('block')
      audioplayerele.classList.add('hidden')
    }, 300)
  }, [location])

  let supplyimgUrl = ''
  let demandimgUrl = ''
  let headerImage = ''
  if (state?.image !== null && state?.image.includes('https')) {
    headerImage = state?.image
  } else if (state?.image == null || state?.image == '') {
    headerImage =
      'https://navigatecms.s3.ap-southeast-1.amazonaws.com/images/al26VA4GsM1HqpX7xhYQI9Sbh34dR6oOKWbVmP1t.png'
  } else {
    headerImage = 'https://dev-navigate-cms.mwancloud.com/' + state?.image
  }

  if (state?.supply !== null && state?.supply.includes('https')) {
    supplyimgUrl = state?.supply
  } else if (state?.supply == null) {
    supplyimgUrl =
      'https://dev-navigate-cms.mwancloud.com/assets/img/img_placeholder.png'
  } else {
    supplyimgUrl = 'https://dev-navigate-cms.mwancloud.com/' + state?.supply
  }
  //demand
  if (state?.demand !== null && state?.demand.includes('https')) {
    demandimgUrl = state?.demand
  } else if (state?.demand == null) {
    demandimgUrl =
      'https://dev-navigate-cms.mwancloud.com/assets/img/img_placeholder.png'
  } else {
    demandimgUrl = 'https://dev-navigate-cms.mwancloud.com/' + state?.demand
  }
  //  debugger
  console.log(supplyimgUrl, demandimgUrl)
  let synth = window.speechSynthesis
  let utterance = new SpeechSynthesisUtterance(
    state?.content || 'no Content Available '
  )
  let elapsedTime

  function handleListenContent () {
    if ('speechSynthesis' in window) {
      // speechSynthesis.speak(utterance);
      // dom visibility
      let listenbtnele = listenbtnRef.current
      let audioplayerele = audioPlayerRef.current
      let sourceele = sourceRef.current
      listenbtnele.classList.add('hidden')
      audioplayerele.classList.remove('hidden')
      audioplayerele.classList.add('block')

      utterance.onboundary = event => {
        elapsedTime = event.elapsedTime
      }
    } else {
      alert('Speech Synthesis is not Supported 😞 ')
    }
  }
  window.addEventListener('popstate', () => {
    synth.cancel()
    speechSynthesizer.close()
  })

  return (
    <>
      <div className='  lg: py-1 min-h-screen max-h-fit bg-white'>
        <div
          className='
        compass-part'
        >
          <p className='text-sm font-medium mt-5 mb-4'>
            <span className='mr-1'>
              <Link to={-1} onClick={() => synth.cancel()}>
                <img
                  src={LeftArrowBlue}
                  className='inline mr-1'
                  width='11px'
                  height='6px'
                  alt='Back'
                  style={{ height: '10px' }}
                />
                <span style={{ color: '#0071C1' }}>Go Back </span>
              </Link>
            </span>
            /
            <span style={{ color: '#424141' }} className='ml-1'>
              {state?.title}{' '}
            </span>{' '}
          </p>
          <img
            src={headerImage}
            alt='header'
            className='mt-2 '
            style={{ width: '100%' }}
          />
          <h1 className='font-extrabold text-4xl mt-4 capitalize'>
            {/* Market Compass - Ferrous Scrap 13-06-2023 */}
            {state?.title}
          </h1>
          <div
            className='mc-link-article-control-wrapper pt-4 pb-4 mt-4'
            style={{ width: '100%' }}
          >
            <ul className='flex justify-items-start  flex-col gap-4 md:gap-10 lg:flex-row  w-full'>
              <li className='font-medium text-sm '>
                {ConvertDateTOLocalUtility?.convertDateToLocal(state?.date)}
              </li>
              <li className='font-medium text-sm flex '>
                <span className='mr-2 mt-1'>
                  <img src={ReadIcon} alt='read' />
                </span>
                5 min read
              </li>
              <li className='font-medium text-sm text-color-primary w-full md:w-8/12'>
                <button
                  className='flex '
                  ref={listenbtnRef}
                  onClick={() => handleListenContent()}
                >
                  <span className='mr-2'>
                    <img src={PlayIcon} alt='read' />
                  </span>
                  Listen to this content
                </button>
                <figure>
                  <div
                    ref={audioPlayerRef}
                    className='hidden w-full h-7 rounded-3xl pl-3'
                    style={{ background: '#0071C1' }}
                  >
                    <TextToSpeech ref={audioPlayerRef} text={state?.content} />
                  </div>
                </figure>
              </li>
            </ul>
          </div>
          <div className=' grid grid-cols-3 mt-6 gap-x-6'>
            <div className=' p-2 col-span-3 lg:col-span-1 '>
              <div className='sticky top-14 z-10'>
                <p className='font-semibold text-lg  mb-4'>Supply</p>
                <ImagePreview src={supplyimgUrl} />
                <p className='font-semibold text-lg mt-8 mb-4'>Demand</p>
                <ImagePreview src={demandimgUrl} />
                <img
                  src={backgroundVector}
                  alt='background'
                  className='relative -left-2/4 top-20 -z-10 '
                />
              </div>
            </div>
            <div className='p-2 font-medium text-base col-span-3 lg:col-span-2 z-10'>
              <div
                style={{ color: '#424141' }}
                className='text-justify font-medium text-base leading-7'
              >
                {parse(state?.content || 'NO CONTENT AVAILABLE')}
              </div>
            </div>
          </div>

          <Disclaimer disclaimertext={(state?.disclaimer)[0]} />
          <RelatedContent
            relatedData={state?.relatedData}
            disclaimer={state?.disclaimer}
          />
        </div>
      </div>
    </>
  )
}

export default Mclinkpage
