import { useContext } from 'react'
import './App.css'
import { Outlet } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/footer/Footer'
import Router from './Router'
import 'bootstrap-icons/font/bootstrap-icons.css';
import UserContext from './store/UserContext'
import AutoLogout from './Components/AutoLogout'
import LogUserIp from './Components/LogUserIp'
function App() {
  const {
    userState: { isAuth, token, email },
    setUserState
  } = useContext(UserContext)
  const acesstoken = localStorage.getItem('token')
  console.log("Envirnoment variable ", process.env.NODE_ENV)
  return (
    <>
      <div id='App-container' className={`App`}>
        {token !== '' && isAuth ? <Header /> : null}

        {/* <Outlet/> */}
        <AutoLogout> <LogUserIp />  <Router /> </AutoLogout>
      </div>
    </>
  )
}

export default App
