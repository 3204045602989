const validator = require('validator');

export const utilities = {
  checkMail: mail => {
    console.log(mail);

    if (mail.trim()?.length == 0) {
      return 'Please Enter an Email';
    } else if (!validator.isEmail(mail)) {
      return 'Please Enter a Valid Email Address';
    } else {
      return '';
    }
  }
}
