import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import TabLinks from '../../Components/Compass/TabLinks'
import { Pagination } from 'antd'
import UseFetch from '../../Hooks/UseFetch'
import dayjs from 'dayjs'
import axios from '../../axiosconfiguration/axios'

const Mctabs = ({warning}) => {
  const UseFetchListing = UseFetch('/market-compass/listing?limit=6', 'get', '')
  // ferrous scrap state
  const [ferrurl, setferrUrl] = useState('/market-compass/listing?limit=6')
  const [mcTabsdata, setMcTabsdata] = useState({})
  const [ferrcurrent, setFerrCurrent] = useState(null)
  const [ferrtotal, setFerrTotal] = useState(null)
  const [ferrpageSize, setFerrPageSize] = useState(null)
  const [ferrData, setFerrData] = useState(null)
  const [marketcompass, setCompass] = useState({})

  // steel
  const [steelcurrent, setsteelCurrent] = useState(null)

  // iron ore state
  const [ironorecurrent, setIronoreCurrent] = useState(null)

  // uncategorized
  const [uncategorizedcurrent, setUncategorizedCurrent] = useState(null)

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const FerrousPagination = async ferrurl => {
    setLoading(true)
    try {
      const res = await axios.get(ferrurl, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      console.log(res?.data?.data)

      if (res?.data) {
        let Objectdata = {}
        Object?.keys(res?.data?.data)?.map(item => {
          Objectdata[item] = {
            data: res?.data?.data?.[item]?.['market-compass']?.data,
            current_page:
              res?.data?.data?.[item]?.['market-compass']?.current_page,
            total: res?.data?.data?.[item]?.['market-compass']?.total,
            size: res?.data?.data?.[item]?.['market-compass']?.per_page,
            name: res?.data?.data?.[item]?.name,
            lists:res?.data?.data?.[item]?.lists
          }
        })
        console.log(Objectdata)
        setCompass(Objectdata)
        // setMcTabsdata(res?.data)
        // setFerrCurrent(
        //   Object.values(res?.data?.data)[0]?.['market-compass']['current_page']
        // )
        // setFerrTotal(
        //   Object.values(res?.data?.data)[0]?.['market-compass']['total']
        // )
        // setFerrPageSize(
        //   Object.values(res?.data?.data)[0]?.['market-compass']['per_page']
        // )
        // setFerrData(
        //   Object.values(res?.data?.data)[0]?.['market-compass']['data']
        // )

        // setsteelCurrent(
        //   Object.values(res?.data?.data)[1]?.['market-compass']['current_page']
        // )
        // setSteelTotal(
        //   Object.values(res?.data?.data)[1]?.['market-compass']['total']
        // )
        // setSteelPageSize(
        //   Object.values(res?.data?.data)[1]?.['market-compass']['per_page']
        // )
        // setsteelData(
        //   Object.values(res?.data?.data)[1]?.['market-compass']['data']
        // )

        // setIronoreCurrent(
        //   Object.values(res?.data?.data)[2]?.['market-compass']['current_page']
        // )
        // setIronoreTotal(
        //   Object.values(res?.data?.data)[2]?.['market-compass']['total']
        // )
        // setIronorePageSize(
        //   Object.values(res?.data?.data)[2]?.['market-compass']['per_page']
        // )
        // setIronoreData(
        //   Object.values(res?.data?.data)[2]?.['market-compass']['data']
        // )

        // setUncategorizedCurrent(
        //   Object.values(res?.data?.data)[3]?.['market-compass']['current_page']
        // )
        // setUncategorizedTotal(
        //   Object.values(res?.data?.data)[3]?.['market-compass']['total']
        // )
        // setUncategorizedPageSize(
        //   Object.values(res?.data?.data)[3]?.['market-compass']['per_page']
        // )
        // setUncategorizedData(
        //   Object.values(res?.data?.data)[3]?.['market-compass']['data']
        // )

        setLoading(false)
      }
    } catch (error) {
      setError(error.message)
    }
  }

  const tempFunc=(prop)=>{
    console.log(prop)
    warning(prop)
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      FerrousPagination(ferrurl)
      // alert('page change' ,ferrcurrent);
    }
  }, [ferrcurrent, steelcurrent, ironorecurrent, ferrurl])
  console.log('ferrous data', ferrData)
  const handlePaginationChange = page => {
    setFerrCurrent(page)
    setferrUrl(`/market-compass/listing?limit=6&page=${page}`)
  }
  const handleSteelPaginationChange = page => {
    setsteelCurrent(page)
    setferrUrl(`/market-compass/listing?limit=6&page=${page}`)
  }
  const handleIronOrePaginationChange = page => {
    setIronoreCurrent(page)
    setferrUrl(`/market-compass/listing?limit=6&page=${page}`)
  }
  const handleUncategorizedPaginationChange = page => {
    setUncategorizedCurrent(page)
    setferrUrl(`/market-compass/listing?limit=6&page=${page}`)
  }
  let items
  let newItems = []
  if (
    UseFetchListing?.data?.data !== null &&
    UseFetchListing?.data?.data !== undefined &&
    UseFetchListing?.data?.length != 0
  ) {
    let myList = ['steel', 'iron-ore', 'ferrous-scrap', 'coal']

    newItems = myList?.map((object, index) => {
      let currentItem = marketcompass?.[object]
      console.log(currentItem)
      return currentItem?.data?.length < 1
        ? null
        : {
            key: index + 1,
            label: (
              <p className='capitalize font-semibold text-base leading-5'>
                {currentItem?.name}
              </p>
            ),
            children: UseFetchListing?.isPending ? (
              <p>loading....</p>
            ) : (
              <>
                {/* {ferrData !== null && */}
                {currentItem?.data?.map(items => {
                  console.log(items)
                  let trailversion=false

                  const accessLevel =
                  localStorage.getItem('AccessLevelData') == undefined
                    ? []
                    : JSON.parse(localStorage.getItem('AccessLevelData'))
                  const ListId =
                  localStorage.getItem('ListIds') == undefined
                    ? []
                    : JSON.parse(localStorage.getItem('ListIds'))
                  console.log(ListId)
                  const categoryListIds=currentItem?.lists.map(list =>list.list_id);

                  let currentItem1 = accessLevel?.find(i => 6 == i?.id)
                  if (currentItem1?.lists?.length == 0) {
                    trailversion=false
                  }
                  if (
                    accessLevel &&
                    // List Array
                    ListId?.some(ai =>
                      currentItem1?.lists?.map(it => JSON.parse(it))?.includes(ai)
                    )
                  )
                    trailversion=true
                  else
                    trailversion=false

                  let actualAccess=false
                  let access=false
                  categoryListIds.forEach(categoryListId => {
                    if (ListId.includes(categoryListId)) {
                      access = true;
                    }
                  });
                  console.log(access)
                  if(access==true){
                      let blogExpired=false
                      if(trailversion){
                        const lastMonth = dayjs().subtract(1, 'month');
                        const blogDate=dayjs(items?.date)
                        if(blogDate<lastMonth){
                          blogExpired=true
                        }
                      }

                      if(!blogExpired)
                      {       
                        const blogListIds = items.lists.map(list =>list.list_id);
                        if(blogListIds.length>0)
                        {
                          blogListIds.forEach(blogListId => {
                            if (ListId.includes(blogListId)) {
                              actualAccess = true;
                            }
                          });
                        }
                        else
                          actualAccess=true;
                      }
                    }
                    console.log(actualAccess)

                  return (
                    <TabLinks
                      title={items?.title}
                      loading={loading}
                      key={items?.id}
                      warning={(prop)=>{tempFunc(prop)}}
                      statedata={{
                        id: items?.id,
                        title: items?.title,
                        content: items?.content,
                        image: items?.image,
                        supply: items?.supply,
                        demand: items?.demand,
                        date: items?.date,
                        from: currentItem?.name,
                        relatedData: ferrData,
                        disclaimer: UseFetchListing?.data?.data?.disclaimers,
                        access:actualAccess
                      }}
                    />
                  )
                })}
                {currentItem?.total > 1 ? (
                  <div className='tablink-pagination-wrapper '>
                    <Pagination
                      loading={loading}
                      showLessItems={true}
                      showSizeChanger={false}
                      defaultCurrent={1}
                      current={currentItem?.current_page}
                      total={currentItem?.total}
                      pageSize={currentItem?.size}
                      onChange={page => handlePaginationChange(page)}
                      className='tablink-pagination'
                    />
                  </div>
                ) : null}
              </>
            )
          }
    })

    console.log(newItems)

    // items = [
    //   {
    //     key: '1',
    //     label: (
    //       <p className='capitalize font-semibold text-base leading-5'>
    //         {Object.keys(UseFetchListing?.data?.data)[0]?.replaceAll(
    //           '-',
    //           ' '
    //         ) || 'data unavailable'}
    //       </p>
    //     ),
    //     children: UseFetchListing?.isPending ? (
    //       <p>loading....</p>
    //     ) : (
    //       <>
    //         {ferrData !== null &&
    //           ferrData?.map(items => {
    //             return (
    //               <TabLinks
    //                 title={items?.title}
    //                 loading={loading}
    //                 key={items?.id}
    //                 statedata={{
    //                   id: items?.id,
    //                   title: items?.title,
    //                   content: items?.content,
    //                   image: items?.image,
    //                   supply: items?.supply,
    //                   demand: items?.demand,
    //                   date: items?.date,
    //                   from: Object.values(UseFetchListing?.data?.data)[0]?.name,
    //                   relatedData: ferrData,
    //                   disclaimer: UseFetchListing?.data?.data?.disclaimers
    //                 }}
    //               />
    //             )
    //           })}
    //         <div className='tablink-pagination-wrapper '>
    //           <Pagination
    //             loading={loading}
    //             showLessItems={true}
    //             showSizeChanger={false}
    //             defaultCurrent={1}
    //             current={ferrcurrent}
    //             total={ferrtotal}
    //             pageSize={ferrpageSize}
    //             onChange={page => handlePaginationChange(page)}
    //             className='tablink-pagination'
    //           />
    //         </div>
    //       </>
    //     )
    //   },
    //   {
    //     key: '2',
    //     label:
    //       UseFetchListing?.data !== null ? (
    //         <p className='capitalize font-semibold text-base leading-5'>
    //           {Object.keys(UseFetchListing?.data?.data)[1]?.replaceAll(
    //             '-',
    //             ' '
    //           )}
    //         </p>
    //       ) : (
    //         ' data unavailable'
    //       ),
    //     children: (
    //       <>
    //         {steelData !== null &&
    //           steelData?.map(items => {
    //             return (
    //               <TabLinks
    //                 title={items?.title}
    //                 loading={loading}
    //                 key={items?.id}
    //                 statedata={{
    //                   id: items?.id,
    //                   title: items?.title,
    //                   content: items?.content,
    //                   image: items?.image,
    //                   supply: items?.supply,
    //                   demand: items?.demand,
    //                   date: items?.date,
    //                   from: Object.values(UseFetchListing?.data?.data)[1]?.name,
    //                   relatedData: steelData,
    //                   disclaimer: UseFetchListing?.data?.data?.disclaimers
    //                 }}
    //               />
    //             )
    //           })}
    //         <div className='tablink-pagination-wrapper'>
    //           <Pagination
    //             showLessItems={true}
    //             showSizeChanger={false}
    //             current={steelcurrent}
    //             defaultCurrent={1}
    //             total={steeltotal}
    //             pageSize={steelpageSize}
    //             onChange={handleSteelPaginationChange}
    //             className='tablink-pagination'
    //           />
    //         </div>
    //       </>
    //     )
    //   },
    //   {
    //     key: '3',
    //     label:
    //       UseFetchListing?.data !== null ? (
    //         <p className='capitalize font-semibold text-base leading-5'>
    //           {Object.keys(UseFetchListing?.data?.data)[2]?.replaceAll(
    //             '-',
    //             ' '
    //           )}
    //         </p>
    //       ) : (
    //         ' data unavailable'
    //       ),
    //     children: (
    //       <>
    //         {ironoreData !== null &&
    //           ironoreData?.map(items => {
    //             return (
    //               <TabLinks
    //                 title={items?.title}
    //                 loading={loading}
    //                 key={items?.id}
    //                 statedata={{
    //                   id: items?.id,
    //                   title: items?.title,
    //                   content: items?.content,
    //                   image: items?.image,
    //                   supply: items?.supply,
    //                   demand: items?.demand,
    //                   date: items?.date,
    //                   from: Object.values(UseFetchListing?.data?.data)[2]?.name,
    //                   relatedData: ironoreData,
    //                   disclaimer: UseFetchListing?.data?.data?.disclaimers
    //                 }}
    //               />
    //             )
    //           })}
    //         <div className='tablink-pagination-wrapper'>
    //           <Pagination
    //             showLessItems={true}
    //             showSizeChanger={false}
    //             defaultCurrent={1}
    //             current={ironorecurrent}
    //             total={ironoretotal}
    //             pageSize={ironorepageSize}
    //             onChange={handleIronOrePaginationChange}
    //             className='tablink-pagination'
    //           />
    //         </div>
    //       </>
    //     )
    //   },
    //   {
    //     key: '4',
    //     label:
    //       UseFetchListing?.data !== null ? (
    //         <p className='capitalize font-semibold text-base leading-5'>
    //           {Object.keys(UseFetchListing?.data?.data)[3]?.replaceAll(
    //             '-',
    //             ' '
    //           )}
    //         </p>
    //       ) : (
    //         ' data unavailable'
    //       ),
    //     children: (
    //       <>
    //         {uncategorizedData !== null &&
    //           uncategorizedData?.map(items => {
    //             return (
    //               <TabLinks
    //                 title={items?.title}
    //                 loading={loading}
    //                 key={items?.id}
    //                 statedata={{
    //                   id: items?.id,
    //                   title: items?.title,
    //                   content: items?.content,
    //                   image: items?.image,
    //                   supply: items?.supply,
    //                   demand: items?.demand,
    //                   date: items?.date,
    //                   from: Object.values(UseFetchListing?.data?.data)[3]?.name,
    //                   relatedData: uncategorizedData,
    //                   disclaimer: UseFetchListing?.data?.data?.disclaimers
    //                 }}
    //               />
    //             )
    //           })}
    //         <div className='tablink-pagination-wrapper'>
    //           <Pagination
    //             showLessItems={true}
    //             showSizeChanger={false}
    //             defaultCurrent={1}
    //             current={ironorecurrent}
    //             total={ironoretotal}
    //             pageSize={ironorepageSize}
    //             onChange={handleUncategorizedPaginationChange}
    //             className='tablink-pagination'
    //           />
    //         </div>
    //       </>
    //     )
    //   }
    // ]

    // console.log(items)
  }

  return (
    <>
      <div className='p-8 mt-32 w-full'>
        <Tabs
          defaultActiveKey='1'
          items={newItems}
          onChange={() => {
            setferrUrl(`/market-compass/listing?limit=6&page=1`)
          }}
        />
      </div>
    </>
  )
}

export default Mctabs
