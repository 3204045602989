import { Pagination } from "antd"

export const ProPagination = ({ currentPage, pageSize, TotalPage, loading, onPaginationChange }) => {
    return (
        <>
            <Pagination
                className='p-4 text-center bg-white'
                defaultCurrent={1}
                current={currentPage}
                pageSize={pageSize}
                total={TotalPage}
                showSizeChanger={true}
                loading={loading}
                onChange={(page, pageSize) => {
                    onPaginationChange(page, pageSize)
                }}
                onShowSizeChange={(current, size) => { }}
            />
        </>
    )
}   