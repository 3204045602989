import React,{useEffect} from "react";
import InteractiveMap from "../Components/IntereactiveDir/InteractiveMap";
import { useLocation } from "react-router-dom";
const InteractiveDirectory = () => {
  const location = useLocation();
useEffect(() => {
  setTimeout(() => {
    let synth=window.speechSynthesis;
    synth.cancel();
  }, 300);
   
}, [location]);
  return (
    <>
    <InteractiveMap/>
    </>
  );
};

export default InteractiveDirectory;
