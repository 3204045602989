import React from 'react'
import arrowRightvector  from '../../Assets/images/Marketcompass/VectorExpand.svg'
import RelatedContentimg from '../../Assets/images/Marketcompass/MC-img.png'
import { Link } from 'react-router-dom'

const RelatedContent = ({relatedData,stateData,disclaimer}) => {
    const relatedContent=[
        {
            img:RelatedContentimg,
            title:"Market Compass - Ferrous Scrap 13-06-2023",
        },
        {
            img:RelatedContentimg,
            title:"Market Compass - Ferrous Scrap 13-06-2023",
        },
        {
            img:RelatedContentimg,
            title:"Market Compass - Ferrous Scrap 13-06-2023",
        },
        {
            img:RelatedContentimg,
            title:"Market Compass - Ferrous Scrap 13-06-2023",
        }
    ]
  return (
   <>
   {relatedData?.length > 0 ? <div className='related-content-wrapper py-4'>
       <div className='flex justify-between'>
        <p className='font-semibold  text-2xl'>Related Content</p>
        <Link className='text-color-primary font-semibold ' to={-1}>
            <p className='leading-8 text-base flex'>
            Show all 
            <span className='-rotate-90 ml-4'><img src={arrowRightvector}/></span>
            </p>
        </Link>
       </div>

       <div className='flex flex-col lg:flex-row w-full mt-6'>

        {/* {
            relatedContent?.map(({img,title})=>{
                return <div key={title} className="w-60 h-auto m-auto lg:mr-10 cursor-pointer">
                    <img src={img} className='w-full h-36 object-cover rounded'/>
                    <p className='font-semibold text-base mt-2'>{title}</p>
                </div>
            })
        } */}
         {
            relatedData?.slice(2,6)?.map(({image,title,id,content,supply,demand,date})=>{
                let relatedDataImage='';
                if (
                   image !== null &&
                   image.includes('https')
                    ) {
                        relatedDataImage=image;
                    
                 }
                 else if(image==null ){
                    relatedDataImage='https://dev-navigate-cms.mwancloud.com/assets/img/img_placeholder.png'
                 }
                 else{
                    relatedDataImage='https://dev-navigate-cms.mwancloud.com/'+image;
                 }
                return <div key={title} className="w-60 h-auto m-auto lg:mr-10 cursor-pointer">
                     <Link  to={`/market-compass/${title?.replaceAll(" ", "-")}/${id}`} replace  state={  
                 {
                  id,
                  title,
                  content,
                  image,
                  supply,
                  demand,
                  date,
                 relatedData,
                 disclaimer

                }
                } onClick={()=>window?.scrollTo({top:0,behavior:"instant"})} >
                        <img src={relatedDataImage} className='w-full h-36 object-cover rounded'/>
                        <p className='font-semibold text-base mt-2'>{title?.slice(0,58)}</p>
                    </Link>
                </div>
              
            })
        }
        
       </div>
   </div>:null}
   </>
  )
}

export default RelatedContent