import { Button, Dropdown, Space, Carousel, Input, Spin, message, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import { ReactComponent as SearchMenu } from "../../Assets/images/icons/searchMenu.svg"
import { ReactComponent as Tick } from "../../Assets/images/icons/tick.svg"
import { ReactComponent as Cross } from "../../Assets/images/icons/cross.svg"
import { ReactComponent as DeleteAll } from "../../Assets/images/icons/deleteAll.svg"
import { ReactComponent as MessageHistory } from "../../Assets/images/icons/message-history.svg"
import "./style.css"
import ModalDialog from '../common/ModalDialog';
import { useEffect, useState } from 'react';
import { mapFilterObject } from '../../utilities/filtersUtil';
import { EmptySearch } from './EmptySearch';
export const SavedFilter = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [operation, setOperation] = useState(0);
    const [selectedSearch, setSelectedSearch] = useState({});
    const [clickedSearch, setClickedSearch] = useState({});
    const [searchCount, setSearchCount] = useState({ savedCount: 0, historyCount: 0 });
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        if (clickedSearch && loading) {
            messageApi.open({
                key: 'delete',
                type: 'success',
                className: 'saved-loader',
                content: operation == 1 ? 'Search Saved' : 'Search Deleted!',
                duration: 2,
            });
        }
        setClickedSearch({})
        setLoading(false)
    }, [props.savedSearches])

    const items = [
        {
            label: 'Rename',
            key: '1',
            // icon: <SearchOutlined />,
        },
        {
            label: 'Delete',
            key: '2',
            // icon: <SearchOutlined />,
        },
    ];
    const menuProps = {
        items,
        // onClick: (e) => { props.onSearchMenuClick(e.key, e.item.props.search) },
        onClick: (e) => {
            setOperation(e.key);
            setSelectedSearch(e.item.props.search);
            e.key == 2 && setIsModalOpen(true)
        },
    };

    useEffect(() => {
        let tempSelectedSearch = {};
        const searchesCount = { savedCount: 0, historyCount: 0 }
        props.savedSearches.forEach(element => {
            if (element.save_serach == 1)
                searchesCount.savedCount = searchesCount.savedCount + 1;
            else {
                if (searchesCount.historyCount == 0)
                    tempSelectedSearch = element;
                searchesCount.historyCount = searchesCount.historyCount + 1;
            }
        });
        if (props.isSavedFilterCall) {
            setOperation(1);
            setSelectedSearch(tempSelectedSearch);
            props.setIsSavedFilterCall(false);
        }
        setSearchCount(searchesCount);
    }, [props.savedSearches])
    const saveSearch = (operation, selectedSearch) => {
        setLoading(true);
        let showError = false;
        props.savedSearches.map((search) => {
            if (search.save_serach == 1 && selectedSearch.title == search.title) {
                showError = true;
            }
        })
        if (showError) {
            messageApi.open({
                key: 'delete',
                type: 'error',
                className: 'duplicate-search',
                content: 'Name already exists',
                duration: 2,
            });
            setLoading(false);
            return;
        }
        props.onSearchMenuClick(operation, selectedSearch);
        setSelectedSearch({});
    }
    return (
        <>
            {(searchCount.savedCount == 0 && props.searchType == 1) || (searchCount.historyCount == 0 && props.searchType == 0) ?
                <EmptySearch searchType={props.searchType} /> :
                <>
                    {contextHolder}
                    <ModalDialog width={300} filterName={selectedSearch.title} operation={operation} isModalOpen={isModalOpen} handleCancel={() => { setIsModalOpen(false); }}
                        handleOk={(title) => {
                            if (operation == 1)
                                selectedSearch.title = title;
                            props.onSearchMenuClick(operation, selectedSearch);
                            setIsModalOpen(false)
                            setLoading(true);
                        }} />
                    {props.searchType == 0 && <div className='history-message'>
                        <div> <MessageHistory /></div>
                        <p>Click on “Rename & Save" to quickly save your search. It goes to "Saved Searches" tab</p>
                    </div>}
                    <div style={{ display: "flex", flexDirection: "column", position: "relative", marginTop: "10px" }} >
                        {
                            loading ?
                                <div style={{ zIndex: 55, position: "absolute", top: 0, left: 0, display: 'flex', justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }} >
                                    <Spin tip='' size='medium' ></Spin>
                                </div>
                                : null
                        }

                        <div className='manage-searches-header' style={{ position: "absolute", top: 0, opacity: loading ? 0.3 : 1, left: 0, zIndex: 1 }} >
                            <label className="font-semibold text-neutral-800">{`${props.searchType == 1 ? 'Saved Searches' : 'Search History'} (${props.searchType == 1 ? searchCount.savedCount : searchCount.historyCount})`} </label>
                            <div className='delete-search'>
                                <div className='delete-search-icon'> <DeleteAll /></div>
                                <Popconfirm
                                    icon={<></>}
                                    placement="topRight"
                                    className='pop-confirm'
                                    title="Delete Search History"
                                    description={<div>Are you sure to <b style={{ color: "#ED5050" }}>Delete</b> the entire Search History?</div>}
                                    onConfirm={() => { props.onDeleteAllSearches(props.searchType == 1 ? 'saved' : 'filter'); setLoading(true); }}
                                    onCancel={() => { }}
                                    okText="Yes, Delete it"
                                    cancelText="No"
                                >
                                    < Button type='link' className='delete-searches-button' >{props.searchType == 1 ? 'Delete Saved Searches' : "Delete Search History"}</Button>
                                </Popconfirm>
                            </div>
                        </div>
                        {
                            props.savedSearches.length > 0 ?
                                <div className={props.searchType == 1 ? 'searches-list-saved' : 'searches-list-history'} style={{ opacity: loading ? 0.3 : 1, marginTop: "35px" }} >
                                    {props.savedSearches.map((search) => (
                                        <>
                                            {search.save_serach == props.searchType ?
                                                <div key={search.id} className={`${clickedSearch?.id == search.id ? "saved-filter-crousel-border" : ""} saved-filter-crousel flex flex-col mb-2 px-3.5 pt-2 pb-5 w-full bg-white rounded-md border border-solid border-sky-600 border-opacity-20`}>
                                                    <div className="flex gap-5 justify-between w-full font-semibold text-sky-600">
                                                        <div className="flex gap-1 my-auto">
                                                            {(selectedSearch.id !== search.id || operation == 2) ? <> <SearchOutlined /> <p style={{ padding: "0px" }} type='link'>{search.title ?? "Filter " + search.id}  </p> </> :
                                                                <>
                                                                    <Input placeholder="Unsaved Filter" value={selectedSearch.title} onChange={(e) => { setSelectedSearch({ ...selectedSearch, title: e.target.value }) }} />
                                                                    <Button className="tick-all-searches" onClick={() => { saveSearch(operation, selectedSearch); }} icon={<Tick />}></Button>
                                                                    <Button className="cross-all-searches" onClick={() => { setSelectedSearch({}) }} icon={<Cross />}></Button>
                                                                </>
                                                            }
                                                        </div>
                                                        <div style={{ display: "inline-block", margin: "2px" }}>
                                                            <Dropdown placement='bottomRight' menu={{ ...menuProps, style: { width: "170px" }, items: [{ label: props.searchType == 0 ? 'Rename & Save' : "Rename", search: search, key: 1 }, { label: 'Delete', search: search, key: 2 }] }} >
                                                                <Button style={{ borderRadius: "50%", height: "24px", width: "24px", padding: "0px" }}>
                                                                    <Space>
                                                                        <SearchMenu />
                                                                    </Space>
                                                                </Button>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <p className="mt-2 font-medium leading-5 text-neutral-700">
                                                        {Object.values(mapFilterObject(props.filtersdata, search)).toString().slice(0, 40)
                                                        }
                                                    </p>
                                                </div> : null}</>))}
                                </div> :
                                <div className="flex flex-col px-3.5 pt-2 pb-5 mt-3.5 w-full bg-white rounded-md border border-solid border-sky-600 border-opacity-20" >
                                    <div className="flex gap-5 justify-between w-full font-semibold text-sky-600">
                                        <label>No Saved Filter</label>
                                    </div>
                                </div>
                        }

                    </div>
                </>
            }
        </>
    )
}