export const ConvertDateTOLocalUtility = {

  convertDateToLocal: date => {
    var nameofMonth = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const localDate = new Date(Date.parse(date))
    const month = nameofMonth[localDate?.getMonth()]
    const year = localDate?.getFullYear()
    const day = localDate?.getDate()
    return day + '-' + month + '-' + year
  },
  convertDateToLinear: date => {
    const localDate = new Date(Date.parse(date))
    const month = localDate?.getMonth();
    const year = localDate?.getFullYear()
    const day = localDate?.getDate()
    return day + '-' + month + '-' + year
  }
}

export const NavigateMarineCategories = [{
  id: 2,
  value: "ferrousScrap",
  label: "Ferrous Scrap"
},
{
  id: 15,
  value: "merchantPigIron",
  label: "Merchant Pig Iron"
},
{
  id: 14,
  value: "driHbi",
  label: "DRI/HBI"
},
{
  id: 17,
  value: "ironOre",
  label: "Iron Ore"
},
]

//table cols
export const defaultShipmentTableColForPigIron = [
  {
    title: 'Timestamp UTC',
    dataIndex: 'timestamp',
    width: 150
  },
  {
    title: 'Ship Name',
    dataIndex: 'shipname',
    width: 150
  },
  {
    title: 'Ship Type',
    dataIndex: 'shiptype',
    width: 150
  },
  {
    title: 'Vessel Class',
    dataIndex: 'size_class',
    width: 150
  },
  {
    title: 'DWT',
    dataIndex: 'dwt',
    width: 150
  },
  {
    title: 'Move type',
    dataIndex: 'movetype',
    width: 150
  },
  {
    title: 'Estimated Cargo Load (Tons)',
    dataIndex: 'totalcargoload',
    width: 150
  },
  {
    title: 'Mill',
    dataIndex: 'mill',
    width: 150
  },
  {
    title: 'Port Name',
    dataIndex: 'portname',
    width: 150
  },
  {
    title: 'Port Name',
    dataIndex: 'previous_port_name',
    width: 150
  },
  {
    title: 'Destination Berth',
    dataIndex: 'destberth',
    width: 150
  },
  {
    title: 'Destination Country',
    dataIndex: 'destcountry',
    width: 150
  },
  {
    title: 'Company',
    dataIndex: 'company',
    width: 150
  },
  {
    title: 'Origin Port',
    dataIndex: 'originport',
    width: 150
  },
  {
    title: 'Origin Berth',
    dataIndex: 'originberth',
    width: 150
  },
  {
    title: 'Co Loading Port',
    dataIndex: 'coloadingportcompany',
    width: 150
  },
  {
    title: 'Origin Country',
    dataIndex: 'origincountry',
    width: 150
  }
]
