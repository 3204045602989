import { Modal } from 'antd'
export const WarningModal = text => {
  Modal.warning({
    title: <p className='text-xl'>Access Denied</p>,
    content: (
      <div>
        <p>
          We're sorry, but you don’t have permission to access <b>“{text}”</b>
        </p>
        <br />
        <p>
          We'd love to grant you access! Simply send us a request, and we'll
          take care of the rest. <br />{' '}
          <a
            className='text-sky-700'
            href='mailto:support@navigatecommodities.com'
          >
            support@navigatecommodities.com
          </a>{' '}
        </p>
      </div>
    ),
    okText: 'Got it',
    bodyStyle: {
      borderRadius: '0px !important'
    }
  })
}