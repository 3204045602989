import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import FiltersDrawer from '../Components/FiltersDrawer'
import axios from '../axiosconfiguration/axios'
import DataTable from '../Components/DragableSortTable'
import { Button, Modal } from 'antd'
import ChartDrawer from '../Components/Companies/ChartDrawer'
import { UpOutlined } from '@ant-design/icons'
import { allData, dataForGraph } from '../utilities/chartData'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { checkBargesAccessLevel } from '../utilities/utils'


//table cols
const tablecolumns = [
  {
    title: 'Timestamp UTC',
    dataIndex: 'timestamp',
    width: 150
  },
  {
    title: 'Ship Name',
    dataIndex: 'shipname',
    width: 150
  },
  {
    title: 'Ship Type',
    dataIndex: 'shiptype',
    width: 150
  },
  {
    title: 'Vessel Class',
    dataIndex: 'size_class',
    width: 150
  },
  {
    title: 'DWT',
    dataIndex: 'dwt',
    width: 150
  },
  {
    title: 'Move type',
    dataIndex: 'movetype',
    width: 150
  },
  {
    title: 'Estimated Cargo Load (Tons)',
    dataIndex: 'totalcargoload',
    width: 150
  },
  {
    title: 'Mill',
    dataIndex: 'mill',
    width: 150
  },
  {
    title: 'Port Name',
    dataIndex: 'portname',
    width: 150
  },
  {
    title: 'Port Name',
    dataIndex: 'previous_port_name',
    width: 150
  },
  {
    title: 'Destination Berth',
    dataIndex: 'destberth',
    width: 150
  },
  {
    title: 'Destination Country',
    dataIndex: 'destcountry',
    width: 150
  },
  {
    title: 'Company',
    dataIndex: 'company',
    width: 150
  },
  {
    title: 'Origin Port',
    dataIndex: 'originport',
    width: 150
  },
  {
    title: 'Origin Berth',
    dataIndex: 'originberth',
    width: 150
  },
  {
    title: 'Co Loading Port',
    dataIndex: 'coloadingportcompany',
    width: 150
  },
  {
    title: 'Origin Country',
    dataIndex: 'origincountry',
    width: 150
  }
]

const dataType = {
  isArrival: 1,
  isShipment: 2,
  isloading: 3,
  isBarges: 4,
  inTransit: 5,
};
const Fixtures = ({ comodity }) => {
  const [dataSource, setDataSource] = useState([])
  // const [fixtureFilterMetaData, setFixtureFilterMetaData] = useState({
  //   ata_utc_start: '',
  //   ata_utc_end: '',
  //   ship_name: '',
  //   ship_type: '',
  //   dwt_start: '',
  //   dwt_end: '',
  //   total_cargo_load_start: '',
  //   total_cargo_load_end: '',
  //   dest_country: '',
  //   actual_dest: '',
  //   mill: '',
  //   country: '',
  //   previous_port_name: '',
  //   company: '',
  //   co_load: ''
  // })
  // const [shipmentFilterMetaData, setShipmentFilterMetaData] = useState({
  //   timestamp_utc_start: '',
  //   timestamp_utc_end: '',
  //   ship_name: '',
  //   ship_type: '',
  //   dwt_start: '',
  //   dwt_end: '',
  //   total_cargo_load_start: '',
  //   total_cargo_load_end: '',
  //   reported_eta_start: '',
  //   reported_eta_end: '',
  //   country: '',
  //   port_name: '',
  //   company: '',
  //   co_load: '',
  //   dest_country: '',
  //   destination: '',
  //   loading_rate_start: '',
  //   loading_rate_end: ''
  // })

  // const [loadingFilterMetaData, setLoadingFilterMetaData] = useState({
  //   timestamp_utc_start: '',
  //   timestamp_utc_end: '',
  //   arrival_Time: '',
  //   ship_name: '',
  //   ship_type: '',
  //   dwt: '',
  //   move_type: '',
  //   supplier: '',
  //   load_country: '',
  //   load_port: '',
  //   load_berth: '',
  //   co_load: '',
  //   berthing_time: '',
  //   prev_country: '',
  //   prev_port: '',
  //   prev_leg_time: ''
  // })

  const [appliedFilter, setAppliedFilter] = useState(false)
  const [dataSourceType, setDataSourceType] = useState('arrival')
  const [shipmentFilterUpdated, setSetShipmentFiltersdata] = useState(null)

  const [bordered] = useState(true)
  // const [loading, setLoading] = useState(false);
  const [size] = useState('small')
  const [bottom, setBottom] = useState('bottomCenter')
  const [open, setOpen] = useState(false)
  const [columns, setColumns] = useState(tablecolumns)
  const [trailversion, setTrail] = useState(true)
  const [trailCalculated, setTrialCalculated] = useState(false)
  const [current_page, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [totalLoad, setTotalLoad] = useState({
    arrivals: 0,
    shipment: 0
  })

  const [loadingCount, setLoadingCount] = useState(0)
  const [arrivcount, setArrivalCount] = useState(0)
  const [shipcount, setShipCount] = useState(0)
  const [bargesCount, setBargesCount] = useState(0)
  const [intransitcount, setIntransitCount] = useState(0)

  const [defaultShipCount, setDefaultShipCount] = useState(0)
  const [defaultIntransitcount, setDefaultIntransitCount] = useState(0)
  const [defaultArrivCount, setDefaultArrivalCount] = useState(0)
  const [defaultLoadingCount, setDefaultLoadingCount] = useState(0)
  const [defaultBargesCount, setDefaultBargesCount] = useState(0)


  const [totalEntries, setTotalEntries] = useState(0)
  const [applyFilter, setFilterApply] = useState(false)
  const navigate = useNavigate()
  const [defaultArrivalFilter, setDefaultArrivalFilter] = useState({})
  const [defaultShipmentFilter, setDefaultShipmentFilter] = useState({})
  const [defaultLoadingFilter, setDefaultLoadingFilter] = useState({})
  const [defaultBargesFilter, setDefaultBargesFilter] = useState({})
  const [defaultIntransitFilter, setDefaultIntransitFilter] = useState({})

  const [openChart, setOpenChart] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(-1);
  const company = useSelector(state => state.company);
  const [isSavedFilterCall, setIsSavedFilterCall] = useState(false);
  const [bargesAccessLevel, setBargesAccessLevel] = useState(false);

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      let synth = window.speechSynthesis
      synth.cancel()
    }, 300)
  }, [location])
  // drawer functions

  // const warning = text => {
  //   Modal.warning({
  //     title: <p className='text-xl'>Access Denied</p>,
  //     content: (
  //       <div>
  //         <p>
  //           We're sorry, but you don’t have permission to access <b>“{text}”</b>
  //         </p>
  //         <br />
  //         <p>
  //           We'd love to grant you access! Simply send us a request, and we'll
  //           take care of the rest. <br />{' '}
  //           <a
  //             className='text-sky-700'
  //             href='mailto:support@navigatecommodities.com'
  //           >
  //             support@navigatecommodities.com
  //           </a>{' '}
  //         </p>
  //       </div>
  //     ),
  //     okText: 'Got it',
  //     bodyStyle: {
  //       borderRadius: '0px !important'
  //     }
  //   })
  // }

  useEffect(() => {
    setBargesAccessLevel(checkBargesAccessLevel());
    const ListId =
      localStorage.getItem('ListIds') == undefined
        ? []
        : JSON.parse(localStorage.getItem('ListIds'))
    const accessLevel =
      localStorage.getItem('AccessLevelData') == undefined
        ? []
        : JSON.parse(localStorage.getItem('AccessLevelData'))
    console.log(accessLevel)
    const user = JSON.parse(localStorage.getItem('navigateUser'))

    let currentItem = accessLevel?.find(i => 2 == i?.id)
    console.log(user)
    console.log(currentItem)
    if (!currentItem || currentItem?.lists?.length == 0) {
      console.log("noaccess")
      return
    }
    if (
      accessLevel &&
      // List Array
      ListId?.some(ai =>
        currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
      )
    ) {
      let currentItem = accessLevel?.find(i => 6 == i?.id)
      if (currentItem?.lists?.length == 0) {
        {
          setTrail(false)
          setTrialCalculated(true)
        }
      }
      if (
        accessLevel &&
        // List Array
        ListId?.some(ai =>
          currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
        )
      ) {
        setTrail(true)
        setTrialCalculated(true)
      }
      else {
        setTrail(false)
        setTrialCalculated(true)
      }
    }
  }, [])

  useEffect(() => {
    if (trailCalculated) {
      var url = null
      console.log(trailversion)
      if (trailversion) {
        if (dataSourceType == 'arrival') {
          url = `/shipData/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`
        } else if (dataSourceType == 'loading') {
          url = `/shipData/fixtures/filter/loading?limit=${pageSize}&page=${1}`
        } else if (dataSourceType == 'departure' || dataSourceType == 'shipment') {
          url = `/shipData/fixtures/filter/shipments?limit=${pageSize}&page=${1}`
        } else if (dataSourceType == 'barges') {
          url = `/barges/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`
        }
        const today = new Date().toISOString().split('T')[0];

        const oneMonthPrior = new Date();
        oneMonthPrior.setMonth(oneMonthPrior.getMonth() - 1);
        const oneMonthPriorFormatted = oneMonthPrior.toISOString().split('T')[0];
        const filtersobj = {
          date: [oneMonthPriorFormatted, today],
          ata_utc_start: oneMonthPriorFormatted,
          ata_utc_end: today,
        }
        const filtersobj2 = {
          date: [oneMonthPriorFormatted, today],
          timestamp_utc_start: oneMonthPriorFormatted,
          timestamp_utc_end: today,
        }

        const filtersobj3 = {
          date: [oneMonthPriorFormatted, today],
          timestamp_utc_start: oneMonthPriorFormatted,
          timestamp_utc_end: today,
        }

        getfixturesFilterData(url, filtersobj)
        allFixtureLimit2(filtersobj, filtersobj2, filtersobj3)
        setDefaultArrivalFilter(filtersobj)
        setDefaultShipmentFilter(filtersobj2)
        setDefaultLoadingFilter(filtersobj3)
        setDefaultBargesFilter(filtersobj);
        setDefaultIntransitFilter(filtersobj2);
      }
      else {
        if (dataSourceType == 'arrival') {
          url = `/shipData/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
        }
        else if (dataSourceType == 'loading') {
          url = `/shipData/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${1}`
        } else if (dataSourceType == 'departure' || dataSourceType == 'shipment') {
          url = `/shipData/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${1}`
        } else if (dataSourceType == 'barges') {
          url = `/barges/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
        }

        getfixturesData(url)
        allFixtureLimit()
      }
    }
  }, [trailCalculated])

  useEffect(() => {
    if (chartLoading && openChart && isRefresh === -1) {
      setIsRefresh(0);
    }
    if (chartLoading && openChart && isRefresh === 0) {
      setIsRefresh(1);
    }
    if (!chartLoading) {
      setIsRefresh(-1);
    }
  }, [isRefresh, chartLoading, openChart])
  console.log("IsRefresh", isRefresh)

  const showDrawer = url => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const checkIfAllDataAlreadyFetch = (type) => {
    const comodityAllData = allData[comodity];
    if (comodityAllData.arrival && type === dataType.isArrival) {
      setGraphData(dataForGraph(comodityAllData.arrival, company, _.cloneDeep(graphData)));
      return true;
    }
    if (comodityAllData.shipment && type === dataType.isShipment) {
      setGraphData(dataForGraph(comodityAllData.shipment, company, _.cloneDeep(graphData)));
      return true;
    }
    if (comodityAllData.barges && type === dataType.isBarges) {
      setGraphData(dataForGraph(comodityAllData.barges, company, _.cloneDeep(graphData), 'barges'));
      return true;
    }
    return false;
  }

  const setDataForGraph = (url, filter) => {

    if (filter) {
      axios
        .post(
          url, filter,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(data => {
          setGraphData(dataForGraph(data, company, _.cloneDeep(graphData), url.includes("barges") ? 'barges' : null));
          setChartLoading(false);
        })
        .catch(err => {
          setChartLoading(false);
        })
    } else {
      let type = 0;
      if (url.includes("barges")) {
        type = dataType.isBarges;
        if (checkIfAllDataAlreadyFetch(type)) {
          setChartLoading(false);
          return;
        }
      } else if (url.includes("arrival")) {
        type = dataType.isArrival;
        if (checkIfAllDataAlreadyFetch(type)) {
          setChartLoading(false);
          return;
        }
      } else if (url.includes("shipment")) {
        type = dataType.isShipment;
        if (checkIfAllDataAlreadyFetch(type)) {
          setChartLoading(false);
          return;
        }
      }

      axios
        .get(
          url,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(data => {
          if (type === dataType.isArrival)
            allData[comodity].arrival = data;
          else if (type === dataType.isShipment)
            allData[comodity].shipment = data;
          else if (type === dataType.isBarges)
            allData[comodity].barges = data;

          setGraphData(dataForGraph(data, company, _.cloneDeep(graphData), type == dataType.isBarges ? 'barges' : null));
          setChartLoading(false);
        })
        .catch(err => {
          setChartLoading(false);
        })
    }

  }
  const allFixtureLimit = () => {
    axios
      .get(
        `/shipData/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        setArrivalCount(data?.data?.meta?.total)
        setDefaultArrivalCount(data?.data?.meta?.total);
      })
      .catch(err => { })
    axios
      .get(
        `/shipData/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setShipCount(data?.data?.meta?.total)
        setDefaultShipCount(data?.data?.meta?.total)
      })
      .catch(err => { })

    axios
      .get(
        `/shipData/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setLoadingCount(data?.data?.meta?.total)
        setDefaultLoadingCount(data?.data?.meta?.total)
      })
      .catch(err => { })
    axios
      .get(
        `/barges/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setBargesCount(data?.data?.meta?.total)
        setDefaultBargesCount(data?.data?.meta?.total)
      })
      .catch(err => { })

    axios
      .get(
        `/intransit/fixtures/data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setIntransitCount(data?.data?.meta?.total)
        setDefaultIntransitCount(data?.data?.meta?.total)
      })
      .catch(err => { })
  }

  const allFixtureLimit2 = (filters, filters2, filters3) => {
    axios
      .post(
        `/shipData/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`, filters,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data)
        setArrivalCount(data?.data?.meta?.total)
        setDefaultArrivalCount(data?.data?.meta?.total)
      })
      .catch(err => { })
    axios
      .post(
        `/shipData/fixtures/filter/shipments?limit=${pageSize}&page=${1}`, filters2,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setShipCount(data?.data?.meta?.total)
        setDefaultShipCount(data?.data?.meta?.total)
      })
      .catch(err => { })

    axios
      .post(
        `/shipData/fixtures/filter/loading?limit=${pageSize}&page=${1}`, filters3,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setLoadingCount(data?.data?.meta?.total)
        setDefaultLoadingCount(data?.data?.meta?.total)
      })
      .catch(err => { })

    axios
      .post(
        `/barges/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`, filters,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setBargesCount(data?.data?.meta?.total)
        setDefaultBargesCount(data?.data?.meta?.total)
      })
      .catch(err => { })

    axios
      .post(
        `/intransit/fixtures/filter/data?limit=${pageSize}&page=${1}`, filters,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        setIntransitCount(data?.data?.meta?.total)
        setDefaultIntransitCount(data?.data?.meta?.total)
      })
      .catch(err => { })
  }


  const getfixturesData = async url => {
    setLoading(true)
    setChartLoading(true);
    //
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      console.log(url)

      if (
        response?.data
        // && response?.data?.status!='failed'
      ) {
        console.log('fixtureResponse', response?.data?.meta)
        console.log(response?.data?.data)
        setTotalEntries(response?.data?.meta?.total)
        const data = response?.data?.data?.map(item => {
          let newdate = item?.ata_utc?.replace('-', '/')
          let date = new Date(newdate)
          let day =
            date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate()
          let month =
            date?.getMonth() < 10 ? `0${date?.getMonth()}` : date?.getMonth()
          let year = date?.getFullYear()
          return {
            ...item,
            ata_utc: `${day}-${month}-${year}`
          }
        })
        setDataSource(response?.data?.data)
        setCurrentPage(response?.data?.meta?.current_page)
        setTotalPage(response?.data?.meta?.total)
        // setPageSize(response?.data?.meta?.per_page)
        if (url?.includes('barges')) {
          setBargesCount(response?.data?.meta?.total)
        } else if (url?.includes('arrival-data')) {
          setArrivalCount(response?.data?.meta?.total)
        } else if (url?.includes('loading-data')) {
          setLoadingCount(response?.data?.meta?.total)
        } else if (url?.includes('shipment-data')) {
          setShipCount(response?.data?.meta?.total)
        } else if (url?.includes('intransit')) {
          setIntransitCount(response?.data?.meta?.total)
        }

        setLoading(false)
      } else {
        // alert(response?.data?.message);
        // setUserState({isAuth:false });
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
    if (!url?.includes('loading-data') && !url?.includes('intransit')) {
      const parseUrl = url.split('?')[0];
      setDataForGraph(parseUrl.concat("?limit=1000000&page=1"));
    }
  }

  const saveFilterCall = async (url, filtersData) => {
    setLoading(true)
    try {
      const response = await axios.post(url, filtersData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response?.data) {
        setIsSavedFilterCall(true);
      }
      setLoading(false)
    } catch (err) {
      console.log("Save filter call failed")
      setLoading(false)
    }
  }
  const getfixturesFilterData = async (url, filtersData) => {
    setLoading(true)
    setChartLoading(true);
    try {
      const response = await axios.post(url, filtersData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (
        response?.data
        // && response?.data?.status!='failed'
      ) {
        console.log('fixtureResponse', response?.data?.meta)
        setTotalEntries(response?.data?.meta?.total)
        const data = response?.data?.data?.map(item => {
          let newdate = item?.ata_utc?.replace('-', '/')
          let date = new Date(newdate)
          let day =
            date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate()
          let month =
            date?.getMonth() < 10 ? `0${date?.getMonth()}` : date?.getMonth()
          let year = date?.getFullYear()
          return {
            ...item,
            ata_utc: `${day}-${month}-${year}`
          }
        })
        console.log(response?.data?.data)
        setDataSource(response?.data?.data)
        setCurrentPage(response?.data?.meta?.current_page)
        setTotalPage(response?.data?.meta?.total)
        // setPageSize(response?.data?.meta?.per_page)
        if (url?.includes('barges')) {
          setBargesCount(response?.data?.meta?.total)
        } else if (url?.includes('arrival')) {
          setArrivalCount(response?.data?.meta?.total)
        } else if (url?.includes('loading')) {
          setLoadingCount(response?.data?.meta?.total)
        } else if (url?.includes('shipments')) {
          setShipCount(response?.data?.meta?.total)
        } else if (url?.includes('intransit')) {
          setIntransitCount(response?.data?.meta?.total)
        }
        setLoading(false)
      } else {
        // alert(response?.data?.message);
        // setUserState({isAuth:false });
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
    if (!url?.includes('loading') && !url?.includes('intransit')) {
      const parseUrl = url.split('?')[0];
      if (filtersData.save_search)
        delete filtersData["save_search"]
      setDataForGraph(parseUrl.concat("?limit=1000000&page=1"), filtersData);
    }
  }
  // table props
  const tableProps = {
    bordered,
    // loading,
    size,
    bottom
  }

  // const ApplyFiters = () => {}
  // clearFilter = () => {}
  const handleResize =
    index =>
      (_, { size }) => {
        const newColumns = [...columns]
        newColumns[index] = {
          ...newColumns[index],
          width: size.width
        }
        setColumns(newColumns)
      }

  // const mergeColumns = columns.map((col, index) => ({
  //   ...col,
  //   onHeaderCell: column => ({
  //     width: column.width,
  //     onResize: handleResize(index)
  //   })
  // }))
  // const setPageNumber = () => { }
  console.log('filter Applied', appliedFilter)
  // console.log(dataSource)
  return (
    <>
      <div className='fixtures-page-wrapper w-full'>

        <FiltersDrawer
          placement='left'
          closable={false}
          onClose={onClose}
          trailversion={trailversion}
          setFilterApply={setFilterApply}
          open={open}
          // fixtureFilterMetaData={fixtureFilterMetaData}
          // setFixtureFilterMetaData={setFixtureFilterMetaData}
          // shipmentFilterMetaData={shipmentFilterMetaData}
          // setShipmentFilterMetaData={setShipmentFilterMetaData}
          // appliedFilter={appliedFilter}
          setAppliedFilter={setAppliedFilter}
          dataSourceType={dataSourceType}
          getfixturesData={getfixturesData}
          getfixturesFilterDatas={getfixturesFilterData}
          setSetShipmentFiltersdata={setSetShipmentFiltersdata}
          shipmentFilterUpdated={shipmentFilterUpdated}
          pageSizes={pageSize}
          defaultArrivalFilter={defaultArrivalFilter}
          defaultShipmentFilter={defaultShipmentFilter}
          defaultLoadingFilter={defaultLoadingFilter}
          defaultBargesFilter={defaultBargesFilter}
          defaultIntransitFilter={defaultIntransitFilter}
          comodity={'shipData'}
          dataSource={dataSource}
          loadings={loading}
          saveFilterCall={saveFilterCall}
          isSavedFilterCall={isSavedFilterCall}
          setIsSavedFilterCall={setIsSavedFilterCall}
        />

        <div className=' antd-table-custom'>
          <DataTable
            showDrawer={showDrawer}
            setFilterApply={setFilterApply}
            dataSource={dataSource}
            applyFilter={applyFilter}
            dataSourceType={dataSourceType}
            setDataSourceType={setDataSourceType}
            appliedFilter={appliedFilter}
            current_page={current_page}
            pageSizes={pageSize}
            totalPage={totalPage}
            setAppliedFilter={setAppliedFilter}
            getfixturesDatas={getfixturesData}
            trailversion={trailversion}
            getfixturesFilterDatas={getfixturesFilterData}
            loadings={loading}
            setLoadings={setLoading}
            arrivcount={arrivcount}
            shipcount={shipcount}
            intransitcount={intransitcount}
            loadingCount={loadingCount}
            bargesCount={bargesCount}
            defaultBargesCount={defaultBargesCount}
            defaultShipCount={defaultShipCount}
            defaultIntransitcount={defaultIntransitcount}
            defaultArrivCount={defaultArrivCount}
            defaultLoadingCount={defaultLoadingCount}
            setPageSize1={setPageSize}
            shipmentFilterUpdated={shipmentFilterUpdated}
            setSetShipmentFiltersdata={setSetShipmentFiltersdata}
            totalEntries={totalEntries}
            defaultArrivalFilter={defaultArrivalFilter}
            defaultShipmentFilter={defaultShipmentFilter}
            defaultLoadingFilter={defaultLoadingFilter}
            defaultBargesFilter={defaultBargesFilter}
            defaultIntransitFilter={defaultIntransitFilter}
            bargesAccessLevel={bargesAccessLevel}
          />
        </div>
        {dataSourceType !== "loading" && dataSourceType !== "intransit" ?
          <Button
            style={{ right: "-3rem" }}
            className='absolute top-2/4 z-10 transform -rotate-90 pb-3 h-auto font-medium'
            type='primary'
            onClick={() => { setOpenChart(true) }}>
            View Charts
            <UpOutlined />
          </Button> : null}
      </div>
      {(dataSourceType !== "loading" && dataSourceType !== "intransit" && isRefresh !== 0) ?
        <ChartDrawer
          placement='right'
          closable={false}
          onClose={() => { setOpenChart(false) }}
          open={openChart}
          dataforgrapg={graphData}
          Filters={{
            type: dataSourceType == "arrival" ? "arrivals" : dataSourceType
          }}
          loading={chartLoading}
        /> : null
      }
    </>
  )
}

export default Fixtures
