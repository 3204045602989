import axios from '../axiosconfiguration/axios'
export const accountApi = {
  updateProfile: (data, token, cb) => {
    axios
      .post('/profile', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(data => {
        cb(null, data)
      })
      .catch(err => {
        cb(err, data)
      })
  }
}
