import React from 'react'
import { Image,Button } from "antd";
import expandIcon from "../../Assets/images/Marketcompass/icons/expand-icon.svg"


const ImagePreview = ({src}) => {
    const [visible, setVisible] =React.useState(false);

  return (
   <>
    <Image
                width={"100%"}
                height={"218px"}
                src={src}
                preview={{
                  visible,
                  onVisibleChange: (value) => {
                    setVisible(value);
                  },
                }}
              />
              <Button  className='relative -top-12 rounded-full p-2 bg-slate-300' style={{left:'88%'}} onClick={() => setVisible(true)}>
                <img src={expandIcon} />
             </Button>
   
   </>
  )
}

export default ImagePreview