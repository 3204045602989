import { React, useEffect, useState } from 'react'
import axios from '../axiosconfiguration/axios'

const UseFetch = (endpoint, request, body) => {
  const [data, setData] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)
  const acesstoken = localStorage.getItem('token')

  useEffect(() => {
    if (request == 'post') {
      publicPostRequest(endpoint, body)
    } else {
      publicGetRequest(endpoint, body)
    }
    return () => {
      // if (request == 'post') {
      //   publicPostRequest(endpoint, body)
      // } else {
      //   publicGetRequest(endpoint, body)
      // }
    }
  }, [endpoint])

  const publicPostRequest = async (endpoint, body) => {
    try {
      const response = await axios.post(endpoint, body, {
        headers: { Authorization: `Bearer ${acesstoken}` }
      })
      if (response?.data) {
        setData(response?.data?.data)
        console.log('response', data)
      }
    } catch (error) {
      setError(error?.message)
    }
  }
  // get
  const publicGetRequest = async endpoint => {
    try {
      setIsPending(true)
      const response = await axios.get(
        endpoint,
        { headers: { Authorization: `Bearer ${acesstoken}` } },
        body
      )
      if (response?.data) {
        setData(response?.data)
        setIsPending(false)
      }
    } catch (error) {
      setError(error?.message)
    }
  }

  return { data, isPending, error }
}

export default UseFetch
