import { React, useState, useEffect } from 'react'
import { Select } from 'antd'
import { Cascader, Button } from 'antd'
import CapacitySlider from './CapacitySlider'

import UseFetch from '../../Hooks/UseFetch'
import axios from '../../axiosconfiguration/axios'

const Dropdowns = ({
  maxGroupCapacity,
  setMaxGroupCapacity,
  filterMetaData,
  setFilterMetaData
}) => {
  const { data, isPending, error } = UseFetch(
    'interactive-directories/get-countries',
    'get',
    ''
  )
  const [country, setCountry] = useState(null)
  const [countryState, setCountryState] = useState(null)
  const [ports, setPorts] = useState(null)
  const [selectedPort, setSelectedPort] = useState(null)
  const [materials, setMaterials] = useState(null)
  const [selectedMaterial, setSelectedMaterial] = useState(null)
  const [equipments, setEquipments] = useState(null)
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [groupCapacity, setGroupCapacity] = useState(0)
  const [Error, setError] = useState(null)

  const acesstoken = localStorage.getItem('token')
  console.log('Group Capacity state', groupCapacity)
  console.log('ports state', ports)

  useEffect(() => {
    let mounted = true
    if (
      mounted &&
      country !== null &&
      countryState !== null &&
      countryState !== '' &&
      country !== ''
    ) {
      getPorts()
      getMaterials()
      getEquipment()
    }
    // else{
    //   setPorts(null)
    // }
    return () => (mounted = false)
  }, [country, countryState])

  // useEffect(()=>{
  //   getGroupCapacity();
  // },[])

  const getPorts = async () => {
    try {
      const response = await axios.post(
        'interactive-directories/get-ports',

        {
          country: country,
          state: countryState
        },
        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )
      if (response?.data) {
        setPorts(new Set(response?.data?.data))
      }
    } catch (error) {
      setError(error.message)
    }
  }
  const getMaterials = async () => {
    try {
      const response = await axios.get(
        'interactive-directories/get-materials',

        { headers: { Authorization: `Bearer ${acesstoken}` } }
      )

      if (response?.data) {
        setMaterials(new Set(response?.data?.data))
      }
    } catch (error) {
      setError(error.message)
    }
  }
  const getEquipment = async () => {
    try {
      const response = await axios.post(
        'interactive-directories/get-equipments',

        {
          country: country
        },
        {
          headers: { Authorization: `Bearer ${acesstoken}` }
        }
      )
      if (response?.data) {
        console.log('equipment', response?.data?.data)
        setEquipments(new Set(response?.data?.data))
      }
    } catch (error) {
      setError(error.message)
    }
  }

  return (
    <div className='flex justify-between w-full bg-black  bg-opacity-70 interactived-dropdowns-wrapper'>
      <Cascader
        // defaultValue="Country"
        allowClear
        //showSearch
        value={country}
        style={{
          width: 245
        }}
        onChange={value => {
          if (value !== undefined) {
            setCountry(value[0])
            setCountryState(value[1])
            setFilterMetaData({
              ...filterMetaData,
              country: value[0],
              countryState: value[1]
            })
          } else {
            setCountry('')
            setCountryState('')
            setFilterMetaData({
              ...filterMetaData,
              country: country, //refactor issue 1
              countryState: countryState
            })
          }
        }}
        options={
          data?.data &&
          Object.keys(data?.data).map(country => {
            return {
              value: country,
              label: country,
              children:
                country &&
                data?.data?.[`${country}`].map(cstate => {
                  return { value: cstate, label: cstate }
                })
            }
          })
        }
        placeholder='Country'
      />

      <Select
        // defaultValue="Port"
        //showSearch
        // allowClear
        style={{
          width: 245
        }}
        value={selectedPort}
        onChange={value => {
          setSelectedPort(value)
          setFilterMetaData({
            ...filterMetaData,
            port: value
          })
        }}
        options={
          ports !== undefined &&
          ports !== null &&
          Array.from(ports)?.map(port => {
            return { value: port, label: port }
          })
        }
        placeholder='Port'
      />
      <Select
        // defaultValue="Materials"
        //showSearch
        // allowClear
        value={selectedMaterial}
        style={{
          width: 245
        }}
        onChange={value => {
          setSelectedMaterial(value)
          setFilterMetaData({
            ...filterMetaData,
            material: value
          })
        }}
        options={
          materials !== undefined &&
          materials !== null &&
          Array.from(materials)?.map(material => {
            return { value: material, label: material }
          })
        }
        placeholder='Materials'
      />
      <Select
        // defaultValue="Equipments"
        // allowClear
        value={selectedEquipment}
        //showSearch
        style={{
          width: 245
        }}
        onChange={value => {
          setSelectedEquipment(value)
          setFilterMetaData({
            ...filterMetaData,
            equipment: value
          })
        }}
        options={
          equipments !== undefined &&
          equipments !== null &&
          Array.from(equipments)?.map(equipment => {
            return { value: equipment, label: equipment }
          })
        }
        placeholder='Equipments'
      />
      <CapacitySlider
        groupCapacity={groupCapacity}
        maxCapacity={maxGroupCapacity}
        setGroupCapacity={setGroupCapacity}
        filterMetaData={filterMetaData}
        setFilterMetaData={setFilterMetaData}
        widthHeight='pt-1'
      />
      <Button
        type='primary'
        className='company-reset font-medium text-sm'
        onClick={() => {
          setCountry('')
          setCountryState('')
          setGroupCapacity(0)
          setSelectedEquipment(null)
          setSelectedPort(null)
          setSelectedMaterial(null)
          setFilterMetaData({
            ...filterMetaData,
            country: '',
            countryState: '',
            port: '',
            material: '',
            equipment: '',
            gcapacity: ''
          })
        }}
      >
        Reset Filter
      </Button>
    </div>
  )
}

export default Dropdowns
