import { message } from 'antd'
import React from 'react'
import axios from '../axiosconfiguration/axios'

const UseFetchAxios = () => {
  const [data, setData] = React.useState(null)
  const [isPending, setIsPending] = React.useState(true)
  const [error, setError] = React.useState(null)
const acesstoken= localStorage.getItem('token')

  const apiCallGet = async (endpoint, cb) => {
    try {
      const res = await axios.get(endpoint,{ headers: {"Authorization" : `Bearer ${acesstoken}`} })
      console.log(res)
      if (res.status == 200) {
        cb(null, res?.data?.data)
        setData(res?.data?.data)
      }
    } catch (err) {
      cb(err, null)
      setError(err)
    }
  }
  const apiCallPost = async (endpoint, body, cb) => {
    try {
      const res = await axios.post(endpoint,body, { withCredentials: false })
      console.log("api login",res?.response?.data?.message)
      if (res?.data) {
        cb(null, res?.data)
        setData(res?.data?.data)
      }
      else{
        cb(res, null)
      }
    } catch (err) {
      // cb(err?.message, null)
      setError(err?.message)
    }
  }
  return { apiCallGet, apiCallPost }
}

export default UseFetchAxios
