import React from "react";
import Slider from "react-slick";
import Mcimg from "../../Assets/images/Marketcompass/MC-img.png";
import leftArrow from '../../Assets/images/Marketcompass/icons/LeftArrow.svg'
import { Link } from "react-router-dom";
import UseFetch from "../../Hooks/UseFetch";

const MediaCarosel = () => {
  const fetchMediaCoverage = UseFetch('/website/media-coverages','get','');
  if(fetchMediaCoverage)
  {
    console.log("Media COverage data",fetchMediaCoverage?.data)
  }
  const Mediasliderref = React.useRef(null);


  var settings = {
    dots: true,
    dotsClass: "slick-dots-custom slick-thumb slick-dots",
    infinite: false,
    arrows:false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    customPaging: function(i) { 
      return (
        <ul>
          <li style={{width:'10px', height:'10px', borderRadius:'50%', border:'2px solid #0071C1', }} key={i} className='Mediadot' >

          </li>
        </ul>
      );},
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
       
        }
      }
    ]
  };
  
  
  return (
    <div>
      <Slider ref={Mediasliderref} {...settings}>
         {fetchMediaCoverage?.data?.data && fetchMediaCoverage?.data?.data.map(({ date, title ,image,video_url}) => {
           let imgUrl='';
           if (image !== null && typeof image === "string" && image.includes('https')) {
             imgUrl=image;
           }
           else if(image==null){
            imgUrl='https://navigatecms.s3.ap-southeast-1.amazonaws.com/images/LLfdRzt9SNSsMcxuyPOJSEYGD5tGsvkR6H39JKoh.jpg'
           }
           else{
            imgUrl='https://dev-navigate-cms.mwancloud.com/'+image;
            
           }
          return (
            <Link to={video_url!==null?video_url:'https://navigate-web.sys.mwancloud.com/media-coverage'} className="cursor-pointer" target={"_blank"}>
            <div className="p-3 slider-slick-item-wrapper" key={title}>
              <div className=" w-3/6 float-left h-full flex items-center justify-center">
                <img src={imgUrl} className="rounded" width="132px" height="112px" alt="media"/>
              </div>
              <div className="h-full flex items-center p-2">
                <div>
                  <p
                    className="text-sm font-normal"
                    style={{ color: "#424141" }}
                  >
                    {date}
                  </p>
                  <p className="text-base font-semibold leading-5">{title?.slice(0,58)}...</p>
                </div>
              </div>
            </div>
            </Link>
          );
        })}
      </Slider>
      <div className="w-full  flex justify-between  mx-auto absolute top-2/4 -z-10000">
      
      <button onClick={() => Mediasliderref?.current?.slickPrev()} className ='mediaArrows relative -left-4 rounded-full  flex justify-center items-center '>
        <img src={leftArrow} width='10' height='10' alt="left arrow"/>
      </button>
    
      <button onClick={() => Mediasliderref?.current?.slickNext()} className =' mediaArrows relative -right-4 rounded-full flex justify-center items-center '>
      <img src={leftArrow} width='10' height='10' alt="left arrow" className="transform rotate-180"/>
      </button>
      </div>
    </div>
  );
};

export default MediaCarosel;
