import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import FiltersDrawer from '../Components/FiltersDrawer'
import axios from '../axiosconfiguration/axios'
import DataTable from '../Components/DragableSortTable/DataTable'
import { Button, Modal } from 'antd'
import ChartDrawer from '../Components/Companies/ChartDrawer'
import { UpOutlined } from '@ant-design/icons'
import { allData, dataForGraph } from '../utilities/chartData'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { WarningModal } from '../Components/Misc/WarningModal'
import { ProPagination } from '../Components/ProPagination'
import { DRIHBIDataColumns, IronOreDataColumns, MerchantDataColumns, getUrlToDownloadData, getUrlToshowHeader } from '../utilities/utils'

const Comodoties = ({ defaultColumns, comodity }) => {
  const [dataSource, setDataSource] = useState([])
  const [dataSourceType, setDataSourceType] = useState('arrival')
  const [columns, setColumns] = useState(defaultColumns);
  const [isSavedFilterCall, setIsSavedFilterCall] = useState(false);
  const [tableHeader, setTableHeader] = useState({
    arrival: {
      name: "arrival",
      label: "Arrivals",
      count: 0,
      defaultCount: 0,
      isSelected: true,
      isFilterApplied: false
    },
    intransit: {
      name: "intransit",
      label: "In Transit",
      count: 0,
      defaultCount: 0,
      isSelected: false,
      isFilterApplied: false
    },
    shipment: {
      name: "shipment",
      label: "Shipments",
      count: 0,
      defaultCount: 0,
      isSelected: false,
      isFilterApplied: false
    }
  }
  )
  useEffect(() => {
    if (comodity == 'ironore') {
      const ironOreTableHeader = tableHeader;
      ironOreTableHeader.loading = {
        name: "loading",
        label: "Live Loading",
        count: 0,
        defaultCount: 0,
        isSelected: false,
        isFilterApplied: false
      },
        setTableHeader(ironOreTableHeader);
    }
  }, [comodity])

  const [appliedFilter, setAppliedFilter] = useState(false)
  // const [dataSourceType, setDataSourceType] = useState('arrival')
  const [shipmentFilterUpdated, setSetShipmentFiltersdata] = useState(null)

  const [bordered] = useState(true)
  // const [loading, setLoading] = useState(false);
  const [size] = useState('small')
  const [bottom, setBottom] = useState('bottomCenter')
  const [open, setOpen] = useState(false)

  const [trailversion, setTrail] = useState(true)
  const [trailCalculated, setTrialCalculated] = useState(false)
  const [current_page, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [totalLoad, setTotalLoad] = useState({
    arrivals: 0,
    shipment: 0
  })

  const [defaultShipCount, setDefaultShipCount] = useState(0)
  const [defaultLoadingCount, setDefaultLoadingCount] = useState(0)

  const [defaultIntransitCount, setDefaultIntransitCount] = useState(0)
  const [defaultArrivCount, setDefaultArrivalCount] = useState(0)



  const [totalEntries, setTotalEntries] = useState(0)
  const [applyFilter, setFilterApply] = useState(false)
  const navigate = useNavigate()
  const [defaultArrivalFilter, setDefaultArrivalFilter] = useState({})
  const [defaultShipmentFilter, setDefaultShipmentFilter] = useState({})
  const [defaultIntransitFilter, setDefaultIntransitFilter] = useState({})
  const [openChart, setOpenChart] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(-1);
  const company = useSelector(state => state.company)
  const [defaultLoadingFilter, setDefaultLoadingFilter] = useState({})

  const location = useLocation()

  useEffect(() => {
    tableHeader[dataSourceType].isFilterApplied = appliedFilter;
    setTableHeader(tableHeader);

  }, [appliedFilter])

  useEffect(() => {
    setTimeout(() => {
      let synth = window.speechSynthesis
      synth.cancel()
    }, 300)
  }, [location])
  // drawer functions

  useEffect(() => {
    const ListId =
      localStorage.getItem('ListIds') == undefined
        ? []
        : JSON.parse(localStorage.getItem('ListIds'))
    const accessLevel =
      localStorage.getItem('AccessLevelData') == undefined
        ? []
        : JSON.parse(localStorage.getItem('AccessLevelData'))
    console.log(accessLevel)
    const user = JSON.parse(localStorage.getItem('navigateUser'))
    const accesList = comodity == 'drihbi' ? 14 : comodity == 'ironore' ? 17 : 15
    let currentItem = accessLevel?.find(i => accesList == i?.id)
    console.log(user)
    console.log(currentItem)
    if (currentItem?.lists?.length == 0) {
      console.log("noaccess")
      return
    }
    if (
      accessLevel &&
      // List Array
      ListId?.some(ai =>
        currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
      )
    ) {
      let currentItem = accessLevel?.find(i => 6 == i?.id)
      if (currentItem?.lists?.length == 0) {
        {
          setTrail(false)
          setTrialCalculated(true)
        }
      }
      if (
        accessLevel &&
        // List Array
        ListId?.some(ai =>
          currentItem?.lists?.map(it => JSON.parse(it))?.includes(ai)
        )
      ) {
        setTrail(true)
        setTrialCalculated(true)
      }
      else {
        setTrail(false)
        setTrialCalculated(true)
      }
    }
  }, [])

  useEffect(() => {
    if (trailCalculated) {
      var url = null
      console.log(trailversion)
      if (trailversion) {
        if (dataSourceType == 'arrival') {
          url = `/${comodity}/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`
        } else if (dataSourceType == 'loading') {
          url = `/${comodity}/fixtures/filter/loading?limit=${pageSize}&page=${1}`
        } else {
          url = `/${comodity}/fixtures/filter/shipments?limit=${pageSize}&page=${1}`
        }
        const today = new Date().toISOString().split('T')[0];

        const oneMonthPrior = new Date();
        oneMonthPrior.setMonth(oneMonthPrior.getMonth() - 1);
        const oneMonthPriorFormatted = oneMonthPrior.toISOString().split('T')[0];
        const filtersobj = {
          date: [oneMonthPriorFormatted, today],
          ata_utc_start: oneMonthPriorFormatted,
          ata_utc_end: today,
        }
        const filtersobj2 = {
          date: [oneMonthPriorFormatted, today],
          timestamp_utc_start: oneMonthPriorFormatted,
          timestamp_utc_end: today,
        }

        const filtersobj3 = {
          date: [oneMonthPriorFormatted, today],
          timestamp_utc_start: oneMonthPriorFormatted,
          timestamp_utc_end: today,
        }

        getfixturesFilterData(url, filtersobj)
        allFixtureLimit2(filtersobj, filtersobj2, filtersobj3)
        setDefaultArrivalFilter(filtersobj)
        setDefaultShipmentFilter(filtersobj2)
        setDefaultIntransitFilter(filtersobj2)
        setDefaultLoadingFilter(filtersobj3)
      }
      else {
        if (dataSourceType == 'arrival') {
          url = `/${comodity}/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`
        }
        else if (dataSourceType == 'loading') {
          url = `/${comodity}/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${1}`
        } else {
          url = `/${comodity}/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${1}`
        }
        getfixturesData(url)
        allFixtureLimit()
      }
    }
  }, [trailCalculated])

  useEffect(() => {
    if (chartLoading && openChart && isRefresh === -1) {
      setIsRefresh(0);
    }
    if (chartLoading && openChart && isRefresh === 0) {
      setIsRefresh(1);
    }
    if (!chartLoading) {
      setIsRefresh(-1);
    }
  }, [isRefresh, chartLoading, openChart])
  console.log("IsRefresh", isRefresh)

  const showDrawer = url => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const checkIfAllDataAlreadyFetch = (isArrival) => {
    const comodityData = allData[comodity];
    if (comodityData.arrival && isArrival) {
      setGraphData(dataForGraph(comodityData.arrival, company, _.cloneDeep(graphData), 'arrival', comodity));
      return true;
    }
    if (comodityData.shipment && !isArrival) {
      setGraphData(dataForGraph(comodityData.shipment, company, _.cloneDeep(graphData), 'shipment', comodity));
      return true;
    }
    return false;
  }

  const setDataForGraph = (url, filter) => {
    const isArrival = url.includes("arrival");
    if (filter) {
      axios
        .post(
          url, filter,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(data => {
          setGraphData(dataForGraph(data, company, _.cloneDeep(graphData), isArrival ? 'arrival' : 'shipment', comodity));
          setChartLoading(false);
        })
        .catch(err => {
          setChartLoading(false);
        })
    } else {

      if (checkIfAllDataAlreadyFetch(isArrival)) {
        setChartLoading(false);
        return;
      }

      axios
        .get(
          url,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(data => {
          if (isArrival)
            allData[comodity].arrival = data;
          else
            allData[comodity].shipment = data;
          setGraphData(dataForGraph(data, company, _.cloneDeep(graphData), isArrival ? 'arrival' : 'shipment', comodity));
          setChartLoading(false);
        })
        .catch(err => {
          setChartLoading(false);
        })
    }

  }
  const allFixtureLimit = () => {
    axios
      .get(
        `/${comodity}/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        // setArrivalCount(data?.data?.meta?.total)
        setDefaultArrivalCount(data?.data?.meta?.total);
        tableHeader.arrival.defaultCount = data?.data?.meta?.total;
        tableHeader.arrival.count = data?.data?.meta?.total;
        setTableHeader(tableHeader);
      })
      .catch(err => { })
    axios
      .get(
        `/${comodity}/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        tableHeader.shipment.count = data?.data?.meta?.total;
        tableHeader.shipment.defaultCount = data?.data?.meta?.total;
        setTableHeader(tableHeader);
        setDefaultShipCount(data?.data?.meta?.total)

      })
      .catch(err => { })
    if (comodity == 'ironore') {
      axios
        .get(
          `/${comodity}/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${1}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(data => {
          console.log(data?.data?.meta?.total)
          tableHeader.loading.count = data?.data?.meta?.total;
          tableHeader.loading.defaultCount = data?.data?.meta?.total;
          setTableHeader(tableHeader);
          setDefaultLoadingCount(data?.data?.meta?.total)

        })
        .catch(err => { })
    }
    axios
      .get(
        `/${comodity}/intransit/fixtures/data-in-chunks?limit=${pageSize}&page=${1}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        tableHeader.intransit.count = data?.data?.meta?.total;
        tableHeader.intransit.defaultCount = data?.data?.meta?.total;
        setTableHeader(tableHeader);
        setDefaultIntransitCount(data?.data?.meta?.total)

      })
      .catch(err => { })

  }

  const allFixtureLimit2 = (filters, filters2) => {
    axios
      .post(
        `/${comodity}/fixtures/filter/arrivals?limit=${pageSize}&page=${1}`, filters,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data)
        tableHeader.arrival.count = data?.data?.meta?.total;
        tableHeader.arrival.defaultCount = data?.data?.meta?.total;
        setTableHeader(tableHeader);
        setDefaultArrivalCount(data?.data?.meta?.total)
      })
      .catch(err => { })
    axios
      .post(
        `/${comodity}/fixtures/filter/shipments?limit=${pageSize}&page=${1}`, filters2,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        // setShipCount(data?.data?.meta?.total)
        setDefaultShipCount(data?.data?.meta?.total)
        tableHeader.shipment.defaultCount = data?.data?.meta?.total;
        tableHeader.shipment.count = data?.data?.meta?.total;
        setTableHeader(tableHeader);
      })
      .catch(err => { })
    if (comodity == 'ironore') {
      axios
        .post(
          `/${comodity}/fixtures/filter/loading?limit=${pageSize}&page=${1}`, filters2,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        )
        .then(data => {
          console.log(data?.data?.meta?.total)
          // setShipCount(data?.data?.meta?.total)
          setDefaultLoadingCount(data?.data?.meta?.total)
          tableHeader.loading.defaultCount = data?.data?.meta?.total;
          tableHeader.loading.count = data?.data?.meta?.total;
          setTableHeader(tableHeader);
        })
        .catch(err => { })
    }
    axios
      .post(
        `/${comodity}/intransit/fixtures/filter/data?limit=${pageSize}&page=${1}`, filters2,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then(data => {
        console.log(data?.data?.meta?.total)
        // setShipCount(data?.data?.meta?.total)
        setDefaultIntransitCount(data?.data?.meta?.total)
        tableHeader.intransit.defaultCount = data?.data?.meta?.total;
        tableHeader.intransit.count = data?.data?.meta?.total;
        setTableHeader(tableHeader);
      })
      .catch(err => { })
  }

  const getfixturesData = async url => {
    setLoading(true)
    setChartLoading(true);
    //
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      console.log(url)

      if (
        response?.data
        // && response?.data?.status!='failed'
      ) {
        console.log('fixtureResponse', response?.data?.meta)
        console.log(response?.data?.data)
        setTotalEntries(response?.data?.meta?.total)
        const data = response?.data?.data?.map(item => {
          let newdate = item?.ata_utc?.replace('-', '/')
          let date = new Date(newdate)
          let day =
            date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate()
          let month =
            date?.getMonth() < 10 ? `0${date?.getMonth()}` : date?.getMonth()
          let year = date?.getFullYear()
          return {
            ...item,
            ata_utc: `${day}-${month}-${year}`
          }
        })
        setDataSource(response?.data?.data)
        setCurrentPage(response?.data?.meta?.current_page)
        setTotalPage(response?.data?.meta?.total)
        // setPageSize(response?.data?.meta?.per_page)
        if (url?.includes('arrival-data')) {
          // setArrivalCount(response?.data?.meta?.total)
          tableHeader.arrival.count = response?.data?.meta?.total;
          setTableHeader(tableHeader);
        } else if (url?.includes('intransit')) {
          // setArrivalCount(response?.data?.meta?.total)
          tableHeader.intransit.count = response?.data?.meta?.total;
          setTableHeader(tableHeader);
        } else if (url?.includes('loading')) {
          // setArrivalCount(response?.data?.meta?.total)
          tableHeader.loading.count = response?.data?.meta?.total;
          setTableHeader(tableHeader);
        }
        else {
          // setShipCount(response?.data?.meta?.total)
          tableHeader.shipment.count = data?.data?.meta?.total;
          setTableHeader(tableHeader);
        }
        setLoading(false)
      } else {
        // alert(response?.data?.message);
        // setUserState({isAuth:false });
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
    if (!url?.includes('loading-data') && !url?.includes('intransit')) {
      const parseUrl = url.split('?')[0];
      setDataForGraph(parseUrl.concat("?limit=1000000&page=1"));
    }
  }
  const getfixturesFilterData = async (url, filtersData) => {
    setLoading(true)
    setChartLoading(true);
    try {
      const response = await axios.post(url, filtersData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (
        response?.data
        // && response?.data?.status!='failed'
      ) {
        console.log('fixtureResponse', response?.data?.meta)
        setTotalEntries(response?.data?.meta?.total)
        const data = response?.data?.data?.map(item => {
          let newdate = item?.ata_utc?.replace('-', '/')
          let date = new Date(newdate)
          let day =
            date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate()
          let month =
            date?.getMonth() < 10 ? `0${date?.getMonth()}` : date?.getMonth()
          let year = date?.getFullYear()
          return {
            ...item,
            ata_utc: `${day}-${month}-${year}`
          }
        })
        console.log(response?.data?.data)
        setDataSource(response?.data?.data)
        setCurrentPage(response?.data?.meta?.current_page)
        setTotalPage(response?.data?.meta?.total)
        // setPageSize(response?.data?.meta?.per_page)
        if (url?.includes('arrival')) {
          // setArrivalCount(response?.data?.meta?.total)
          tableHeader.arrival.count = response?.data?.meta?.total;
          setTableHeader(tableHeader);
        } else if (url?.includes('intransit')) {
          // setArrivalCount(response?.data?.meta?.total)
          tableHeader.intransit.count = response?.data?.meta?.total;
          setTableHeader(tableHeader);
        } else if (url?.includes('loading')) {
          // setArrivalCount(response?.data?.meta?.total)
          tableHeader.loading.count = response?.data?.meta?.total;
          setTableHeader(tableHeader);
        } else {
          // setShipCount(response?.data?.meta?.total)
          tableHeader.shipment.count = response?.data?.meta?.total;
          setTableHeader(tableHeader);
        }
        setLoading(false)
      } else {
        // alert(response?.data?.message);
        // setUserState({isAuth:false });
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
    if (!url?.includes('loading') && !url?.includes('intransit')) {
      const parseUrl = url.split('?')[0];
      if (filtersData.save_search)
        delete filtersData["save_search"]
      setDataForGraph(parseUrl.concat("?limit=1000000&page=1"), filtersData);
    }
  }
  // table props
  const tableProps = {
    bordered,
    // loading,
    size,
    bottom
  }

  const onPaginationChange = (page, pageSize) => {
    const tableWrapper = document.querySelector('.ant-table-wrapper');
    if (tableWrapper) {
      tableWrapper.scrollLeft = 0;
    }

    setPageSize(pageSize)
    if (!applyFilter) {
      if (trailversion) {
        if (dataSourceType == 'arrival') {
          getfixturesFilterData(
            `/${comodity}/fixtures/filter/arrivals?limit=${pageSize}&page=${page}`,
            defaultArrivalFilter
          )
        }
        else if (dataSourceType == 'loading') {
          getfixturesFilterData(
            `/${comodity}/fixtures/filter/loading?limit=${pageSize}&page=${page}`,
            defaultLoadingFilter
          )
        } else if (dataSourceType == 'intransit') {
          getfixturesFilterData(
            `/${comodity}/intransit/fixtures/filter/data?limit=${pageSize}&page=${page}`,
            defaultLoadingFilter
          )
        } else {
          getfixturesFilterData(
            `/${comodity}/fixtures/filter/shipments?limit=${pageSize}&page=${page}`,
            defaultShipmentFilter
          )
        }
      }
      else {
        if (dataSourceType == 'arrival') {
          getfixturesData(
            `/${comodity}/fixtures/arrival-data-in-chunks?limit=${pageSize}&page=${page}`
          )
        }
        else if (dataSourceType == 'loading' && comodity == 'ironore') {
          getfixturesData(
            `/${comodity}/fixtures/loading-data-in-chunks?limit=${pageSize}&page=${page}`
          )
        } else if (dataSourceType == 'intransit') {
          getfixturesData(
            `/${comodity}/intransit/fixtures/data-in-chunks?limit=${pageSize}&page=${page}`
          )
        } else {
          getfixturesData(
            `/${comodity}/fixtures/shipment-data-in-chunks?limit=${pageSize}&page=${page}`
          )
        }
      }
    } else {
      if (trailversion && (!shipmentFilterUpdated || !shipmentFilterUpdated.date)) {
        if (dataSourceType == 'arrival')
          shipmentFilterUpdated = { ...shipmentFilterUpdated, ata_utc_end: defaultArrivalFilter.ata_utc_end, ata_utc_start: defaultArrivalFilter.ata_utc_start, date: defaultArrivalFilter.date };
        else if (dataSourceType == 'intransit')
          shipmentFilterUpdated = { ...shipmentFilterUpdated, timestamp_utc_start: defaultIntransitFilter.timestamp_utc_start, timestamp_utc_end: defaultIntransitFilter.timestamp_utc_end, date: defaultIntransitFilter.date };
        else
          shipmentFilterUpdated = { ...shipmentFilterUpdated, timestamp_utc_start: defaultShipmentFilter.timestamp_utc_start, timestamp_utc_end: defaultShipmentFilter.timestamp_utc_end, date: defaultArrivalFilter.date };
      }
      if (dataSourceType == 'arrival') {
        getfixturesFilterData(
          `/${comodity}/fixtures/filter/arrivals?limit=${pageSize}&page=${page}`,
          shipmentFilterUpdated
        )
      }
      else if (dataSourceType == 'loading') {
        getfixturesFilterData(
          `/${comodity}/fixtures/filter/loading?limit=${pageSize}&page=${page}`,
          shipmentFilterUpdated
        )
      } else if (dataSourceType == 'intransit') {
        getfixturesFilterData(
          `/${comodity}/intransit/fixtures/filter/data?limit=${pageSize}&page=${page}`,
          shipmentFilterUpdated
        )
      } else {
        getfixturesFilterData(
          `/${comodity}/fixtures/filter/shipments?limit=${pageSize}&page=${page}`,
          shipmentFilterUpdated
        )
      }
    }
  }
  const saveFilterCall = async (url, filtersData) => {
    setLoading(true)
    try {
      const response = await axios.post(url, filtersData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response?.data) {
        setIsSavedFilterCall(true);
      }
      setLoading(false)
    } catch (err) {
      console.log("Save filter call failed")
      setLoading(false)
    }
  }
  const onSelectTableTab = (e) => {
    tableHeader[dataSourceType].isSelected = false;
    tableHeader[dataSourceType].isFilterApplied = false;
    tableHeader[e].isSelected = true;
    setSetShipmentFiltersdata(null);
    setDataSourceType(e);
    setTableHeader(tableHeader);
    setFilterApply(false)
    setAppliedFilter(false)

    if (!trailversion)
      getfixturesData(getUrlToshowHeader(comodity, e, trailversion, pageSize))
    else
      getfixturesFilterData(getUrlToshowHeader(comodity, e, trailversion, pageSize), e === "arrival" ? defaultArrivalFilter : e === "intransit" ? defaultIntransitFilter : e === "loading" ? defaultLoadingFilter : defaultShipmentFilter);

  }

  const getColumns = () => {
    if (comodity == 'drihbi') {
      return dataSourceType == "arrival" ? DRIHBIDataColumns.Arrivalcolumns : dataSourceType == "intransit" ? DRIHBIDataColumns.Intransitcolumns : DRIHBIDataColumns.Shipmentcolumns
    }
    else if (comodity == 'ironore') {
      return dataSourceType == "arrival" ? IronOreDataColumns.Arrivalcolumns : dataSourceType == "intransit" ? IronOreDataColumns.Intransitcolumns : dataSourceType == "loading" ? IronOreDataColumns.Loadingcolumns : IronOreDataColumns.Shipmentcolumns
    }
    else {
      return dataSourceType == "arrival" ? MerchantDataColumns.Arrivalcolumns : dataSourceType == "intransit" ? MerchantDataColumns.Intransitcolumns : MerchantDataColumns.Shipmentcolumns
    }
  }
  return (
    <>
      <div className='fixtures-page-wrapper w-full'>

        <FiltersDrawer
          placement='left'
          closable={false}
          onClose={onClose}
          trailversion={trailversion}
          setFilterApply={setFilterApply}
          open={open}
          setAppliedFilter={setAppliedFilter}
          dataSourceType={dataSourceType}
          getfixturesData={getfixturesData}
          getfixturesFilterDatas={getfixturesFilterData}
          setSetShipmentFiltersdata={setSetShipmentFiltersdata}
          shipmentFilterUpdated={shipmentFilterUpdated}
          pageSizes={pageSize}
          defaultArrivalFilter={defaultArrivalFilter}
          defaultShipmentFilter={defaultShipmentFilter}
          defaultLoadingFilter={defaultLoadingFilter}
          defaultIntransitFilter={defaultIntransitFilter}
          comodity={comodity}
          dataSource={dataSource}
          loadings={loading}
          saveFilterCall={saveFilterCall}
          isSavedFilterCall={isSavedFilterCall}
          setIsSavedFilterCall={setIsSavedFilterCall} />

        <div className=' antd-table-custom'>
          <DataTable
            dataSource={dataSource}
            dataSourceType={dataSourceType}
            columns={getColumns()}
            loading={loading}
            tableHeader={tableHeader}
            defaultLoadingCount={defaultLoadingCount}
            defaultShipCount={defaultShipCount}
            defaultArrivCount={defaultArrivCount}
            defaultIntransitCount={defaultIntransitCount}
            trailversion={trailversion}
            showDrawer={showDrawer}
            setLoading={setLoading}
            shipmentFilterUpdated={shipmentFilterUpdated}
            url={getUrlToDownloadData(comodity, dataSourceType, applyFilter, totalEntries)}
            onSelectTableTab={onSelectTableTab}
            comodity={comodity}
          />
        </div>
        <ProPagination currentPage={current_page} pageSize={pageSize} TotalPage={totalPage} loading={loading} onPaginationChange={onPaginationChange} />
        {dataSourceType !== "loading" && dataSourceType !== "intransit" ?
          <Button
            style={{ right: "-3rem" }}
            className='absolute top-2/4 z-10 transform -rotate-90 pb-3 h-auto font-medium'

            type='primary'
            onClick={() => { setOpenChart(true) }}>
            View Charts
            <UpOutlined />
          </Button> : null}
      </div>
      {(dataSourceType !== "loading" && dataSourceType !== "intransit" && isRefresh !== 0) ?
        <ChartDrawer
          placement='right'
          closable={false}
          onClose={() => { setOpenChart(false) }}
          open={openChart}
          dataforgrapg={graphData}
          Filters={{
            type: dataSourceType == "arrival" ? "arrivals" : dataSourceType
          }}
          loading={chartLoading}
        /> : null
      }
    </>
  )
}

export default Comodoties
