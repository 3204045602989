export function SavedFilterMapping(comodity, subComodity, type) {
    const filterOption = {
        "type": "",
        // "limit": "4"
    }
    if (comodity == "shipData") {
        if (subComodity == "fixture") {
            if (type == "arrival") {
                filterOption.type = "ferous_scrap_arrivals_fixtures";
                return filterOption;
            }
            if (type == "departure") {
                filterOption.type = "ferous_scrap_shipments_fixtures";
                return filterOption;
            }
            if (type == "loading") {
                filterOption.type = "ferous_scrap_loading_fixtures";
                return filterOption;
            }
            if (type == "barges") {
                filterOption.type = "barges_arrivals_fixtures";
                return filterOption;
            }
            if (type == "intransit") {
                filterOption.type = "ferouscrap_intransit_fixtures";
                return filterOption;
            }
        }
    }
    if (comodity == "merchantiron") {
        if (subComodity == "fixture") {
            if (type == "arrival") {

                filterOption.type = "merchant_iron_arrivals_fixture";
                return filterOption;
            }
            if (type == "shipment") {
                filterOption.type = "merchant_iron_shipments_fixture";
                return filterOption;
            }
            if (type == "intransit") {
                filterOption.type = "merchant_iron_intransit_fixtures";
                return filterOption;
            }
        }
    }
    if (comodity == "drihbi") {
        if (subComodity == "fixture") {
            if (type == "arrival") {
                filterOption.type = "dri_hbi_arrivals_fixtures";
                return filterOption;
            }
            if (type == "shipment") {
                filterOption.type = "dri_hbi_shipments_fixtures";
                return filterOption;
            }
            if (type == "intransit") {
                filterOption.type = "drihbi_intransit_fixtures";
                return filterOption;
            }
        }
    }
    if (comodity == "ironore") {
        if (subComodity == "fixture") {
            if (type == "arrival") {
                filterOption.type = "ironore_arrivals_fixture";
                return filterOption;
            }
            if (type == "shipment") {
                filterOption.type = "ironore_shipments_fixture";
                return filterOption;
            }
            if (type == "loading") {
                filterOption.type = "ironore_loading_fixtures";
                return filterOption;
            }
            if (type == "intransit") {
                filterOption.type = "ironore_intransit_fixtures";
                return filterOption;
            }
        }
    }
    return filterOption;
}

export function mapFilterObject(filtersdata, searchObj) {
    const shipmentFilterUpdated = {};

    filtersdata?.map(item => {
        if (item?.type == 'select') {
            if (searchObj[item?.key] !== null)
                shipmentFilterUpdated[item?.key] = searchObj[item?.key]
        } else if (item?.type == 'children') {
            item?.children?.map(item => {
                if (searchObj[item?.key] !== null)
                    shipmentFilterUpdated[item?.key] = Number(searchObj[item?.key]);
            })
        } else if (item?.type == 'date') {
            if (searchObj[item?.key1] !== null && searchObj[item?.key2] !== null) {
                shipmentFilterUpdated[item?.key1] = searchObj[item?.key1]
                shipmentFilterUpdated[item?.key2] = searchObj[item?.key2]
            }
        }
    })
    return shipmentFilterUpdated;
}