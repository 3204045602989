import { useLocation, useNavigate } from "react-router-dom";
import { SubMenu } from "../Components/subMenu";
import Company from "./Company/Company";
import { useEffect, useState } from "react";
import { defaultShipmentTableColForPigIron } from "../utilities/DateToLocalUtility";
import { checkAccessLevelById, getAccessLevels, submenuAccessLevel } from "../Components/access-level";
import Comodoties from "./Comodoties";
import { WarningModal } from "../Components/Misc/WarningModal";

export const IronOre = () => {
    const navigate = useNavigate();
    const [selectedSubMenu, setSeletectedSubMenu] = useState("");
    useEffect(() => {
        const access = checkAccessLevelById(17);
        if (!access) {
            navigate('/welcome')
            WarningModal("Iron Ore")
        }
        else
            setSeletectedSubMenu("/fixtures");
    }, [])
    return (
        <>
            <SubMenu submenuAccessLevel={submenuAccessLevel.slice(0, -1)} accessLevels={getAccessLevels()} selectedSubMenu={selectedSubMenu} setSeletectedSubMenu={setSeletectedSubMenu} />
            {selectedSubMenu == "/fixtures" ? <Comodoties defaultColumns={defaultShipmentTableColForPigIron} comodity={'ironore'} /> :
                selectedSubMenu == "/company" ? <Company comodity={'ironore'} /> : ""}
        </>
    )
}   